import React from 'react';
import { toJS } from 'mobx';
import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaDialog from '@/components/Schema/Dialog';
import SchemaForm, { IFormSchema } from '@/components/Schema/Form';
import CURDTable from '@/components/Schema/CURDTable';
import { Icon, message } from 'antd';
import { Commonv2Services } from 'services/apiServices.js';
import { Shensuo_CategoryType } from 'services/enumTypes';
import styles from './index.module.scss';

type IFetchListReq = commonv2.GetCategoryListAdminReq;
type IFetchListRsq = commonv2.GetCategoryListAdminRsp;
type ICategoryModel = commonv2.ModelCategory;

type IModal = commonv2.ModelCategory;

type SchemaState = {
  addDialog: boolean;
  model: IModal;
};

const CategoryList: ICURDTableSchema<
  ICategoryModel,
  SchemaState,
  IFetchListRsq
> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '新建分类',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          this.methods.toggleModelDialog();
        }
      }
    ]
  },
  table: {
    rowKey: 'id',
    rowClassName: () => styles.row,
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        width: 180,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: ICategoryModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  this.methods.toggleModelDialog();
                  this.methods.setModalData(record);
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  async onConfirm(this: CURDTable) {
                    await Commonv2Services.DelCategory({
                      id: Number(record!.id),
                      category_type: Shensuo_CategoryType.CategoryTypeStore
                    });
                    try {
                      this.message.success('删除成功');
                      this.listService.refreshList();
                    } catch (error) {}
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      },
      {
        dataIndex: 'view_order',
        title: '排序',
        ellipsis: true
      },
      {
        dataIndex: 'name',
        title: '分类名称',
        ellipsis: true
      },
      {
        dataIndex: 'image',
        title: '分类主图',
        width: 200,
        render(src: string) {
          return (
            <div className="thumbnail-list">
              <img src={src} alt="" />
            </div>
          );
        }
      },
      {
        dataIndex: 'created_at',
        title: '创建时间',
        dataType: 'date',
        ellipsis: true
      }
    ],
    pagination: false
  },
  observableData: {
    addDialog: false,
    model: {
      name: ''
    }
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
      }
      this.loading = result.loading;
    },

    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },

    setModalData(this: SchemaCURDTable, v: commonv2.ModelCategory) {
      this.observableData.model = v;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(Commonv2Services.GetCategoryListAdmin);
      this.listService.setReq<IFetchListReq>({
        category_type: Shensuo_CategoryType.CategoryTypeStore
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, model } = toJS(observableData) as SchemaState;
    return (
      <>
        <SchemaDialog
          schema={{
            visible: addDialog,
            title: `${model.id ? '编辑' : '添加'}banner`,
            children: (
              <SchemaForm
                wrappedComponentRef={(ref: any) => {
                  this.customNodeRef.addMenuDialog = ref;
                }}
                schema={
                  {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 14 },
                    labelAlign: 'right',
                    rows: [
                      [
                        {
                          label: '图片',
                          propName: 'image',
                          type: 'uploader-with-list',
                          componentProps: {
                            maxLen: 1,
                            accept: 'image/*'
                          },
                          FieldDecoratorOptions: {
                            initialValue: model.image ? [model.image] : [],
                            rules: [
                              {
                                required: true,
                                message: '请上传图片'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '分类名称',
                          propName: 'name',
                          type: 'input',
                          placeholder: '请选择分类名称',
                          FieldDecoratorOptions: {
                            initialValue: model.name!,
                            rules: [
                              {
                                max: 10,
                                required: true,
                                message: '请输入不超过10字的分类名称'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '排序',
                          propName: 'view_order',
                          type: 'input',
                          FieldDecoratorOptions: {
                            initialValue: model.view_order,
                            rules: [
                              {
                                validator(rule, value, callback) {
                                  if (!value) {
                                    callback();
                                    return;
                                  }
                                  const number = Number(value);
                                  if (Number.isNaN(number)) {
                                    callback('请输入数字');
                                  } else {
                                    if (value < 0) {
                                      callback('必须大于等于0');
                                    } else if (value > 1000) {
                                      callback('必须小于等于1000');
                                    } else if (!Number.isInteger(number)) {
                                      callback('请输入整数');
                                    } else {
                                      callback();
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        }
                      ]
                    ]
                  } as IFormSchema<IModal>
                }
              />
            ),
            onCancel: () => {
              this.methods.toggleModelDialog();
              this.methods.setModalData({ name: '' });
              this.customNodeRef.addMenuDialog.props.form.resetFields();
            },
            onOk: async () => {
              console.log(2222);
              this.customNodeRef.addMenuDialog.props.form.validateFields(
                async (errors: Error[], values: any) => {
                  console.log('errors', errors);
                  if (!errors || !Object.keys(errors!).length) {
                    const category = {
                      ...model,
                      name: values.name,
                      image: values.image[0],
                      view_order: +values.view_order,
                      category_type: Shensuo_CategoryType.CategoryTypeStore
                    };
                    if (this.observableData.model.id) {
                      await Commonv2Services.SetCategory({
                        category
                      });
                    } else {
                      await Commonv2Services.AddCategory({
                        category
                      });
                    }
                    message.success(
                      `${!this.observableData.model.id ? '添加' : '编辑'}成功`
                    );
                    this.listService.refreshList();
                    this.methods.toggleModelDialog();
                    this.customNodeRef.addMenuDialog.props.form.resetFields();
                    this.methods.setModalData({ name: '' });
                  }
                }
              );
            }
          }}
        />
      </>
    );
  }
};

export default CategoryList;
