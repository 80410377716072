import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';

const citiesMap = require('json/district_shensuo.json');
const region = require('json/Region.json');

export interface ICities {
  [key: string]: any;
}

export enum IRootAreaCode {
  REGION = '001',
  ALL_PROVINCES = '002'
}

export interface ICitiesForCascader {
  value: string;
  label: string;
  children?: ICitiesForCascader[];
}

const rootCode = '86';

function combineCities(map: ICities, code: string) {
  return Object.keys(map[code]).reduce((acc: any, key: string) => {
    acc[key] = {
      id: key,
      name: map[code][key]
    };
    if (map[key]) {
      acc[key].children = combineCities(map, key);
    }
    return acc;
  }, {});
}

function getCitiesForCascader(cities: ICities): ICitiesForCascader[] {
  return Object.keys(cities).reduce(
    (acc: ICitiesForCascader[], key: string) => {
      const { children, id, name } = cities[key];
      const cname = typeof name === 'string' ? name : name[0];
      const item: ICitiesForCascader = { value: id, label: cname };
      if (children) {
        item.children = getCitiesForCascader(children);
      }
      acc.push(item);
      return acc;
    },
    []
  );
}

// function combineRegions(regionMap: ICities, code: string, citiesMap: ICities) {
//   return Object.keys(regionMap[code]).reduce((acc: any, key: string) => {
//     acc[key] = {
//       id: key,
//       name: regionMap[code][key],
//       children: Object.keys(regionMap[key]).reduce(
//         (acc: ICities, cur: string) => {
//           acc[cur] = citiesMap[cur];
//           return acc;
//         },
//         {}
//       )
//     };
//     return acc;
//   }, {});
// }

class CommonStore {
  @persist('object') @observable cities: ICities = combineCities(
    citiesMap,
    rootCode
  );
  @persist('object') @observable region = region;

  @action
  SET_CITIES(value: ICities) {
    this.cities = value;
  }

  @computed
  get citiesForCascader() {
    console.log('cities', this.cities);
    return getCitiesForCascader(this.cities);
  }

  @computed
  get regionsForCascader() {
    return [
      {
        value: IRootAreaCode.REGION,
        label: '大区',
        children: Object.keys(this.region[rootCode]).map((k: string) => ({
          value: k,
          label: this.region[rootCode][k]
        }))
      },
      {
        value: IRootAreaCode.ALL_PROVINCES,
        label: '全国',
        children: this.citiesForCascader
      }
    ];
  }

  @computed
  get provinceToRegionMap() {
    const regions = this.region[rootCode];
    return Object.keys(this.region)
      .filter(key => key !== '86')
      .reduce((acc: any, regionCode: string) => {
        Object.keys(this.region[regionCode]).forEach((code: string) => {
          acc[code] = {
            pid: regionCode,
            name: regions[regionCode]
          };
        });
        return acc;
      }, {});
  }
}

const commonStore = new CommonStore();

export default commonStore;

export { CommonStore };
