import { message } from 'antd';
import { ErrCode } from '@/services/enumTypes';

export const errorMessageMap = {
  [ErrCode.stockmgr_ErrDecTotalCountTooLarge]: '库存数量不足',
  [ErrCode.wechatpay_ErrWxAccountNoAuth]: '发放失败，余额已退回当前账户'
};

export default (code: number, errmsg: string) => {
  let tip = errmsg || `system error [${code}]`;
  try {
    errorMessageMap[code] && (tip = errorMessageMap[code]);
  } catch (e) {
    console.log(e);
  }
  message.error(tip);
};
