import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import { Rbacv2Services } from '@/services/apiServices.js';

type IModel = rbacv2.ModelRoleUser;

const config: ICURDTableSchema<IModel> = {
  table: {
    rowKey: 'id',
    scroll: { y: 'calc(100vh - 260px)' },
    defaultExpandAllRows: true,
    columns: [
      {
        dataIndex: 'role_id',
        title: '角色名',
        width: 200
      }
    ]
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      this.loading = result.loading;
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
      }
    },
    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(Rbacv2Services.GetRoleUserList);
      this.listService.getList();
    }
  }
};

export default config;
