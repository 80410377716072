import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import CURDTable from '@/components/Schema/CURDTable';
import { Icon } from 'antd';
import { ShensuoServices } from 'services/apiServices.js';
import { customerLevelMap } from '@/services/customerServices';
import { combineAreas } from '@/services/commonServices';
import {
  Shensuo_ModelLessonScheduleApplication_State,
  Shensuo_GetLessonScheduleApplicationListReq_ListOption
} from 'services/enumTypes';
import styles from './index.module.scss';

type IFetchListReq = shensuo.GetLessonScheduleApplicationListAdminReq;
type IFetchListRsq = shensuo.GetLessonScheduleApplicationListAdminRsp;
type IModel = shensuo.ModelLessonScheduleApplication;

type SchemaState = {
  lesson_map?: { [key: number]: shensuo.ModelLesson };
  user_map?: { [key: string]: shensuo.ModelUser };
};

const ApplyScheduleList: ICURDTableSchema<
  IModel,
  SchemaState,
  IFetchListRsq
> = {
  header: {
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '代理名称',
              type: 'input',
              placeholder: '请输入代理名称',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName:
                Shensuo_GetLessonScheduleApplicationListReq_ListOption.ListOptionUserName
            },
            {
              label: '状态',
              type: 'select',
              placeholder: '请选择状态',
              componentProps: {
                style: {
                  width: '220px'
                }
              },
              selectOptions: [
                {
                  id: Shensuo_ModelLessonScheduleApplication_State.StateNil,
                  status: '全部'
                },
                {
                  id: Shensuo_ModelLessonScheduleApplication_State.StateIgnore,
                  status: '已忽略'
                },
                {
                  id: Shensuo_ModelLessonScheduleApplication_State.StatePending,
                  status: '待处理'
                },
                {
                  id:
                    Shensuo_ModelLessonScheduleApplication_State.StateScheduleDone,
                  status: '已排课'
                }
              ],
              selectOption: {
                valueKey: 'id',
                labelKey: 'status'
              },
              propName:
                Shensuo_GetLessonScheduleApplicationListReq_ListOption.ListOptionState
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    scroll: { x: 1400, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        dataType: 'btn-group',
        fixed: 'left',
        width: 170,
        renderBtnGroup(record: IModel) {
          const btns: any[] = [
            {
              type: 'link',
              size: 'small',
              children: '一键排课',
              onClick(this: SchemaCURDTable) {
                this.props.history!.push({
                  pathname: '/contentmanagement/scheduleEdit',
                  search: `?applyId=${record.id}&lesson_id=${record.lesson_id}&expect_begin_time=${record.expect_begin_time}&province=${record.province}&area=${record.area}&city=${record.city}`
                });
              }
            },
            {
              type: 'link',
              size: 'small',
              style: {
                color: '#ff4d4f'
              },
              popconfirmProps: {
                title: '确定忽略此条申请？',
                icon: (
                  <Icon type="question-circle-o" style={{ color: '#ff4d4f' }} />
                ),
                async onConfirm(this: CURDTable) {
                  await ShensuoServices.SetLessonScheduleApplicationStateAdmin({
                    lesson_schedule_application_id: record.id!,
                    state:
                      Shensuo_ModelLessonScheduleApplication_State.StateIgnore
                  });
                  this.message.success('忽略成功');
                  this.listService.refreshList();
                }
              },
              children: '忽略'
            }
          ];
          const _btns = btns.filter((item, idx) => {
            if (
              record.state ===
              Shensuo_ModelLessonScheduleApplication_State.StateIgnore
            ) {
              return idx === 0;
            } else if (
              record.state ===
              Shensuo_ModelLessonScheduleApplication_State.StatePending
            ) {
              return true;
            } else {
              return false;
            }
          });
          return {
            needDivider: false,
            btns: _btns
          };
        }
      },
      {
        dataIndex: 'created_at',
        title: '申请时间',
        dataType: 'date'
      },
      {
        dataIndex: 'user_name',
        title: '代理名称'
      },
      {
        dataIndex: 'uid',
        title: '代理级别',
        width: 200,
        render(this: SchemaCURDTable, uid: number) {
          const value = this.observableData.user_map
            ? this.observableData.user_map[uid]
              ? this.observableData.user_map[uid].type
              : 0
            : 0;
          return (
            <span title={customerLevelMap[+value]} className="text-eclipse">
              {customerLevelMap[+value]}
            </span>
          );
        }
      },
      {
        dataIndex: 'lesson_id',
        title: '申请课程类型',
        width: 300,
        render(this: SchemaCURDTable, id: string) {
          return this.observableData.lesson_map[id].name;
        }
      },
      {
        dataIndex: 'expect_begin_time',
        title: '期望开课时间',
        dataType: 'date',
        formatData: 'YYYY-MM-DD'
      },
      {
        title: '期望开课地区',
        render(record: IModel) {
          return (
            <span>
              {combineAreas(record.province, record.city, record.area)}
            </span>
          );
        }
      },
      {
        dataIndex: 'state',
        title: '状态',
        render(value: number) {
          switch (value!) {
            case Shensuo_ModelLessonScheduleApplication_State.StatePending:
              return '待处理';
            case Shensuo_ModelLessonScheduleApplication_State.StateIgnore:
              return '已忽略';
            case Shensuo_ModelLessonScheduleApplication_State.StateScheduleDone:
              return '已排课';
            default:
              break;
          }
        }
      }
    ],
    rowClassName: () => styles.row
  },
  observableData: {
    lesson_map: {},
    user_map: {}
  },
  methods: {
    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.lesson_map = result.resp!.lesson_map;
        this.observableData.user_map = result.resp!.user_map;
      }
      this.loading = result.loading;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(
        ShensuoServices.GetLessonScheduleApplicationListAdmin
      );
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  }
};

export default ApplyScheduleList;
