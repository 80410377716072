import { getBannerConfig } from './bannerConfig';
import { Shensuo_ModelBanner_Type } from '@/services/enumTypes';
import { ShensuoServices } from '@/services/apiServices';
// import { ShensuoServices } from '@/services/apiServices.js';

export enum BannerJumpType {
  NIL = 0,
  LESSON = 3
}

const jumpType = {
  [BannerJumpType.NIL]: '不跳转',
  [BannerJumpType.LESSON]: '课程类型'
};

export default getBannerConfig({
  defaultReq: {
    type: Shensuo_ModelBanner_Type.TypeH5LessonIcon
  },
  jumpType,
  async getJumpTypeOptions(jumpType: number) {
    switch (jumpType) {
      case BannerJumpType.LESSON:
        const { list } = await ShensuoServices.GetLessonListAdmin({
          list_option: {}
        });
        return {
          options: list!,
          option: {
            valueKey: 'id',
            labelKey: 'name'
          }
        };
      default:
        break;
    }
    return {
      options: []
    };
  }
});
