import { onReactionError, configure } from 'mobx';

import { persistStore } from '@/store/persistUtils';
// import userStore, { UserStore } from '@/store/user';
// import appConfigStore, { AppConfigStore } from '@/store/appConfig';
import commonStore, { CommonStore } from '@/store/common';
import userStore, { UserStore } from '@/store/user';
import appStore, { AppStore } from '@/store/app';

configure({ enforceActions: 'observed' });

export enum StoreName {
  USERSTORE = 'userStore',
  APPCONFIGSTORE = 'appStore',
  COMMONSTORE = 'commonStore'
}

type IUserStoreProp = {
  [StoreName.USERSTORE]: UserStore;
};

type IAppConfigStoreProp = {
  [StoreName.APPCONFIGSTORE]: AppStore;
};

type ICommonStoreProp = {
  [StoreName.COMMONSTORE]: CommonStore;
};

export default {
  [StoreName.USERSTORE]: userStore,
  [StoreName.APPCONFIGSTORE]: appStore,
  [StoreName.COMMONSTORE]: commonStore
};

export const persistUserStore = persistStore<UserStore>(
  StoreName.USERSTORE,
  userStore
);

export const persistAppConfigStore = persistStore<AppStore>(
  StoreName.APPCONFIGSTORE,
  appStore
);

export const persisitCommonStore = persistStore<CommonStore>(
  StoreName.COMMONSTORE,
  commonStore
);

export const hydrationsStore = [
  persistUserStore,
  persistAppConfigStore,
  persisitCommonStore
];

export {
  IUserStoreProp,
  UserStore,
  IAppConfigStoreProp,
  AppStore,
  ICommonStoreProp,
  CommonStore
};

onReactionError((err, derivation) => {
  console.error(derivation, err);
});
