import { observable, action, reaction, intercept } from 'mobx';
import { persist } from 'mobx-persist';
import { BaseUserStore } from '@fire/mobx-logic/lib/user';
import { AdminServices, AccountlogicServices } from 'services/apiServices.js';
import { MenuItem, IMenuModel } from '@/Layouts/BasicLayout/menu';
import {
  getMenuTreeAndMap,
  filterMenuTreeAndMap
} from '@/Layouts/BasicLayout/menu.services';

import appStore from '@/store/app';
import { handleToLogin } from '@/router';
import cookie from 'js-cookie';

interface RoleInfo {
  is_super_admin?: boolean;
  role_list?: rbacv2.ModelRole[];
  role_perm_list?: rbacv2.ModelRolePerm[];
  api_list?: string[];
}
interface UserInfo {
  account?: admin.Account;
}

class UserStore extends BaseUserStore<admin.LoginReq, admin.LoginRsp> {
  AccountlogicServices = AccountlogicServices;

  @persist('object') @observable.deep userInfo: UserInfo = {};
  @persist('object') @observable.deep roleInfo: RoleInfo = {};
  @persist('list') @observable.deep menuTree: MenuItem[] = [];
  @persist('list') @observable.deep menuList: IMenuModel[] = [];
  @persist('object') @observable.deep menuMap: {
    [menuId: number]: IMenuModel;
  } = [];

  @action
  SET_USERINFO(userInfo: UserInfo) {
    this.userInfo = userInfo;
  }

  @action
  SET_ROLEINFO(roleInfo: RoleInfo) {
    this.roleInfo = roleInfo;
  }

  @action
  SET_MENUINFO(menu_list: rbacv2.ModelMenu[]) {
    const { menuTree, menuList, menuMap, authMenus } = this.roleInfo!
      .is_super_admin
      ? getMenuTreeAndMap(menu_list)
      : filterMenuTreeAndMap(
          menu_list,
          this.roleInfo!.role_perm_list
            ? this.roleInfo!.role_perm_list!.map(v => v.menu_id!)
            : []
        );
    this.menuTree = menuTree;
    this.menuList = authMenus! || menuList;
    this.menuMap = menuMap;
  }

  processDoLoginOut = () => {
    this.SET_MENUINFO([]);
    this.SET_USERINFO({});
    this.SET_ROLEINFO({});
  };

  // loginOut = () => {
  //   // this.LoginOutAsync();
  //   this.SET_MENUINFO([]);
  //   this.SET_USERINFO({});
  //   this.SET_ROLEINFO({});
  // };

  checkCanIRequest = (api: string) => {
    const canRequest = this.roleInfo.is_super_admin
      ? true
      : (this.roleInfo.api_list! || []).includes(api);
    if (!canRequest) {
      console.info('%c no access request url', 'color:red;', api);
    }
    return canRequest;
  };

  async processDoLogin(req: admin.LoginReq) {
    const { sid, expired_at, ...params } = await AdminServices.Login(req, {
      waitLock: false
    });
    cookie.remove('sid');
    const {
      is_super_admin,
      role_list,
      role_perm_list,
      api_list,
      account,
      menu_tree = []
    } = params;

    this.SET_ROLEINFO({
      is_super_admin,
      role_list,
      role_perm_list,
      api_list
    });
    this.SET_USERINFO({
      account
    });
    this.SET_MENUINFO(menu_tree);

    return {
      sid: sid!,
      expired_at: expired_at!
    };
  }
}

const userStore = new UserStore();

reaction(
  () => userStore.menuList,
  menuList => {
    appStore.updateRouter(menuList);
  }
);

intercept(userStore, change => {
  if (change.name === 'sid' && change.type === 'update' && !change.newValue) {
    handleToLogin();
  }
  return change;
});

export default userStore;

export { UserStore };
