import React from 'react';
import { Icon, message } from 'antd';
import { toJS } from 'mobx';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';

import { Rbacv2Services } from '@/services/apiServices.js';

type IModel = rbacv2.ModelRole;
type AddModelReq = rbacv2.AddRoleReq;
type IFetchListReq = rbacv2.GetMenuTreeReq;
type IFetchListRsq = rbacv2.GetRoleListRsp;

type SchemaState = {
  addDialog: boolean;
  model: IModel;
};

const config: ICURDTableSchema<IModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '添加角色',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          this.methods.setModelData();
          this.observableData.addDialog = true;
        }
      }
    ]
  },
  table: {
    rowKey: 'id',
    scroll: { y: 'calc(100vh - 260px)' },
    childrenColumnName: 'subMenu',
    defaultExpandAllRows: true,
    columns: [
      {
        dataIndex: 'name',
        title: '角色名',
        width: 200
      },
      {
        dataIndex: 'created_at',
        title: '创建时间',
        dataType: 'date',
        width: 200
      },
      {
        title: '操作',
        align: 'center',
        dataType: 'btn-group',
        renderBtnGroup(item: IModel) {
          return {
            btns: [
              {
                type: 'link',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  this.methods.setModelData(item);
                  this.methods.toggleModelDialog();
                }
              },
              {
                type: 'link',
                children: '编辑权限',
                onClick(this: SchemaCURDTable) {
                  this.props.history!.push({
                    pathname: '/admin/editperm',
                    search: `?id=${item.id}`
                  });
                }
              },
              {
                type: 'link',
                style: { color: '#ff4d4f' },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  onConfirm(this: SchemaCURDTable) {
                    Rbacv2Services.DelRole({
                      id: item.id!
                    }).then(() => {
                      message.success('删除成功');
                      this.listService.refreshList();
                    });
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      }
    ]
  },
  observableData: {
    addDialog: false,
    model: {
      name: ''
    }
  },
  methods: {
    setModelData(model?: IModel) {
      this.observableData.model = {
        ...model
      };
    },
    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },

    getFetchListResult(this: SchemaCURDTable, result) {
      this.loading = result.loading;
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
      }
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(Rbacv2Services.GetRoleList);
      this.listService.setReq<IFetchListReq>({
        menu_tree_id: +process.env.ENV_appId!
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog } = toJS(observableData) as SchemaState;

    return (
      <div>
        <SchemaDialog
          schema={{
            visible: addDialog,
            destroyOnClose: true,
            title: !this.observableData.model!.id ? '添加角色' : '编辑角色',
            children: (
              <SchemaForm
                wrappedComponentRef={(ref: any) => {
                  this.customNodeRef.addMenuDialog = ref;
                }}
                schema={
                  {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                    labelAlign: 'right',
                    rows: [
                      [
                        {
                          label: '角色名',
                          propName: 'name',
                          type: 'input',
                          placeholder: '请输入不超过10字',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.model.name,
                            rules: [
                              {
                                required: true,
                                message: '请输入角色名'
                              },
                              {
                                max: 10,
                                message: '请输入不超过10个字的角色名'
                              }
                            ]
                          }
                        }
                      ]
                    ]
                  } as IFormSchema<rbacv2.ModelRole>
                }
              />
            ),
            onCancel: () => {
              this.methods.toggleModelDialog();
              this.customNodeRef.addMenuDialog.props.form.resetFields();
            },
            onOk: async () => {
              this.customNodeRef.addMenuDialog.props.form.validateFields(
                async (errors: Error[], value: any) => {
                  if (!errors) {
                    const opt: AddModelReq = {
                      role: {
                        ...this.observableData.model,
                        ...value
                      }
                    };
                    if (opt.role.id) {
                      await Rbacv2Services.SetRole(opt);
                    } else {
                      await Rbacv2Services.AddRole(opt);
                    }

                    message.success(`${!opt.role.id ? '添加' : '编辑'}成功`);

                    this.listService.refreshList();
                    this.customNodeRef.addMenuDialog.props.form.resetFields();
                    this.methods.toggleModelDialog();
                    return Promise.resolve(value);
                  }
                }
              );
            }
          }}
        />
      </div>
    );
  }
};

export default config;
