import request, {
  RequestInterceptor,
  RequestConfig,
  ResponseInterceptor,
  Response
} from '@fire/httpclient/lib/fetch';
import {
  HEADER_AUTHORIZATION_KEY,
  HEADER_APPID_ID_KEY,
  HEADER_CORP_ID_KEY,
  HEADER_VERSION
} from '@fire/services/lib/const';
import { setClient } from '@/services/apiServices.js';
import { persistUserStore } from 'store';
import errorMsg from 'services/errorMessage';

export interface IReqOptions {
  url: string;
  auth?: string;
}

export default function registerApiServices() {
  request.config.baseURL = `/gateway`;
  request.config.timeout = 10000;

  const { ENV_VERSION, ENV_NAME, ENV_appId, ENV_corpId } = process.env;
  request.config.method = 'POST';
  request.config.headers = {
    'content-type': 'application/json; charset=utf-8',
    [HEADER_VERSION]: `${ENV_NAME}-${ENV_VERSION}`,
    [HEADER_CORP_ID_KEY]: String(ENV_corpId),
    [HEADER_APPID_ID_KEY]: String(ENV_appId)
  };

  request.interceptors.request.use(async function(
    this: RequestInterceptor<RequestConfig>,
    req
  ) {
    // check api auth
    const { auth = '1' } = req;
    if (auth === '1') {
      const userStore = await persistUserStore;
      // check是否有权限访问此接口
      if (!userStore.checkCanIRequest(req.url!.replace(/\/gateway/, ''))) {
        return Promise.reject(`no access: ${req.url!}`);
      }
      try {
        const { sid } = await userStore.handleLoginResp();
        req.headers.append(HEADER_AUTHORIZATION_KEY, sid);
      } catch (error) {
        this.lock();
        userStore.LoginOutAsync();
        this.clear();
        return Promise.reject(error);
      }
    }
    return req;
  });

  request.interceptors.response.use(
    async function(this: ResponseInterceptor<Response>, { data = {}, ...opt }) {
      const code = data.errcode || data.code || 0;
      const errorText = data.errmsg;
      switch (code) {
        case 0:
          return data.data || data;
        case 1002:
          this.lock();
          const userStore = await persistUserStore;
          userStore.LoginOutAsync();
          this.clear();
          break;
        default:
          break;
      }
      // TODO 业务
      const { showToast = true } = opt.request;
      if (showToast) {
        errorMsg(code, errorText);
      }
      console.log('%c request error', 'color:red;', {
        reqid: opt.headers.get('x-req-id'),
        data,
        log: opt.log,
        request: opt.request
      });
      return Promise.reject(data);
    },
    e => {
      switch (e.statusCode) {
        case -1:
          break;
        default:
          break;
      }
    }
  );

  setClient(<T, O extends IReqOptions>(r: T, o: O) =>
    request.POST(o.url, r, o)
  );
}
