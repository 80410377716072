import { Shensuo_ModelUser_Type } from 'services/enumTypes';

/**
 * TypeNil = 0,
  TypeMember = 1, // 会员
  // 以下的type是代理
  TypeChiefBrandOfficer = 2, // 首席品牌官
  TypeAreaAgency = 3, // 区荣誉特使
  TypeCityAgency = 4, // 城市代言人
  TypeProvinceAgency = 5, // 省级代言人
  TypeRegionalPartner = 6, // 大区合伙人
  TypeTutorClassTrainee = 7, // 导师班学员
  TypeSeniorPartner = 8, // 高级合伙人
  TypeStaff = 9, // 身所员工
  TypeFounder = 10, // 身所创始人
 * 
 */
export const customerLevelMap = {
  [Shensuo_ModelUser_Type.TypeMember]: '会员',
  [Shensuo_ModelUser_Type.TypeChiefBrandOfficer]: '首席品牌官',
  [Shensuo_ModelUser_Type.TypeAreaAgency]: '区荣誉特使',
  [Shensuo_ModelUser_Type.TypeCityAgency]: '城市代言人',
  [Shensuo_ModelUser_Type.TypeProvinceAgency]: '省级代言人',
  [Shensuo_ModelUser_Type.TypeRegionalPartner]: '大区合伙人',
  [Shensuo_ModelUser_Type.TypeTutorClassTrainee]: '导师班学员',
  [Shensuo_ModelUser_Type.TypeSeniorPartner]: '高级合伙人',
  [Shensuo_ModelUser_Type.TypeStaff]: '身所员工',
  [Shensuo_ModelUser_Type.TypeFounder]: '身所创始人',
  [Shensuo_ModelUser_Type.TypeTutor]: '明星导师',
  [Shensuo_ModelUser_Type.TypeNormalTutor]: '导师',
  [Shensuo_ModelUser_Type.TypeGroupProjectShareholder]: '集团项目股东'
};
