import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import { AdminServices, Rbacv2Services } from '@/services/apiServices.js';
import { Admin_GetAdminListReq_ListOption } from '@/services/enumTypes';

import { message, Icon } from 'antd';
import CURDTable from '@/components/Schema/CURDTable';

import SchemaForm, { IFormSchema, Form } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';
import { toJS } from 'mobx';

type IModel = admin.Account & { password?: string };
type IFetchListReq = admin.GetAdminListReq;
type IFetchListRsp = admin.GetAdminListRsp;

type SchemaState = {
  addDialog: boolean;
  model: IModel;
  dept_map: { [id: number]: number };
  role_map: { [key: number]: rbacv2.ModelRole };
};

const config: ICURDTableSchema<IModel, SchemaState, IFetchListRsp> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '添加管理员',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          this.methods.setModelData();
          this.methods.toggleModelDialog();
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable) {
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        methods: {},
        rows: [
          [
            {
              type: 'input',
              placeholder: '请输入管理员姓名/角色名称',
              componentProps: {
                style: {
                  width: 300
                },
                autoComplete: 'off'
              },
              FieldDecoratorOptions: {
                getValueFromEvent: event => {
                  return event.target.value.replace(/\s+/g, '');
                }
              },
              propName: Admin_GetAdminListReq_ListOption.ListOptionNameSearch
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    scroll: { y: 'calc(100vh - 410px)' },
    columns: [
      {
        dataIndex: 'name',
        title: '管理员姓名',
        width: 200,
        render(name, item) {
          return (
            <span>
              {name}
              {/* {item.en_name ? `(${item.en_name})` : ''} */}
            </span>
          );
        }
      },
      {
        dataIndex: 'phone',
        title: '手机号码',
        width: 200
      },
      {
        dataIndex: 'role_id_list',
        title: '所在角色',
        width: 300,
        dataType: 'status',
        statusFn(this: SchemaCURDTable, value: number) {
          return this.observableData.role_map[value].name;
        }
      },
      {
        dataIndex: 'created_at',
        title: '添加时间',
        width: 200,
        dataType: 'date'
      },
      {
        dataIndex: 'is_disable',
        title: '状态',
        dataType: 'status',
        statusMap: {
          true: '冻结',
          false: '正常'
        },
        width: 150
      },
      {
        title: '操作',
        align: 'center',
        dataType: 'btn-group',
        renderBtnGroup(value) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  this.methods.setModelData(value);
                  this.methods.toggleModelDialog();
                }
              },
              {
                type: 'link',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  onConfirm(this: CURDTable) {
                    AdminServices.DelAdmin({
                      id: value.id!
                    }).then(() => {
                      message.success('删除成功');
                      this.listService.refreshList();
                    });
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      }
    ]
  },
  observableData: {
    addDialog: false,
    model: {
      name: ''
    },
    dept_map: {},
    role_map: {}
  },
  methods: {
    async setModelData(model?: IModel) {
      this.observableData.model = {
        ...model
      };
    },
    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },
    getFetchListResult(this: SchemaCURDTable, result) {
      if (result.resp!) {
        const { list, role_map } = result!.resp!;
        this.table.dataSource = list;
        this.observableData.role_map = role_map;
      }
      this.loading = result.loading;
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(AdminServices.GetAdminList);
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, model } = toJS(observableData) as SchemaState;

    return (
      <>
        <SchemaDialog
          schema={{
            visible: addDialog,
            destroyOnClose: true,
            title: `${model!.id ? '编辑' : '添加'}管理员`,
            children: (
              <SchemaForm
                wrappedComponentRef={(ref: any) => {
                  this.customNodeRef.addMenuDialog = ref;
                }}
                schema={
                  {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                    labelAlign: 'right',
                    methods: {
                      async componentDidMount(this: Form) {
                        const { list } = await Rbacv2Services.GetRoleList({
                          list_option: {}
                        });
                        console.log('list',list);
                        this.updateFormItemData('role_id_list', {
                          selectOptions: list!
                        });
                      }
                    },
                    rows: [
                      [
                        {
                          label: '姓名',
                          propName: 'name',
                          type: 'input',
                          placeholder: '请输入不超过20字',
                          componentProps: {
                            autoComplete: 'off'
                          },
                          FieldDecoratorOptions: {
                            initialValue: model.name,
                            rules: [
                              {
                                required: true,
                                message: '请输入姓名'
                              },
                              {
                                max: 20,
                                message: '请输入不超过20字'
                              }
                            ],
                            getValueFromEvent: (event: any) => {
                              const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
                              return event.target.value.replace(reg, '');
                            }
                          }
                        }
                      ],
                      [
                        {
                          label: '手机号',
                          propName: 'phone',
                          type: 'input',
                          placeholder: '请输入手机号码',
                          FieldDecoratorOptions: {
                            initialValue: model.phone,
                            rules: [
                              {
                                required: true,
                                message: '请输入手机号码'
                              },
                              {
                                message: '请输入正确的手机号码',
                                pattern: /^1(3|4|5|6|7|8|9)\d{9}$/
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '角色',
                          propName: 'role_id_list',
                          type: 'select',
                          selectOptions: [],
                          selectOption: {
                            valueKey: 'id',
                            labelKey: 'name'
                          },
                          needObservable: true,
                          placeholder: '请选择所在角色',
                          FieldDecoratorOptions: {
                            initialValue: model.role_id_list
                              ? model.role_id_list[0]
                              : [],
                            rules: [
                              {
                                required: true,
                                message: '请选择所在角色'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '登录密码',
                          propName: 'password',
                          type: 'input-password',
                          placeholder: '请输入登录密码',
                          componentProps: {
                            autoComplete: 'new-password'
                          },
                          FieldDecoratorOptions: {
                            initialValue: model.password,
                            rules: [
                              {
                                required: model.id ? false : true,
                                message: '请输入登录密码'
                              },
                              {
                                max: 20,
                                message: '请输入不超过20字符'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '状态',
                          propName: 'is_disable',
                          type: 'radio',
                          radioGroup: [
                            {
                              value: false,
                              children: '正常'
                            },
                            {
                              value: true,
                              children: '冻结'
                            }
                          ],
                          FieldDecoratorOptions: {
                            initialValue: model.is_disable,
                            rules: [
                              {
                                required: true,
                                message: '请选择状态'
                              }
                            ]
                          }
                        }
                      ]
                    ]
                  } as IFormSchema<IModel>
                }
              />
            ),
            onCancel: () => {
              this.methods.toggleModelDialog();
              this.customNodeRef.addMenuDialog.props.form.resetFields();
            },
            onOk: async () => {
              this.customNodeRef.addMenuDialog.props.form.validateFields(
                async (errors: any, value: IModel) => {
                  if (!errors) {
                    const opt: admin.AddAdminReq = {
                      account: {
                        ...this.observableData.model,
                        ...value,
                        role_id_list: value.role_id_list
                          ? [value.role_id_list]
                          : []
                      },
                      password: value.password
                    };
                    console.log('opt',opt);
                    if (opt.account.id) {
                      await AdminServices.SetAdmin(opt);
                    } else {
                      await AdminServices.AddAdmin(opt);
                    }
                    message.success(`${opt.account.id ? '编辑' : '添加'}成功`);
                    this.customNodeRef.addMenuDialog.props.form.resetFields();
                    this.listService.refreshList();
                    this.methods.toggleModelDialog();
                    return Promise.resolve(value);
                  }
                }
              );
            }
          }}
        />
      </>
    );
  }
};

export default config;
