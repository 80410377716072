import React from 'react';
import { Menu as AntMenu, Icon } from 'antd';
import { useRouter } from 'hooks/react-router';

import './menu.scss';

type IMenuModel = rbacv2.ModelMenu & {
  ex?: {
    hidden?: boolean;
    breadcrumbIds?: number[];
    redirectTo?: string;
  };
};

type MenuItem = IMenuModel & {
  subMenu?: IMenuModel[];
};

type IMenuProps = {
  menuTree: MenuItem[];
  isMenuCollapsed: boolean;
  currentPath: string;
};

const prefixPath = (path: string) => (path![0] === '/' ? path! : `/${path!}`);

const renderMenuItem = (routes: MenuItem[]) =>
  routes.map(({ readable_name, icon, path, subMenu, ex }) => {
    if (ex && ex.hidden) {
      return null;
    }
    return !subMenu ? (
      <AntMenu.Item key={prefixPath(path!)}>
        {icon ? <Icon type={icon!} /> : null}
        <span>{readable_name}</span>
      </AntMenu.Item>
    ) : (
      <AntMenu.SubMenu
        key={prefixPath(path!)}
        title={
          <span>
            {icon ? <Icon type={icon!} /> : null}
            <span>{readable_name}</span>
          </span>
        }
      >
        {renderMenuItem(subMenu)}
      </AntMenu.SubMenu>
    );
  });

// TODO path 有 params 就gg
function getOpenKeys(currentPath: string) {
  return currentPath
    .split('/')
    .filter(String)
    .map((v, i, arr) => prefixPath(arr[i - 1] ? [arr[i - 1], v].join('/') : v));
}

function Menu(props: IMenuProps) {
  const { isMenuCollapsed, menuTree, currentPath } = props;

  const { history } = useRouter();

  const [openKeys, setOpenKeys] = React.useState(getOpenKeys(currentPath));
  // if (!openKeys.includes(currentPath)) {
  //   setOpenKeys(getOpenKeys(currentPath));
  // }

  return (
    <div className={['menu', isMenuCollapsed ? 'mini' : null].join(' ')}>
      <div className="menu__header">
        <span className="menu__header-name">
          {process.env.ENV_DISPLAY_NAME}管理后台
        </span>
      </div>
      <div className="menu__body">
        <AntMenu
          openKeys={openKeys}
          selectedKeys={openKeys}
          theme="dark"
          forceSubMenuRender
          onClick={params => {
            history.replace(params.key);
            setOpenKeys(getOpenKeys(params.key));
          }}
          onOpenChange={openKeys => setOpenKeys(openKeys)}
          mode={isMenuCollapsed ? 'vertical' : 'inline'}
        >
          {renderMenuItem(menuTree)}
        </AntMenu>
      </div>
    </div>
  );
}

export { MenuItem, IMenuModel };

export default Menu;
