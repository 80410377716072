import React from 'react';
import BasicLayout from './BasicLayout';

import { IRoutercomponentProps } from '@/router/lib/routers';

function LayoutRender(props: IRoutercomponentProps) {
  return <BasicLayout {...props} />;
}

export default LayoutRender;
