import React from 'react';

import { IRouterConfig } from './index';
import RouterProviderContext from './context';

export function RouterProvider({
  children,
  routerConfig
}: React.PropsWithChildren<{ routerConfig: IRouterConfig }>) {
  // const parentValue = React.useContext(RouterProviderContext);
  // const value = React.useRef({
  //   ...parentValue,
  //   ...routerConfig
  // }).current;

  console.warn('routing', routerConfig);

  return (
    <RouterProviderContext.Provider {...{ children, value: routerConfig }} />
  );
}

RouterProvider.displayName = 'FireRouterProvider';
