import commonStore, { IRootAreaCode } from '@/store/common';
import moment from 'moment';

export const combineAreas = (
  province: string,
  city: string,
  area?: string,
  region?: string
) => {
  const citiesMap = commonStore.cities;
  const regionsMap = commonStore.region['86'];
  let r = '';
  let p = '';
  let c = '';
  let a = '';
  try {
    region && (r = regionsMap[region] || '');
    province && (p = citiesMap[province].name[0] || '');
    province && city && (c = citiesMap[province].children[city].name[0]);
    province &&
      city &&
      area &&
      (a = citiesMap[province].children[city].children[area].name[0]);
  } catch (e) {
    // console.log(e);
  } finally {
    return p ? `${p}${c}${a}` : `${r}`;
  }
};

export const resolveSigningArea = (
  province: string,
  city: string,
  area: string,
  region: string
): string[] => {
  // selection is between region and province&&city&&area
  if (province) {
    return [IRootAreaCode.ALL_PROVINCES, province, city, area].filter(v => v);
  } else if (region) {
    return [IRootAreaCode.REGION, region];
  } else {
    return [];
  }
};

export const seperateSigningAreaArr = (
  arr: string[]
): {
  signing_regional: string;
  signing_province: string;
  signing_city: string;
  signing_area: string;
} => {
  const isRegionSelected = arr[0] === IRootAreaCode.REGION;
  return {
    signing_regional: (isRegionSelected && arr[1]) || '',
    signing_province: (!isRegionSelected && arr[1]) || '',
    signing_city: (!isRegionSelected && arr[2]) || '',
    signing_area: (!isRegionSelected && arr[3]) || ''
  };
};

export const disabledPassedDate = (
  current: moment.Moment | undefined
): boolean => {
  if (!current) return true;
  // Can not select days before today and today
  return current && current < moment().startOf('day');
};
