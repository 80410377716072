import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { ButtonProps } from 'antd/lib/button/button.d';
import { PopconfirmProps } from 'antd/lib/popconfirm/index.d';
import { action, runInAction } from 'mobx';
import t from '@/libs/utils';

export function BindEventHandlerThis<T = any, P = {}>(
  this: T,
  props: P,
  ...keys: string[]
) {
  const eventFunctionNames = [
    'onClick',
    'onConfirm',
    'onCancel',
    'onChange',
    'onSubmit',
    'onSelect',
    'onSearch',
    'onReset',
    ...keys
  ];
  // TODO
  Object.keys(props).forEach(propsName => {
    if (eventFunctionNames.includes(propsName)) {
      const original = props[propsName].original || props[propsName];
      const fn = t.deepClone(original);
      props[propsName] = action(propsName, fn.bind(this));
      props[propsName].original = original;
      // props[propsName] = action(propsName, props[propsName].bind(this));
      // props[propsName] = props[propsName].bind(this);
    }
  });
}

function isNativePromise(p: any) {
  return (
    p &&
    typeof p.constructor === 'function' &&
    Function.prototype.toString.call(p.constructor).replace(/\(.*\)/, '()') ===
      Function.prototype.toString
        .call(/*native object*/ Function)
        .replace('Function', 'Promise') // replacing Identifier
        .replace(/\(.*\)/, '()')
  ); // removing possible FormalParameterList
}

export type ISchemaButtonProps = ButtonProps & {
  /**
   * 代理button onclick 返回promise 支持loading
   * @type {React.MouseEventHandler<HTMLElement>}
   */
  proxyOnClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * 确认操作弹框 有此结构，请使用 PopconfirmProps 的事件
   * @type {PopconfirmProps}
   */
  popconfirmProps?: PopconfirmProps;
};

/**
 * 使用antd button 增加 proxyOnClick 返回promise 支持loading态， 支持确认删除按钮
 * @export
 * @param {ISchemaButtonProps} {
 *   proxyOnClick,
 *   popconfirmProps,
 *   ...props
 * }
 * @returns
 */
export function getSchemaButton({
  proxyOnClick,
  popconfirmProps,
  ...props
}: ISchemaButtonProps) {
  return function SchemaButton<T = any>(this: T) {
    const [loading, setLoading] = React.useState(false);

    if (proxyOnClick) {
      props.onClick = function() {
        const result = proxyOnClick!.apply(this, arguments as any);
        if (isNativePromise(result)) {
          setLoading(true);
        }

        Promise.resolve(result).finally(() => {
          setLoading(false);
        });
      };
    }
    if (popconfirmProps) {
      BindEventHandlerThis.call(this, popconfirmProps);

      return (
        <Popconfirm {...popconfirmProps}>
          <Button loading={loading} {...props} />
        </Popconfirm>
      );
    }
    BindEventHandlerThis.call(this, props);
    return <Button loading={loading} {...props} />;
  };
}

export function WarpSchemacomponent<T>(target: React.ComponentClass<T>) {}

export type SchemaProps<T> = {
  schema: T;
};

export type SchemaState<T> = {};
@WarpSchemacomponent
export class SchemaComponent<
  S,
  Props extends SchemaProps<S>,
  State extends SchemaState<S>
> extends React.Component<Props, State> {
  message = message;

  asyncAction<T>(block: () => T) {
    runInAction(block);
  }
}
