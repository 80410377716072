import React from 'react';
import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import { message } from 'antd';
import { toJS } from 'mobx';

import CURDTable from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema, Form } from '@/components/Schema/Form';
import { ISelectOption } from '@/components/Schema/Form/components';
import SchemaDialog from '@/components/Schema/Dialog';
import { Icon } from 'antd';
import moment, { Moment } from 'moment';
import { ShensuoServices } from '@/services/apiServices.js';
// import t from '@/libs/utils';
import styles from './index.module.scss';

type IModel = Omit<shensuo.ModelBanner, 'image'> & {
  activityTime?: [Moment, Moment];
  image?: string[];
};
type AddModelReq = shensuo.AddBannerAdminReq;
type IFetchListReq = shensuo.GetBannerListAdminReq;
type IFetchListRsp = shensuo.GetBannerListAdminRsp;

type SchemaState = {
  addDialog: boolean;
  model: IModel;
  jumpTypeSelectOptions: any[];
  jumpTypeSelectOption: ISelectOption;
};

type bannerConfigOptions = {
  defaultReq: Partial<shensuo.ModelBanner>;
  jumpType: {
    [key: string]: string;
  };
  getJumpTypeOptions?(
    jumpType: number
  ): Promise<{
    options: any[];
    option?: ISelectOption;
  }>;
};

function mapToOptions(map: {
  [key: string]: string;
}): {
  label: string;
  value: number;
}[] {
  return Object.keys(map).map(v => ({
    label: map[v],
    value: +v
  }));
}

export const getBannerConfig = (options: bannerConfigOptions) => {
  const { defaultReq, jumpType, getJumpTypeOptions } = options;

  const jumpTypeOptions = mapToOptions(jumpType);

  const config: ICURDTableSchema<
    commonv2.ModelBanner,
    SchemaState,
    IFetchListRsp
  > = {
    header: {
      action: [
        {
          type: 'primary',
          children: '添加banner',
          icon: 'plus',
          onClick(this: SchemaCURDTable) {
            this.methods.setModelData();
            this.methods.toggleModelDialog();
          }
        }
      ]
    },
    table: {
      rowKey: 'id',
      rowClassName: () => styles.row,
      scroll: { x: 1000, y: 'calc(100vh - 220px)' },
      columns: [
        {
          title: '操作',
          width: 122,
          fixed: 'left',
          dataType: 'btn-group',
          renderBtnGroup(value) {
            return {
              needDivider: false,
              btns: [
                {
                  type: 'link',
                  children: '编辑',
                  size: 'small',
                  onClick(this: SchemaCURDTable) {
                    console.log('bianji', value);
                    this.methods.setModelData(value);
                    this.methods.toggleModelDialog();
                  }
                },
                {
                  type: 'link',
                  size: 'small',
                  style: {
                    color: '#ff4d4f'
                  },
                  popconfirmProps: {
                    title: '确定删除此项？',
                    icon: (
                      <Icon
                        type="question-circle-o"
                        style={{ color: '#ff4d4f' }}
                      />
                    ),
                    onConfirm(this: CURDTable) {
                      ShensuoServices.DelBannerListAdmin({
                        id: value.id!
                      }).then(() => {
                        message.success('删除成功');
                        this.listService.refreshList();
                      });
                    }
                  },
                  children: '删除'
                }
              ]
            };
          }
        },
        {
          dataIndex: 'view_order',
          title: '排序',
          width: 100,
          ellipsis: true
        },
        {
          dataIndex: 'image',
          title: '图片',
          width: 180,
          render(value) {
            return (
              <div className="thumbnail-list">
                <img src={value} alt="" />
              </div>
            );
          }
        },
        {
          title: '时间',
          width: 180,
          ellipsis: true,
          render(value) {
            if (value.begin_time === 0 && value.end_time === 0) return '--';
            return (
              <>
                <div>
                  {moment(value.begin_time * 1000).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                  至
                </div>
                <div>
                  {moment(value.end_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
                </div>
              </>
            );
          }
        },
        {
          dataIndex: 'jump_type',
          title: '跳转类型',
          width: 150,
          ellipsis: true,
          dataType: 'status',
          statusMap: jumpType
        },
        {
          dataIndex: 'jump_content',
          title: '跳转内容'
        }
      ],
      pagination: false
    },
    observableData: {
      addDialog: false,
      model: {
        type: defaultReq!.type!
      },
      jumpTypeSelectOptions: [],
      jumpTypeSelectOption: {
        valueKey: 'value',
        labelKey: 'label'
      }
    },
    methods: {
      processFormValue(value: IModel, defaultVal?: Partial<IModel>) {
        const {
          observableData: { jumpTypeSelectOptions, jumpTypeSelectOption, model }
        } = this;
        const { activityTime, image, jump_detail, ...params } = value;
        // const new_jump_detail:any = t.deepClone(jump_detail)
        const opt: AddModelReq = {
          banner: {
            ...model,
            jump_detail: String(jump_detail),
            image: String(image!),
            ...params,
            ...defaultVal
          }
        };
        opt.banner.jump_detail = String(opt.banner.jump_detail);
        opt.banner.jump_content = '';
        // check
        try {
          // new_jump_detail.map((v:number) => v)
          // console.log(
          //   '-----',
          //   jumpTypeSelectOptions.filter((v: any) => {
          //     console.log('vvv', v);
          //     return (
          //       +v[jumpTypeSelectOption.valueKey] === +opt.banner.jump_detail!
          //     );
          //   })
          // );
          opt.banner.jump_content = jumpTypeSelectOptions.find(
            (v: any) =>
              +v[jumpTypeSelectOption.valueKey] === +opt.banner.jump_detail!
          )[jumpTypeSelectOption.labelKey];
        } catch (error) {}

        if (activityTime && activityTime.length) {
          opt.banner.begin_time = activityTime![0].unix();
          opt.banner.end_time = activityTime![1].unix();
        }
        return opt;
      },
      processModelToFormValue(value: shensuo.ModelBanner) {
        const { image = '', begin_time, end_time, ...params } = value;
        const form: IModel = {
          image: [image].filter(String),
          ...params
        };

        try {
          if (value.jump_detail) {
            // @ts-ignore
            form.jump_detail = value.jump_detail.split(',').map(v => Number(v));
          }
        } catch (error) {}

        if (form.jump_type) {
          this.methods.getJumpTypeOptionsFn(form.jump_type);
        }

        if (begin_time || end_time) {
          form.activityTime = [
            moment.unix(begin_time! || 0),
            moment.unix(end_time! || 0)
          ];
        }

        return form;
      },
      async setModelData(model?: IModel) {
        this.observableData.model = {
          ...this.methods.processModelToFormValue(model! || {}),
          ...defaultReq
        };
      },
      toggleModelDialog(this: SchemaCURDTable) {
        this.observableData.addDialog = !this.observableData.addDialog;
      },
      getFetchListResult(this: SchemaCURDTable, result) {
        if (result.resp!) {
          this.table.dataSource = result.resp!.list;
        }
        this.loading = result.loading;
      },
      componentDidMount(this: SchemaCURDTable) {
        this.listService.setFetchListFn(ShensuoServices.GetBannerListAdmin);
        this.listService.setReq<IFetchListReq>({
          type: defaultReq.type!
        });
        this.listService.getList();
      },
      async getJumpTypeOptionsFn(this: SchemaCURDTable, jumpType: number) {
        if (getJumpTypeOptions) {
          const { options, option } = await getJumpTypeOptions.call(
            this,
            jumpType
          );
          this.asyncAction(() => {
            this.observableData.jumpTypeSelectOptions = options;
            if (option) {
              this.observableData.jumpTypeSelectOption = option;
            }
          });
        }
      }
    },
    customNode(this: SchemaCURDTable) {
      const { observableData, methods } = this;
      const { addDialog, model } = toJS(observableData) as SchemaState;
      console.log('customNode', model.image);
      return (
        <>
          <SchemaDialog
            schema={{
              visible: addDialog,
              title: `${model!.id ? '编辑' : '添加'}banner`,
              children: (
                <SchemaForm
                  wrappedComponentRef={(ref: any) => {
                    this.customNodeRef.modelDialog = ref;
                  }}
                  schema={
                    {
                      labelCol: { span: 5 },
                      wrapperCol: { span: 14 },
                      labelAlign: 'right',
                      rows: [
                        [
                          {
                            label: '图片',
                            propName: 'image',
                            type: 'uploader-with-list',
                            componentProps: {
                              maxLen: 1,
                              accept: 'image/*'
                            },
                            FieldDecoratorOptions: {
                              initialValue: model.image,
                              rules: [
                                {
                                  required: true,
                                  message: '请上传图片'
                                }
                              ]
                            }
                          }
                        ],
                        [
                          {
                            label: '选择时间',
                            propName: 'activityTime',
                            type: 'date-range-picker',
                            placeholder: '请选择活动时间',
                            componentProps: {
                              showTime: true
                            },
                            FieldDecoratorOptions: {
                              initialValue: model.activityTime!
                            }
                          }
                        ],
                        [
                          {
                            label: '排序',
                            propName: 'view_order',
                            type: 'number',
                            FieldDecoratorOptions: {
                              initialValue: model.view_order,
                              rules: [
                                {
                                  validator(rule, value, callback) {
                                    if (!value) {
                                      callback();
                                      return;
                                    }
                                    const number = Number(value);
                                    if (Number.isNaN(number)) {
                                      callback('请输入数字');
                                    } else {
                                      if (value < 0) {
                                        callback('必须大于等于0');
                                      } else if (value > 1000) {
                                        callback('必须小于等于1000');
                                      } else if (!Number.isInteger(number)) {
                                        callback('请输入正整数');
                                      } else {
                                        callback();
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        [
                          {
                            label: '跳转类型',
                            propName: 'jump_type',
                            type: 'select',
                            placeholder: '请选择跳转类型',
                            selectOptions: jumpTypeOptions,
                            selectOption: {
                              valueKey: 'value',
                              labelKey: 'label'
                            },
                            componentProps: {
                              onChange(this: Form, value: number) {
                                methods.getJumpTypeOptionsFn(value);
                              }
                            },
                            FieldDecoratorOptions: {
                              initialValue: model.jump_type
                            }
                          }
                        ],
                        [
                          {
                            label: '跳转内容',
                            propName: 'jump_detail',
                            type: 'select',
                            placeholder: '请选择跳转类型',
                            componentProps: {
                              mode: 'multiple'
                            },
                            selectOptions: this.observableData
                              .jumpTypeSelectOptions,
                            selectOption: this.observableData
                              .jumpTypeSelectOption,
                            dynamicSetFormItemProps(this: Form) {
                              const {
                                form: { getFieldValue }
                              } = this.props;
                              return {
                                style: {
                                  display: +getFieldValue('jump_type')
                                    ? 'block'
                                    : 'none'
                                }
                              };
                            },
                            FieldDecoratorOptions: {
                              initialValue: model.jump_detail || ''
                            }
                          }
                        ]
                      ]
                    } as IFormSchema<IModel>
                  }
                />
              ),
              onCancel: () => {
                this.methods.toggleModelDialog();
                this.customNodeRef.modelDialog.props.form.resetFields();
              },
              onOk: async () => {
                this.customNodeRef.modelDialog.props.form.validateFields(
                  async (errors: Error[], value: any) => {
                    value.type = defaultReq.type;
                    if (!errors) {
                      console.log('value', value);
                      this.methods.processFormValue(value);
                      if (this.observableData.model.id) {
                        await ShensuoServices.SetBannerAdmin(
                          this.methods.processFormValue(value)
                        );
                      } else {
                        await ShensuoServices.AddBannerAdmin(
                          this.methods.processFormValue(value)
                        );
                      }
                      message.success(
                        `${!this.observableData.model.id ? '添加' : '编辑'}成功`
                      );
                      this.listService.refreshList();
                      this.customNodeRef.modelDialog.props.form.resetFields();
                      this.methods.toggleModelDialog();
                      return Promise.resolve(value);
                    }
                  }
                );
              }
            }}
          />
        </>
      );
    }
  };
  return config;
};
