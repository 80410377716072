import Layout from '@/Layouts/index';
import SchemaCURDTable from '@/components/Schema/CURDTable';

import menu from './menu';
import editperm from './editperm';
import role from './role';
import user from './user';

export const RbacRouting = {
  path: '/rbac',
  component: Layout,
  subRouter: [
    {
      path: '/menu',
      schema: menu,
      component: SchemaCURDTable
    },
    {
      path: '/editperm',
      component: editperm
    },
    {
      path: '/role',
      schema: role,
      component: SchemaCURDTable
    },
    {
      path: 'user',
      schema: user,
      component: SchemaCURDTable
    },
    {
      to: '/rbac/menu'
    }
  ]
};
