import { MenuItem, IMenuModel } from './menu';
import t from '@/libs/utils';

type MenuRsp = {
  menuList: IMenuModel[];
  menuMap: {
    [menuId: number]: IMenuModel;
  };
  menuTree: MenuItem[];
  authMenus?: MenuItem[];
};

export function getMenuTreeAndMap(menu_list: rbacv2.ModelMenu[]): MenuRsp {
  const menuList = t.deepClone(menu_list).map(v => {
    if (v.ex) {
      v.ex = JSON.parse(v.ex);
    }
    return v;
  }) as IMenuModel[];
  const menuTree: MenuItem[] = [];
  const map: { [id: number]: MenuItem } = menuList!.reduce((acc, item) => {
    acc[item.id!] = t.deepClone(item);
    return acc;
  }, {});

  const menuMap = t.deepClone(map);

  const sortFn = (o: MenuItem, n: MenuItem) => {
    return o.view_order! - n.view_order!;
  };

  Object.keys(map).forEach(id => {
    const menu = map[id];
    if (menu.parent_id) {
      if (map[menu.parent_id]) {
        map[menu.parent_id].subMenu = [
          ...(map[menu.parent_id].subMenu || []),
          menu
        ];
        map[menu.parent_id].subMenu!.sort(sortFn);
      }
    } else {
      menuTree.push(menu);
    }
  });

  menuTree.sort(sortFn);

  return {
    menuList,
    menuMap,
    menuTree
  };
}

export function filterMenuTreeAndMap(
  menuList: rbacv2.ModelMenu[],
  menuIds: number[]
): MenuRsp {
  const meunMap: { [id: number]: MenuItem } = menuList!.reduce((acc, item) => {
    acc[item.id!] = item;
    return acc;
  }, {});

  const getMenu = (menus: MenuItem[], menuId: number): MenuItem[] => {
    if (meunMap[menuId]) {
      menus.push(meunMap[menuId]);
      if (meunMap[menuId].parent_id!) {
        menus = getMenu(menus, meunMap[menuId].parent_id!);
      }
    }
    return menus;
  };

  const authMenus = menuIds.reduce(getMenu, []);

  return {
    ...getMenuTreeAndMap(authMenus),
    authMenus
  };
}
