// must has a key
type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export interface IVaildRule {
  type?: 'string' | 'array' | 'number' | 'phone';
  require?: boolean;
  msg?: string;
  reg?: RegExp;
  rules?: IVaildRules;
  maxLen?: number;
  minLen?: number;
  vaildFunc?: (value: any) => boolean | Promise<boolean>;
}
export interface IVaildRules {
  [key: string]: RequireAtLeastOne<IVaildRule>[];
}

interface IRuleError {
  key: string;
  msg?: string;
}

export interface IVaildRuleResult {
  errors: IRuleError[];
  valid: boolean;
}

export default {
  validatePhone(phone: string): boolean {
    const regexp = /^1[3|4|5|6|7|8|9]\d{9}$/;
    return regexp.test(phone);
  },

  async vaildObject<T extends object>(
    obj: T,
    rules: IVaildRules = {}
  ): Promise<IVaildRuleResult> {
    const result: IVaildRuleResult = { valid: true, errors: [] };
    if (obj instanceof Array) {
      for (let [i, v] of new Map(obj.map((v, i) => [i, v]))) {
        // TODO 如果每一项不是object
        const vaildResp = await this.vaildObject(v, rules);
        result.valid = vaildResp.valid;
        if (!result.valid) {
          result.errors.push(
            ...vaildResp.errors.map(e => ({ ...e, key: `[${i}].${e.key}` }))
          );
        }
      }
    } else {
      for (let index = 0; index < Object.keys(rules).length; index++) {
        const k = Object.keys(rules)[index];
        if (obj.hasOwnProperty(k)) {
          const val = obj[k];
          for (const {
            vaildFunc,
            msg,
            require = true,
            type: valType = typeof val,
            reg,
            rules: arrayRule = {},
            maxLen,
            minLen
          } of rules[k]) {
            let type = valType;
            if (type === 'object' && obj[k] instanceof Array) {
              type = 'array';
            }
            const errorItem = { msg, key: k };
            if (require) {
              if (vaildFunc) {
                result.valid = Boolean(await vaildFunc.call(this, val));
              } else {
                switch (type) {
                  case 'array':
                    try {
                      const vaildResp = await this.vaildObject(val, arrayRule);
                      result.valid = vaildResp.valid;
                      if (!result.valid) {
                        result.errors.push(
                          ...vaildResp.errors.map((v: IRuleError) => ({
                            ...v,
                            key: `${errorItem.key}${v.key}`
                          }))
                        );
                        errorItem.key = '';
                      }

                      if (minLen !== undefined && val.length < +minLen) {
                        errorItem.key = k;
                        result.valid = false;
                      }

                      if (maxLen !== undefined && val.length > +maxLen) {
                        errorItem.key = k;
                        result.valid = false;
                      }
                    } catch (err) {}
                    break;
                  case 'string':
                    result.valid = !!(reg ? reg.test(val) : val);
                    break;
                  case 'phone':
                    result.valid = this.validatePhone(val);
                    break;
                  default:
                    result.valid = !!val;
                    break;
                }
              }
            }
            if (!result.valid && errorItem.key) {
              result.errors.push(errorItem);
            }
          }
        }
      }
    }
    result.valid = !result.errors.length;
    return result;
  },

  getObjectValueByStringKey<T>(o: T, s: string) {
    const keys = s
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.');
    for (let i = 0, n = keys.length; i < n; ++i) {
      const k = keys[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
};
