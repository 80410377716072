import React from 'react';
import { Tree, Icon, Button, message } from 'antd';
import styled from 'styled-components';
import { IRoutercomponentProps } from '@/router/lib/routers';

import { getMenuTreeAndMap } from '@/Layouts/BasicLayout/menu.services';
import { MenuItem } from '@/Layouts/BasicLayout/menu';

import { Rbacv2Services } from '@/services/apiServices.js';

type IEditPermProps = IRoutercomponentProps & {};
type IEditPermState = {
  menuTree: MenuItem[];
  checkedKeys: string[];
};

const Container = styled.div`
  padding: 20px;

  .ant-tree-switcher {
    display: none !important;
  }
  .ant-tree-treenode-switcher-open {
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .ant-tree-child-tree {
    display: flex;
    align-items: center;
  }
`;

class EditPerm extends React.Component<IEditPermProps, IEditPermState> {
  state: IEditPermState = {
    menuTree: [],
    checkedKeys: []
  };

  roleId: number = 0;

  componentDidMount() {
    this.roleId = +this.props.query.id;
    this.GetRolePerm();
    this.getMenuTree();
  }

  GetRolePerm = async () => {
    const { menu_id_list } = await Rbacv2Services.GetRolePerm({
      role_id_list: [this.roleId]
    });
    this.setState({ checkedKeys: menu_id_list!.map(String) });
  };

  savePerm = async () => {
    const { checkedKeys } = this.state;
    await Rbacv2Services.SetRolePerm({
      role_id: this.roleId,
      menu_id_list: checkedKeys.map(Number)
    });
    message.success('编辑成功');
  };

  async getMenuTree() {
    try {
      const { menu_list } = await Rbacv2Services.GetMenuTree({
        menu_tree_id: +process.env.ENV_appId!,
        corp_id: +process.env.ENV_corpId!
      });
      const { menuTree } = getMenuTreeAndMap(menu_list!);
      this.setState({ menuTree });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  renderTreeNodes(menuTree: MenuItem[] = []) {
    return menuTree.map(v => (
      <Tree.TreeNode
        title={
          <span>
            {v.icon ? <Icon type={v.icon} style={{ marginRight: 10 }} /> : null}
            {v.readable_name}
          </span>
        }
        key={String(v.id!)}
        children={this.renderTreeNodes(v.subMenu!)}
      />
    ));
  }

  render() {
    const { menuTree, checkedKeys } = this.state;

    return (
      <Container>
        {menuTree.length ? (
          <>
            <Tree
              checkable
              selectable={false}
              multiple
              defaultExpandAll
              checkedKeys={checkedKeys}
              onCheck={checkedKeys => {
                this.setState({ checkedKeys: checkedKeys as string[] });
              }}
            >
              {this.renderTreeNodes(menuTree)}
            </Tree>
            <Button
              type="primary"
              onClick={this.savePerm}
              style={{ marginRight: 10 }}
            >
              保存
            </Button>
            <Button>重置</Button>
          </>
        ) : null}
      </Container>
    );
  }
}

export default EditPerm;
