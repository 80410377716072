import React from 'react';
import { Divider } from 'antd';
import { getSchemaButton, ISchemaButtonProps } from '../Schema';

interface IBtnGroupProps {
  needDivider?: boolean;
  btns: ISchemaButtonProps[];
}

function getBtnGroup(props: IBtnGroupProps) {
  return function BtnGroup<T = any>(this: T) {
    const { btns, needDivider = true } = props;

    return (
      <>
        {btns.map((p, i) => {
          const SchemaButton = getSchemaButton(p).bind(this);
          return (
            <React.Fragment key={i}>
              {needDivider && i !== 0 ? <Divider type="vertical" /> : null}
              <SchemaButton />
            </React.Fragment>
          );
        })}
      </>
    );
  };
}

export { getBtnGroup, IBtnGroupProps };
