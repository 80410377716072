import React from 'react';
import { observer } from 'mobx-react';
import { IRoutercomponentProps } from '@/router/lib/routers';
import { observable, toJS, action } from 'mobx';
import { IKeepAliveLifecycleImpl } from '@/router/lib/keepAlive';
import moment from 'moment';

import { Table } from 'antd';
import { ButtonProps } from 'antd/lib/button/button.d';
import {
  TableProps,
  ColumnProps,
  PaginationConfig
} from 'antd/lib/table/index.d';
import { FormComponentProps } from 'antd/lib/form/index.d';
import { FetchList, FetchResp } from './fetchList';
import SchemaForm, { IFormSchema } from '../Form';

import {
  SchemaProps,
  SchemaState,
  SchemaComponent,
  getSchemaButton,
  BindEventHandlerThis
} from '../Schema';
import { getBtnGroup, IBtnGroupProps } from './btnGroup';

import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import style from './index.module.scss';

type ICURDTableSchemaHeader = {
  action?: ButtonProps[];
  query?: {
    schema: IFormSchema;
  };
};

type IObservableData = {};

type ITableColumnItem<RecoedItem = any> = ColumnProps<RecoedItem> & {
  dataIndex?: keyof RecoedItem;
  /**
   * [data] 支持格式化时间 [formatData]控制格式 default `'YYYY-MM-DD HH:mm:ss'`
   * [status] 支持显示指定状态 [statusMap] key 设置 `dataIndex` 自动取值， 也可以根据`statusFn`返回值显示
   * [btn-group] 当前为操作栏
   * @type {('date' | 'status' | 'btn-group')}
   */
  dataType?: 'date' | 'status' | 'btn-group' | 'date-range';
  dateRangeKey?: [keyof RecoedItem, keyof RecoedItem];
  dataRangeSeparator?: string;
  statusMap?: {
    [key: string]: string;
  };
  /**
   * [proxyrender] table column render
   */
  proxyrender?: (
    text: any,
    record: RecoedItem,
    index: number
  ) => React.ReactNode;
  statusFn?(value: any): number | string | string[] | number[];
  renderBtnGroup?(text: any, record: RecoedItem, index: number): IBtnGroupProps;
  formatData?: string;
};

type ICURDTableSchema<
  RecoedItem = any,
  ObservableData = IObservableData,
  Rsp = any
> = {
  header?: ICURDTableSchemaHeader;
  table?: Omit<TableProps<RecoedItem>, 'columns'> & {
    columns: ITableColumnItem<RecoedItem>[];
  };
  observableData?: ObservableData;
  // 如果需要keepAlive
  methods?: React.ComponentLifecycle<any, any> &
    IKeepAliveLifecycleImpl & {
      getFetchListResult(result: FetchResp<Rsp>): void;
      [key: string]: (this: CURDTable, ...params: any) => any;
    };
  customNode?(this: CURDTable): React.ReactNode;
};

type ICURDTableProps = SchemaProps<ICURDTableSchema> &
  Partial<IRoutercomponentProps<any, ICURDTableSchema>> & {};
type ICURDTableState = SchemaState<ICURDTableSchema> & {};

function processTableColumn(this: CURDTable, columns: ITableColumnItem[]) {
  columns!.forEach((column: ITableColumnItem) => {
    const {
      dataType,
      formatData,
      statusMap,
      statusFn,
      renderBtnGroup,
      proxyrender,
      dateRangeKey,
      dataRangeSeparator
    } = column;
    if (dataType) {
      switch (dataType) {
        case 'date':
          column.render = function(value: number) {
            return (
              <>
                {moment.unix(value).format(formatData || 'YYYY-MM-DD HH:mm:ss')}
                {proxyrender ? proxyrender.apply(this, arguments as any) : null}
              </>
            );
          };
          break;
        case 'date-range':
          column.render = function(value: any) {
            if (dateRangeKey && dateRangeKey!.length !== 2) {
              console.error(
                'when dataType is date-range, the dateRangeKey must [key1, key2]'
              );
              return;
            }
            if (!value[dateRangeKey![0]] && !value[dateRangeKey![1]]) {
              return '-';
            }
            return (
              <>
                {value[dateRangeKey![0]]
                  ? moment
                      .unix(value[dateRangeKey![0]])
                      .format(formatData || 'YYYY-MM-DD HH:mm:ss')
                  : '~'}
                <span
                  style={{
                    margin: '0 5px',
                    display:
                      value[dateRangeKey![0]] || value[dateRangeKey![1]]
                        ? 'inline'
                        : 'none'
                  }}
                >
                  {dataRangeSeparator || '-'}
                </span>
                {value[dateRangeKey![1]]
                  ? moment
                      .unix(value[dateRangeKey![1]])
                      .format(formatData || 'YYYY-MM-DD HH:mm:ss')
                  : '~'}
                {proxyrender ? proxyrender.apply(this, arguments as any) : null}
              </>
            );
          };
          break;
        case 'status':
          if (statusMap) {
            column.render = function(value: any) {
              return (
                <>
                  {statusMap[value] || '-'}
                  {proxyrender
                    ? proxyrender.apply(this, arguments as any)
                    : null}
                </>
              );
            };
          } else if (statusFn) {
            column.render = function(value: any) {
              let result = '-';
              try {
                result = String(statusFn.call(this, value));
              } catch (error) {}
              return (
                <>
                  {String(result)}
                  {proxyrender
                    ? proxyrender.apply(this, arguments as any)
                    : null}
                </>
              );
            };
          } else {
            console.warn(
              'statusFn dataType status please register statusFn or statusFn'
            );
          }
          break;
        case 'btn-group':
          column.render = function() {
            if (!renderBtnGroup) {
              console.error(
                'please register renderBtnGroup when dataType is btn-group'
              );
              return;
            }
            const btnGroup = renderBtnGroup!.apply(this, arguments as any);
            const BtnGroup = getBtnGroup(btnGroup!).bind(this);
            return (
              <>
                <BtnGroup />
                {proxyrender ? proxyrender.apply(this, arguments as any) : null}
              </>
            );
          };
          break;
        default:
          break;
      }
    }
    if (column.render) {
      column.render = column.render.bind(this);
    }
  });
}

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    }: any = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props: any) {
    dragingIndex = props.index;
    return {
      index: props.index
    };
  }
};

const rowTarget = {
  drop(props: any, monitor: any) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(BodyRow)
);

const components = {
  body: {
    row: DragableBodyRow
  }
};

@observer
class CURDTable extends SchemaComponent<
  ICURDTableSchema,
  ICURDTableProps,
  ICURDTableState
> {
  @observable.deep observableData: any;
  @observable.deep header: ICURDTableSchemaHeader;
  @observable.deep table: TableProps<any>;

  customNode: () => React.ReactNode;

  @observable total = 0;
  @observable page = 1;
  @observable pageSize = 20;

  listService: FetchList<any, any> = new FetchList();

  @observable loading = true;
  methods: {
    [fn: string]: (...params: any) => any;
  } = {};

  customNodeRef: any = {};

  searchFormRef: {
    props: FormComponentProps;
  } | null = null;

  constructor(props: ICURDTableProps) {
    super(props);

    const { observableData, header, table, customNode, methods } = props.schema;

    if (table!.columns!) {
      processTableColumn.call(this, table!.columns!);
    }

    if (table!.rowSelection!) {
      BindEventHandlerThis.call(this, table!.rowSelection!);
    }

    BindEventHandlerThis.call(this, table, 'onRow');

    this.header = header!;
    this.table = table!;
    this.observableData = observableData;
    this.customNode = customNode!;

    this.listService.limit = this.pageSize;

    // apply table pagination
    if (table!.pagination !== false) {
      this.table!.pagination = {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: () => `总数:${this.listService.total}`,
        onChange: action((current: number, size?: number) => {
          this.listService.changePageLimeList(current, size!);
        }),
        onShowSizeChange: action((current: number, size: number) => {
          this.listService.changePageLimeList(1, size);
        }),
        ...this.table!.pagination
      };
    }

    // fetchList action in action
    const { getFetchListResult, ...funs } = methods!;
    this.listService.setToViewFn(
      action((result: FetchResp<any>) => {
        if (table!.pagination !== false) {
          // apply total page pageSize
          (this.table
            .pagination! as PaginationConfig).pageSize! = result!.limit!;
          (this.table.pagination! as PaginationConfig).current! = result!.page!;
          (this.table.pagination! as PaginationConfig).total! = result!.total!;
        }
        getFetchListResult.call(this, result);
      })
    );

    // mounted methos in this class and this.methos
    for (const fn in funs!) {
      if (!this[fn]) {
        this[fn] = action(fn, funs[fn].bind(this));
      } else {
        const originFunc = this[fn];
        this[fn] = function() {
          originFunc.call(this, ...(arguments as any));
          action(fn, funs[fn].bind(this)).call(this, ...(arguments as any));
        };
      }
      this.methods[fn] = action(fn, funs[fn].bind(this));
    }
  }

  renderHeaderAction() {
    const header = toJS(this.header);

    return header && header.action && header.action.length ? (
      <div className={style.CURDTable_header_action}>
        {header.action.map((props, key) => {
          const SchemaButton = getSchemaButton(props).bind(this);
          return <SchemaButton key={key} />;
        })}
      </div>
    ) : null;
  }

  renderHeaderQuery() {
    const header = toJS(this.header);

    return header && header.query && header.query.schema ? (
      <div className={style.CURDTable_header_query}>
        {BindEventHandlerThis.call(this, header.query!.schema!)}
        <SchemaForm
          wrappedComponentRef={(ref: any) => (this.searchFormRef = ref)}
          {...this.props}
          parentInstance={this}
          schema={header.query!.schema!}
        />
      </div>
    ) : null;
  }

  renderBody() {
    const { loading } = this;
    const table = toJS(this.table);

    if (table.onRow) {
      const new_table = Object.assign({ ...table, components });
      return (
        <div className={style.CURDTable_body}>
          <DndProvider backend={HTML5Backend}>
            <Table {...{ ...new_table, loading }} />
          </DndProvider>
        </div>
      );
    }
    return (
      <div className={style.CURDTable_body}>
        <Table {...{ ...table, loading }} />
      </div>
    );
  }

  render() {
    const { customNode } = this;

    return (
      <div className={style.CURDTable}>
        <div ref="tableHeader" className={style.CURDTable_header}>
          {this.renderHeaderQuery()}
          {this.renderHeaderAction()}
        </div>
        {this.renderBody()}
        {customNode && customNode.call(this)}
      </div>
    );
  }
}

export default CURDTable;

export { ICURDTableSchema, ICURDTableProps, ICURDTableState };
