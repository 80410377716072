import { IRouterConfigMap } from 'router/routing';
import loadable from '@loadable/component';
import SchemaCURDTable from 'components/Schema/CURDTable';

import goodsManagement from './goodsList';
import inventoryList from './inventoryList';
import orderList from './orderList';
import banner_list from './bannerList/index';
import category_list from './categoryList/index';
import recommend_list from './recommendList/index';
import lessonOrder_list from './lessonOrderList/index';
import commision_list from './commisionList/index';
// import fireEdit from './fireEdit/index';
// import creatFire from './creatFire/index';
import { bindLifecycle } from '@/router/lib/keepAlive';

export enum RouterNameType {
  STOREMANAGEMENT_GOODSLIST = '/storeManagement/goodsList',
  STOREMANAGEMENT_GOODSEDIT = '/storeManagement/goodsEdit',
  STOREMANAGEMENT_GOODSADD = '/storeManagement/goodsAdd',
  STOREMANAGEMENT_INVENTORYLIST = '/storeManagement/inventoryList',
  STOREMANAGEMENT_ORDERLIST = '/storeManagement/orderList',
  STOREMANAGEMENT_ORDERDETAIL = '/storeManagement/orderDetail',
  STOREMANAGEMENT_BANNERLIST = '/storeManagement/bannerList',
  STOREMANAGEMENT_CATEGORYLIST = '/storeManagement/categoryList',
  STOREMANAGEMENT_RECOMMENDLIST = '/storeManagement/recommendList',
  STOREMANAGEMENT_RECOMMENDEDIT = '/storeManagement/recommendEdit',
  STOREMANAGEMENT_RECOMMENDSADD = '/storeManagement/recommendAdd',
  STOREMANAGEMENT_LESSONORDER_LIST = '/storeManagement/lessonOrderList',
  STOREMANAGEMENT_COMMISION_LIST = '/storeManagement/commisionlist',
  STOREMANAGEMENT_COMMISIONRECORD_LIST = '/storeManagement/comrecordlist',
  STOREMANAGEMENT_WITHDRAWAL_LIST = '/storeManagement/withdrawallist',
  STOREMANAGEMENT_FIREEDIT = '/storeManagement/fireEdit',
  STOREMANAGEMENT_FIREEDITS = '/storeManagement/fireEdits',
  STOREMANAGEMENT_FIREADD = '/storeManagement/fireAdd',
  // STOREMANAGEMENT_CREATFIRE = '/storeManagement/creatFire',
}

export const StoreRoutingMap: IRouterConfigMap = {
  [RouterNameType.STOREMANAGEMENT_GOODSLIST]: {
    needAlive: true,
    schema: goodsManagement,
    component: bindLifecycle(SchemaCURDTable)
  },
  [RouterNameType.STOREMANAGEMENT_GOODSEDIT]: {
    component: loadable(() => import('./goodsDetail/index'))
  },
  [RouterNameType.STOREMANAGEMENT_GOODSADD]: {
    component: loadable(() => import('./goodsDetail/index'))
  },
  // [RouterNameType.STOREMANAGEMENT_CREATFIRE]: {
  //   component: loadable(() => import('./creatFire/index'))
  // },
  [RouterNameType.STOREMANAGEMENT_FIREEDITS]: {
    component: loadable(() => import('./creatFire/index'))
  },
  [RouterNameType.STOREMANAGEMENT_FIREADD]: {
    component: loadable(() => import('./creatFire/index'))
  },
  [RouterNameType.STOREMANAGEMENT_INVENTORYLIST]: {
    needAlive: true,
    schema: inventoryList,
    component: bindLifecycle(SchemaCURDTable)
  },
  [RouterNameType.STOREMANAGEMENT_ORDERLIST]: {
    needAlive: true,
    schema: orderList,
    component: bindLifecycle(SchemaCURDTable)
  },
  [RouterNameType.STOREMANAGEMENT_ORDERDETAIL]: {
    component: loadable(() => import('./orderDetail/index'))
  },
  [RouterNameType.STOREMANAGEMENT_BANNERLIST]: {
    schema: banner_list,
    component: SchemaCURDTable
  },
  [RouterNameType.STOREMANAGEMENT_CATEGORYLIST]: {
    schema: category_list,
    component: SchemaCURDTable
  },
  [RouterNameType.STOREMANAGEMENT_RECOMMENDLIST]: {
    schema: recommend_list,
    component: SchemaCURDTable
  },
  [RouterNameType.STOREMANAGEMENT_RECOMMENDEDIT]: {
    component: loadable(() => import('./recommendDetail/index'))
  },
  [RouterNameType.STOREMANAGEMENT_RECOMMENDSADD]: {
    component: loadable(() => import('./recommendDetail/index'))
  },
  [RouterNameType.STOREMANAGEMENT_LESSONORDER_LIST]: {
    schema: lessonOrder_list,
    component: SchemaCURDTable
  },
  [RouterNameType.STOREMANAGEMENT_COMMISION_LIST]: {
    schema: commision_list,
    component: SchemaCURDTable
  },
  [RouterNameType.STOREMANAGEMENT_COMMISIONRECORD_LIST]: {
    component: loadable(() => import('./comrecordlist/index'))
  },
  [RouterNameType.STOREMANAGEMENT_WITHDRAWAL_LIST]: {
    component: loadable(() => import('./withdrawalList/index'))
  },
  [RouterNameType.STOREMANAGEMENT_FIREEDIT]: {
    component: loadable(() => import('./fireEdit/index'))
  }
};
