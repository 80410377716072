type OmitPaginate<S> = Omit<S, 'paginate'>;

type FetchResp<S> = {
  page: number;
  total: number;
  hasMore: boolean;
  loading: boolean;
  limit: number;
  resp: OmitPaginate<S> | undefined;
};

class FetchList<
  R extends { list_option?: core.ListOption },
  S extends { paginate?: core.Paginate },
  O = any
> {
  /**
   * 请求函数
   * @param r  请求参数
   * @param [o] 额外设置
   * @return Promise<S>
   */
  private fetchFn: (r: R, o?: O) => Promise<S> = (r: R) => {
    console.log(r);
    return Promise.reject('pleast register fetchFn');
  };

  /**
   * 通知当前列表状态
   * @param result 结果
   */
  private toViewFn?: (result: FetchResp<S>) => void;

  limit = 10;
  req?: R;
  page = 1;
  total = 0;
  hasMore = true;
  loading = false;
  protected opt?: O;
  protected resp: OmitPaginate<S> | undefined = undefined;

  constructor(
    fn?: (r: R, o?: O) => Promise<S>,
    req: R = {} as R,
    toViewFn?: (result: FetchResp<S>) => void,
    opt?: O,
    limit = 10
  ) {
    this.fetchFn = fn!;
    this.limit = limit;
    this.req = req;
    this.toViewFn = toViewFn;
    this.opt = opt;
  }

  setFetchListFn(fn: (r: R, o?: O) => Promise<S>) {
    this.fetchFn = fn;
  }

  setToViewFn(fn: (result: FetchResp<S>) => void) {
    this.toViewFn = fn;
  }

  setReq<Req>(req: Req) {
    this.req = {
      ...this.req,
      ...(req as any)
    };
  }

  private emitState() {
    const { page, total, hasMore, loading, resp, limit } = this;
    const result = {
      page,
      total,
      hasMore,
      loading,
      resp,
      limit
    };
    this.toViewFn && this.toViewFn(result);
  }

  /**
   * 删除 list_options options
   * @param {number} type
   * @returns {R} this.req
   * @memberof FetchList
   */
  deleteOption(type: number) {
    const { options = [] } = this.req!.list_option! || {};
    this.req!.list_option = {
      ...this.req!.list_option,
      options: options.filter(v => v.type !== type)
    };
    return this.req!;
  }

  /**
   * 增加 list_options options
   * @param {number} type
   * @returns {R} this.req
   * @memberof FetchList
   */
  appendOption(option: core.ListOption_Option) {
    const { options = [] } = this.req!.list_option! || {};
    if (option.value!) {
      this.req!.list_option = {
        ...this.req!.list_option,
        options: options.concat(option)
      };
    }
    return this.req;
  }

  /**
   * 替换 list_options options, 不存在则删除
   * @param {number} type
   * @returns {R} this.req
   * @memberof FetchList
   */
  replaceOption(option: core.ListOption_Option) {
    this.deleteOption(option.type!);
    this.appendOption(option);
    return this.req;
  }

  /**
   * 转换list_option成对象
   * @returns { [type: number]: value }
   * @memberof FetchList
   */
  optionsToMap() {
    const { options = [] } = this.req!.list_option! || {};
    return options.reduce((acc, item) => {
      acc[item.type!] = item.value!;
      return acc;
    }, {});
  }

  /**
   * 通过object替换并且添加option
   * @param {({
   *     [key: string]: string | number | Array<string | number>;
   *   })} map
   * @returns {R} this.req
   * @memberof FetchList
   */
  mapToOptions(map: { [key: string]: string | number | (string | number)[] }) {
    Object.keys(map).forEach(k => {
      this.replaceOption({
        type: +k,
        value: map[k] ? String(map[k]) : ''
      });
    });
    return this.req;
  }

  /**
   * 使用map 重置表单查询参数
   * @param {({
   *     [key: string]: string | number | (string | number)[];
   *   })} [map]
   * @memberof FetchList
   */
  resetOptionsMap(map?: {
    [key: string]: string | number | (string | number)[];
  }) {
    try {
      this.req!.list_option!.options = [];
    } catch (error) {}
    if (map) {
      this.mapToOptions(map);
    }
  }

  private processReq(req: R = this.req!, page: number = this.page): R {
    if (page < 1) {
      page = 1;
    }
    this.page = page;
    this.req = req;
    const { list_option, ...opt } = this.req;
    return {
      ...opt,
      list_option: {
        ...list_option,
        limit: this.limit,
        offset: (page - 1) * this.limit
      }
    } as R;
  }

  private processResp(resp: S): S {
    const { paginate, ...result } = resp;
    if (this.page === 1 && paginate) {
      this.total = paginate!.total!;
    }
    this.hasMore =
      this.total > (this.page - 1) * this.limit && this.limit <= this.total;
    this.resp = result!;
    return resp;
  }

  /**
   * 请求列表
   * @param [req]
   * @param [page]
   * @param [o]
   * @returns
   */
  async getList(req?: R, page?: number, opt?: O) {
    if (this.loading) {
      return Promise.reject('GetList is loading, please await getList success');
    }
    if (!this.hasMore) {
      return Promise.reject('No more data');
    }
    if (opt) {
      this.opt = opt;
    }
    this.loading = true;
    const r = this.processReq(req, page);
    this.emitState();
    try {
      const resp = await this.fetchFn(r, this.opt);
      return this.processResp(resp);
    } catch (error) {
    } finally {
      this.loading = false;
      this.emitState();
      delete this.resp;
    }
  }

  /**
   * 刷新列表，如果不传page 会自动跳回第一页
   * @param [req] 请求参数
   * @param [page] 页数
   * @param [o] 额外设置
   * @returns
   */
  refreshList(req?: R, page?: number, o?: O) {
    if (!page) {
      page = 1;
    }
    this.hasMore = true;
    return this.getList(req, page, o);
  }

  /**
   * 刷新当前页面列表
   * @param [req] 请求参数
   * @param [o] 额外设置
   * @returns
   */
  refreshCurPageList(req?: R, o?: O) {
    this.hasMore = true;
    return this.getList(req, this.page, o);
  }

  /**
   * 改变列表limit长度
   * @param limit
   * @param [req]
   * @param [o]
   * @returns
   */
  changeLimitList(limit: number, req?: R, o?: O) {
    return this.changePageLimeList(this.page, limit, req, o);
  }

  /**
   * 改变页数
   * @param {number} page
   * @param {R} [req]
   * @param {O} [o]
   * @returns
   * @memberof FetchList
   */
  changePageList(page: number, req?: R, o?: O) {
    return this.changePageLimeList(page, this.limit, req, o);
  }

  /**
   * 使用 page pageSize 更新列表
   * @param {number} page
   * @param {number} limit
   * @param {R} [req]
   * @param {O} [o]
   * @returns
   * @memberof FetchList
   */
  changePageLimeList(page: number, limit: number, req?: R, o?: O) {
    if (limit <= 0) {
      limit = 10;
      console.warn('limit must > 0');
    }
    if (page < 1) {
      page = 1;
      console.warn('page must > 1');
    }
    this.hasMore = true;
    this.page = page;
    this.limit = limit;
    return this.getList(req, this.page, o);
  }

  /**
   * 下一页
   * @param [req]
   * @param [o]
   * @returns
   */
  nextList(req?: R, o?: O) {
    return this.getList(req, ++this.page, o);
  }

  /**
   * 上一页
   * @param [req]
   * @param [o]
   * @returns
   */
  prevList(req?: R, o?: O) {
    return this.getList(req, --this.page, o);
  }
}

export { FetchList, FetchResp };
