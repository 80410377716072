import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import { IRoutercomponentProps } from '@/router/lib/routers';
import {
  BindEventHandlerThis,
  SchemaProps,
  SchemaState,
  SchemaComponent
} from '../Schema';

import { getBtnGroup, IBtnGroupProps } from '../CURDTable/btnGroup';
import { ISelectOption } from './components';

import {
  Form as AntForm,
  Row,
  Col,
  Input,
  InputNumber,
  DatePicker,
  TimePicker,
  Radio,
  Checkbox,
  TreeSelect,
  Cascader,
  Select
} from 'antd';
import {
  FormProps,
  FormComponentProps,
  FormItemProps
} from 'antd/lib/form/index.d';

import { GetFieldDecoratorOptions } from 'antd/lib/form/Form.d';
import { InputProps, TextAreaProps } from 'antd/lib/input/index.d';
import { InputNumberProps } from 'antd/lib/input-number/index.d';
import {
  RangePickerProps,
  DatePickerProps
} from 'antd/lib/date-picker/interface.d';
import { TimePickerProps } from 'antd/lib/time-picker/index.d';
import { RadioGroupProps, RadioProps } from 'antd/lib/radio/index.d';
import { SelectProps } from 'antd/lib/select/index.d';
import { CheckboxProps } from 'antd/lib/checkbox/index.d';
import { CascaderProps } from 'antd/lib/cascader/index.d';
import { RowProps, ColProps } from 'antd/lib/grid/index.d';
import {
  TreeSelectProps,
  TreeNodeNormal
} from 'antd/lib/tree-select/interface.d';

import RichTextEditor from '@/components/RichTextEditor';
import Uploader, { IUploaderProps } from '@/components/NewUploader';
import UploaderWithList, {
  IUploaderWithListProps
} from '@/components/UploaderWithList';

import style from './index.module.scss';

type IObservableData = {};

type IComponentProps =
  | InputProps
  | InputNumberProps
  | RangePickerProps
  | RadioGroupProps
  | DatePickerProps
  | TimePickerProps
  | SelectProps
  | IUploaderProps
  | IUploaderWithListProps
  | CheckboxProps
  | TreeSelectProps<any>
  | CascaderProps;

type IRowItem<F> = React.PropsWithChildren<FormItemProps> & {
  propName?: keyof F;
  FieldDecoratorOptions?: GetFieldDecoratorOptions;
  type?:
    | 'input'
    | 'input-password'
    | 'text-area'
    | 'number'
    | 'date-pick'
    | 'date-range-picker'
    | 'radio'
    | 'radio-button'
    | 'select'
    | 'time-range-pick'
    | 'rich-text'
    | 'uploader'
    | 'uploader-with-list'
    | 'check-box'
    | 'tree-select'
    | 'cascader'
    | 'time-picker';
  rangePickerPropNames?: [keyof F, keyof F];
  component?: React.ReactNode;
  placeholder?: string;
  defaultValue?: any;
  formatDate?: any;
  componentProps?: IComponentProps;
  radioGroup?: RadioProps[];
  /**
   * 下拉框数据 当 `type`: select
   * @type {any[]}
   */
  selectOptions?: any[];
  selectOption?: ISelectOption;
  /*
   * 解决需要异步加载下拉选项
   * @returns {Promise<any[]>}
   */
  loadData?(): Promise<any[]>;
  treeSelectNodes?: any[];
  treeNodes?: any[];
  treeNodeOption?: {
    titleKey: string;
    valueKey: string;
    rowKey: string;
    childrenKey?: string;
  };
  colProps?: ColProps;
  needObservable?: boolean;
  formItemChildren?: IRowItem<F>[];
  dynamicSetFormItemProps?(): FormItemProps;
  dynamicSetComponentProps?(): IComponentProps;
  renderBtnGroup?(this: Form): IBtnGroupProps;
};

type IFormSchema<
  F = any,
  ObservableData = IObservableData
> = React.PropsWithChildren<FormProps> & {
  rows: IRowItem<F>[][];
  methods?: React.ComponentLifecycle<any, any> & {
    [key: string]: (this: Form, ...params: any) => any;
  };
  rowProps?: RowProps;
  observableData?: ObservableData;
};

type ISchemaFormProps = SchemaProps<IFormSchema> &
  FormComponentProps &
  Partial<IRoutercomponentProps<any, IFormSchema>> & {
    parentInstance?: any;
  };
type ISchemaFormState = SchemaState<IFormSchema>;

@observer
class Form extends SchemaComponent<
  IFormSchema,
  ISchemaFormProps,
  ISchemaFormState
> {
  methods: {
    [fn: string]: (...params: any) => any;
  } = {};

  @observable.deep observableData: any;

  @observable.deep formItemData: {
    [propName: string]: any;
  } = {};

  @action
  updateFormItemData(propsName: string | number, val: any) {
    Object.assign(this.formItemData[propsName], val);
  }

  constructor(props: ISchemaFormProps) {
    super(props);

    const { schema } = props;
    const { methods, observableData, rows } = schema;

    BindEventHandlerThis.call(this, schema);

    this.observableData = observableData;

    // apply row item observable
    rows.forEach(row => {
      row.forEach(v => {
        if (v.propName && v.needObservable) {
          this.formItemData[String(v.propName)] = v;
        }
      });
    });

    if (methods) {
      const { ...funs } = methods!;

      // mounted methods in this class and this.methods
      for (const fn in funs!) {
        if (!this.hasOwnProperty(fn)) {
          this[fn] = action(fn, funs[fn].bind(this));
        }
        this.methods[fn] = action(fn, funs[fn].bind(this));
      }
    }
  }

  /**
   * 根据表单key 存自定义数据  val 参数为 props
   * @param {(string | number)} propName
   * @param {*} val
   * @memberof Form
   */
  @action
  setFormItemData(propName: string | number, val: any) {
    this.formItemData[propName] = val;
  }

  getFormItemData = (propName: string | number, defaultVal?: any) => {
    return this.formItemData[propName] || defaultVal;
  };

  preventDefaultWrap(
    action: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined
  ) {
    return action
      ? function WrappedAction(e: React.FormEvent<HTMLFormElement>) {
          e.preventDefault();
          return action(e);
        }
      : undefined;
  }

  render() {
    const { props } = this;
    const { form, schema } = props;

    const {
      rows = [],
      rowProps,
      observableData,
      onSubmit,
      ...formProps
    } = toJS(schema);
    const { getFieldDecorator } = form;

    const renderRow = (rows: IRowItem<any>[], key: string | number) => {
      return (
        <Row key={key} {...rowProps}>
          {rows.map((item, index) => {
            let row = item;
            if (
              item.needObservable &&
              item.propName &&
              this.formItemData[String(item.propName)]
            ) {
              row = toJS(this.formItemData[String(item.propName)]);
            }
            const {
              colProps,
              children,
              propName,
              FieldDecoratorOptions,
              type,
              placeholder,
              defaultValue,
              formatDate,
              component,
              componentProps,
              radioGroup,
              selectOptions,
              selectOption,
              loadData,
              treeSelectNodes,
              treeNodeOption,
              dynamicSetFormItemProps,
              dynamicSetComponentProps,
              renderBtnGroup,
              treeNodes,
              formItemChildren,
              ...formItemProps
            } = row;

            if (dynamicSetFormItemProps) {
              Object.assign(formItemProps, dynamicSetFormItemProps.call(this));
            }

            if (dynamicSetComponentProps) {
              Object.assign(
                componentProps,
                dynamicSetComponentProps.call(this)
              );
            }

            if (type === 'check-box') {
              Object.assign(FieldDecoratorOptions, {
                valuePropName: 'checked'
              });
            }

            BindEventHandlerThis.call(this, componentProps || {}, 'loadData');

            if (formItemChildren) {
              return (
                <Col key={index} {...colProps}>
                  <AntForm.Item {...formItemProps}>
                    {renderRow(formItemChildren, index)}
                  </AntForm.Item>
                </Col>
              );
            }

            if (!propName) {
              if (renderBtnGroup) {
                const btnGroup = renderBtnGroup!.apply(this);
                const BtnGroup = getBtnGroup(btnGroup!).bind(this);
                return (
                  <Col key={index} {...colProps}>
                    <AntForm.Item {...formItemProps}>
                      <BtnGroup />
                    </AntForm.Item>
                  </Col>
                );
              }
              return (
                <Col key={index} {...colProps}>
                  <AntForm.Item {...formItemProps}>{children}</AntForm.Item>
                </Col>
              );
            }

            const getContainer = (triggerNode: HTMLElement & any) =>
              triggerNode;

            return (
              <Col key={index} {...colProps}>
                <AntForm.Item {...formItemProps}>
                  {getFieldDecorator(
                    String(propName),
                    FieldDecoratorOptions
                  )(
                    (() => {
                      if (type) {
                        switch (type) {
                          case 'input':
                            return (
                              <Input
                                {...(componentProps as InputProps)}
                                placeholder={placeholder}
                              />
                            );
                          case 'input-password': {
                            return (
                              <Input.Password
                                {...(componentProps as InputProps)}
                                placeholder={placeholder}
                              />
                            );
                          }
                          case 'date-pick':
                            return (
                              <DatePicker
                                getCalendarContainer={getContainer}
                                {...(componentProps as DatePickerProps)}
                              />
                            );
                          case 'time-picker':
                            return (
                              <TimePicker
                                {...(componentProps as TimePicker)}
                                placeholder={placeholder}
                              />
                            );
                          case 'text-area':
                            return (
                              <Input.TextArea
                                {...(componentProps as TextAreaProps)}
                                placeholder={placeholder}
                              />
                            );
                          case 'number':
                            return (
                              <InputNumber
                                style={{ width: '100%' }}
                                {...(componentProps as InputNumberProps)}
                                placeholder={placeholder}
                              />
                            );
                          case 'date-range-picker':
                            return (
                              <DatePicker.RangePicker
                                {...(componentProps as RangePickerProps)}
                                getCalendarContainer={getContainer}
                              />
                            );
                          case 'time-range-pick':
                            return (
                              <TimePicker
                                getPopupContainer={getContainer}
                                {...(componentProps as TimePickerProps)}
                              />
                            );
                          case 'radio':
                            return (
                              <Radio.Group
                                {...(componentProps as RadioGroupProps)}
                              >
                                {radioGroup!.map((radio, key) => (
                                  <Radio {...radio} key={key} />
                                ))}
                              </Radio.Group>
                            );
                          case 'radio-button':
                            return (
                              <Radio.Group
                                {...(componentProps as RadioGroupProps)}
                              >
                                {radioGroup!.map((radio, key) => (
                                  <Radio.Button {...radio} key={key} />
                                ))}
                              </Radio.Group>
                            );
                          case 'select':
                            // 规避为有自定义筛选 屏蔽 select 的 filterOption
                            if (
                              componentProps &&
                              (componentProps as SelectProps).onSearch!
                            ) {
                              (componentProps as SelectProps).filterOption = false;
                            }
                            return (
                              <Select
                                getPopupContainer={getContainer}
                                {...(componentProps as SelectProps)}
                                placeholder={placeholder}
                              >
                                {selectOptions!.map((option, key) => (
                                  <Select.Option
                                    value={option[selectOption!.valueKey]}
                                    children={option[selectOption!.labelKey]}
                                    key={key}
                                  />
                                ))}
                              </Select>
                            );
                          case 'rich-text':
                            return <RichTextEditor placeholder={placeholder} />;
                          case 'uploader':
                            return (
                              <Uploader
                                {...(componentProps as IUploaderProps & {
                                  onlineVideo?: boolean | undefined;
                                })}
                              />
                            );
                          case 'uploader-with-list':
                            return (
                              <UploaderWithList
                                {...(componentProps as IUploaderWithListProps)}
                              />
                            );
                          case 'check-box':
                            return (
                              <Checkbox
                                {...(componentProps as CheckboxProps)}
                              />
                            );
                          case 'tree-select':
                            const renderSelectTreeNodes = (
                              nodes: any[] = []
                            ): any[] => {
                              return nodes.map(v => {
                                const {
                                  titleKey = 'label',
                                  valueKey = 'value',
                                  rowKey = 'id',
                                  childrenKey = 'children'
                                } = treeNodeOption!;
                                const nodeProps: TreeNodeNormal = {
                                  value: v[valueKey!],
                                  title: v[titleKey!],
                                  key: v[rowKey!],
                                  ...v
                                };
                                if (childrenKey && v[childrenKey!]) {
                                  nodeProps.children = renderSelectTreeNodes(
                                    v[childrenKey!]
                                  );
                                }
                                return <TreeSelect.TreeNode {...nodeProps} />;
                              });
                            };
                            return (
                              <TreeSelect
                                getPopupContainer={getContainer}
                                {...(componentProps as TreeSelectProps<any>)}
                                children={renderSelectTreeNodes(
                                  treeSelectNodes
                                )}
                              />
                            );
                          case 'cascader':
                            return (
                              <Cascader
                                getPopupContainer={getContainer}
                                {...(componentProps as CascaderProps)}
                              />
                            );
                          default:
                            break;
                        }
                      }

                      return component;
                    })()
                  )}
                </AntForm.Item>
              </Col>
            );
          })}
        </Row>
      );
    };

    return (
      <AntForm
        onSubmit={this.preventDefaultWrap(onSubmit)}
        {...formProps}
        className={style.form}
      >
        {rows.map(renderRow)}
      </AntForm>
    );
  }
}

export default AntForm.create<ISchemaFormProps>()(Form);

export { IFormSchema, ISchemaFormProps, ISchemaFormState, Form };
