import React from 'react';
import { Layout } from 'antd';
// import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import Menu from './menu';
import Header from './header';

import { IRoutercomponentProps } from '@/router/lib/routers';
import { StoreName, IAppConfigStoreProp, IUserStoreProp } from 'store/index';

type IBaiscLayoutProps = Partial<IAppConfigStoreProp & IUserStoreProp> &
  IRoutercomponentProps & {};

function BaiscLayout(props: IBaiscLayoutProps) {
  const { userStore, appStore, location } = props;

  const currentPath = location.pathname;

  return (
    <Layout>
      <Layout.Sider
        width={200}
        trigger={null}
        collapsed={appStore!.isMenuCollapsed}
        className="webkit-scrollbar--dark"
        style={{
          maxHeight: '100vh',
          overflowY: 'auto'
        }}
      >
        <Menu
          currentPath={currentPath}
          menuTree={userStore!.menuTree!}
          isMenuCollapsed={appStore!.isMenuCollapsed}
        />
      </Layout.Sider>
      <Layout
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh'
        }}
      >
        <Header {...{ userStore, appStore, location }} />
        <Layout.Content
          style={{
            margin: 10,
            // marginTop: 0,
            // paddingTop: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            className="webkit-scrollbar"
            id="layoutContent"
            style={{ flex: 1, overflow: 'auto' }}
          >
            {props.childView}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default inject((stores: IBaiscLayoutProps) => ({
  [StoreName.USERSTORE]: stores[StoreName.USERSTORE],
  [StoreName.APPCONFIGSTORE]: stores[StoreName.APPCONFIGSTORE]
}))(observer(BaiscLayout));
