import { IRouterConfigMap } from 'router/routing';
import loadable from '@loadable/component';

export const CustomermanagementRoutingMap: IRouterConfigMap = {
  '/customermanagement/customerlist': {
    needAlive: true,
    component: loadable(() => import('./CustomerList/index'))
  },
  '/customermanagement/customerDetail': {
    component: loadable(() => import('./CustomerDetailView/index'))
  },
  '/customermanagement/customerEdit': {
    component: loadable(() => import('./CustomerDetail/index'))
  },
  '/customermanagement/ApplicationList': {
    needAlive: true,
    component: loadable(() => import('./ApplicationList/index'))
  },
  '/customermanagement/BookingList': {
    needAlive: true,
    component: loadable(() => import('./BookingList/index'))
  }
};
