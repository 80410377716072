export enum Accountlogic_AuthType {
  AUTH_TYPE_WECHAT_SNS = 0,
  AUTH_TYPE_WECHAT_WXA = 1,
  AUTH_TYPE_PHONE = 2,
  AUTH_TYPE_EMAIL = 3,
  AUTH_TYPE_PHONE_PASSWORD = 4,
  AUTH_TYPE_ALIPAY_XCX = 5, // 支付宝小程序
  AUTH_TYPE_ROBOT = 100 // 接口测试
}

export enum Accountlogic_SetProfileReq_EncryptedDataType {
  ENCRYPTED_DATA_TYPE_USER_INFO = 0, // getUserInfo
  ENCRYPTED_DATA_TYPE_PHONE = 1 // getPhoneNumber
}

export enum Ext_ExtFlags {
  FLAGS_NIL = 0,
  FLAGS_ADMIN = 1,
  FLAGS_INTERNAL = 2
}

export enum Core_AppId {
  APP_ID_NIL = 0,
  APP_ID_BROADCAST = 1000000, // 传播助手
  APP_ID_KOLRANK = 1000001, // 人人打榜
  APP_ID_WECHAT_GROUP = 1000002, // 加群助手
  APP_ID_TAPMANIA = 1000003, // 疯狂手指2
  APP_ID_SBUYER = 9999999, // 返利商城
  APP_ID_TURNTABLE = 1000005, // 转盘抽奖
  APP_ID_MONOPOLY = 1000006, // 大富翁
  APP_ID_NEWYEARPAINTING = 79, // 年画
  APP_ID_GAME_TEST = 2000001, // 小游戏测试
  APP_ID_GAME_GOLDTRAIN = 2000002, // 小游戏欢乐火车
  APP_ID_GAME_BUMPER = 2000003, // 小游戏疯狂动物岛
  APP_ID_GAME_ONE_DRAW_TO_END = 2000004, // 小游戏萌犬一笔画
  APP_ID_GAME_TRAIN_WEIBO = 2000005, // 小游戏疯狂高铁
  APP_ID_GAME_BUMPER_WEIBO = 2000006, // 小游戏暴躁动物园
  APP_ID_TEST = 2000028, // 测试
  APP_ID_TVSNEWS = 2000175, // tvsnews
  APP_ID_GIALEN = 2000180, // 娇兰
  APP_ID_BORAVIS = 2000190, // 娇兰
  APP_ID_SOUCHE = 2000211, // 大搜车
  APP_ID_KEEPFIRE = 2000206, // 自燃人
  APP_ID_SHENSUO = 2000215, // 身所
  APP_ID_TAIKOOHUI = 2000219, // 太古汇
  APP_ID_SHENSUO_RELEASE = 2000295, // 身所正式
  APP_ID_ZHIYUE = 2000213, // 智悦
  APP_ID_ZNEST = 2000241, // 左巢
  APP_ID_TRADEADMIN = 2000242, //电商后台
  APP_ID_COTASK = 2000243 //cotask
}

export enum Core_CorpId {
  CORP_ID_NIL = 0,
  CORP_ID_BROADCAST = 1000000, // 传播助手
  CORP_ID_KOLRANK = 1000001, // 人人打榜
  CORP_ID_WECHAT_GROUP = 1000002, // 加群助手
  CORP_ID_TAPMANIA = 1000003, // 疯狂手指2
  CORP_ID_SBUYER = 9999999, // 返利商城
  CORP_ID_TURNTABLE = 1000005, // 转盘抽奖
  CORP_ID_MONOPOLY = 1000006, // 大富翁
  CORP_ID_NEWYEARPAINTING = 79, // 年画
  CORP_ID_GAME_TEST = 2000001, // 小游戏测试
  CORP_ID_GAME_GOLDTRAIN = 2000002, // 小游戏欢乐火车
  CORP_ID_GAME_BUMPER = 2000003, // 小游戏疯狂动物岛
  CORP_ID_GAME_ONE_DRAW_TO_END = 2000004, // 小游戏萌犬一笔画
  CORP_ID_GAME_TRAIN_WEIBO = 2000005, // 小游戏疯狂高铁
  CORP_ID_GAME_BUMPER_WEIBO = 2000006, // 小游戏暴躁动物园
  CORP_ID_TEST = 2000028, // 测试
  CORP_ID_TVSNEWS = 2000175, // tvsnews
  CORP_ID_GIALEN = 2000180, // 娇兰和柏蕊诗共用CORP_ID
  CORP_ID_SOUCHE = 2000211, // 大搜车
  CORP_ID_KEEPFIRE = 2000206, // 自燃人
  CORP_ID_SHENSUO = 2000215, // 身所
  CORP_ID_TAIKOOHUI = 2000219, // 太古汇
  CORP_ID_SHENSUO_RELEASE = 2000295, // 身所正式
  CORP_ID_ZHIYUE = 2000213, // 智悦
  CORP_ID_TRADEADMIN = 2000242, //电商后台
  CORE_ID_COTASK = 2000243, //cotask
  CORP_ID_ZNEST = 2000240 // 左巢
}

export enum Core_Gender {
  GENDER_MALE = 0,
  GENDER_FEMALE = 1
}

export enum Core_ModelType {
  MODEL_TYPE_NIL = 0,
  MODEL_TYPE_ACCOUNT = 1,
  MODEL_TYPE_PROFILE = 2,
  MODEL_TYPE_VISITOR = 3,
  MODEL_TYPE_TAG = 4,
  MODEL_TYPE_CONTENT = 5,
  MODEL_TYPE_ACTIVITY = 6,
  MODEL_TYPE_STATISTIC = 7,
  MODEL_TYPE_WALLET = 8,
  MODEL_TYPE_TRANSACTION = 9,
  MODEL_TYPE_SESSION = 10,
  MODEL_TYPE_MESSAGE = 11,
  MODEL_TYPE_TASK = 12,
  MODEL_TYPE_WECHAT_PAYMENT = 13,
  MODEL_TYPE_CATALOG = 14,
  MODEL_TYPE_WECHAT_TRANSFER = 15,
  MODEL_TYPE_WECHAT_REFUND = 16,
  MODEL_TYPE_GAME_MAIL = 17,
  MODEL_TYPE_TICKET = 18,
  MODEL_TYPE_GROUP_BUY_DEF = 19,
  MODEL_TYPE_GROUP_BUY_INSTANCE = 20,
  MODEL_TYPE_GROUP_BUY_BUY = 21,
  MODEL_TYPE_SHARE = 22,
  MODEL_TYPE_RED_PACKET = 23,
  MODEL_TYPE_RED_PACKET_RECEIVE = 24,
  MODEL_TYPE_ORDER = 25,
  MODEL_TYPE_ROBOT_ACCOUNT = 26,
  // 不需要分配，但要定义占位
  MODEL_TYPE_GAME_ITEM = 10000
}

export enum Core_ModelStatus {
  MODEL_STATUS_NIL = 0,
  MODEL_STATUS_DEFAULT = 1, // 2^0 系统自动生成的默认数据
  MODEL_STATUS_DELETED = 2, // 2^1 删除标记
  MODEL_STATUS_BLOCKED = 4, // 2^2 系统封禁
  MODEL_STATUS_RECOMMENDED = 8, // 2^3 推荐
  MODEL_STATUS_CONFIRMED = 16 // 2^4 已确认
}

export enum Core_ServerConfig_AccessType {
  SERVER_ACCESS_NIL = 0,
  SERVER_ACCESS_PUBLIC = 1, // 公开服务，不验证会话
  SERVER_ACCESS_PRIVATE = 2, // 私有服务，要验证会话
  SERVER_ACCESS_INTERNAL = 3 // 内部服务，不验证会话
}

export enum Core_PushType {
  PushTypeNil = 0,
  PushTypeGialenTurntable0517 = 1000, // 娇兰 0517 转盘活动 -- gialencomm.TurntablePushMessage
  PushTypeZhuoJianActivityInteractive = 1001, // 拙见活动互动 -- zhuojian.ActivityInteractivePushMessage
  PushTypeChat = 1002, // 聊天 push
  PushTypeQollWebMiniProgramConn = 1003, // 快问卷 小程序跟web端通讯链接
  PushTypeRoom = 1004, // 聊天室
  PushTypeTermDebug = 1005, // 前端调试日志
  PushTypeApiCall = 1006 // 服务端接口调用
}

export enum Account_App_Feature {
  APP_FEATURE_NIL = 0,
  APP_FEATURE_BROADCAST_REWARD = 1, // 传播-赏金功能启用
  APP_FEATURE_KOLRANK_JOIN_GROUP = 1, // 打榜-加群
  APP_FEATURE_WBGAMEBOX_DISPLAY = 1, // 微播游戏盒子-显示为游戏盒子
  APP_FEATURE_WBGAMEBOX_QR_SCANN = 2, // 微播游戏盒子-二维码扫描显示为游戏盒子
  APP_FEATURE_WBGAMEBOX_SPECIFIED_VERSION_DISPLAY = 4, // 微播游戏盒子-指定版本显示为游戏盒子
  APP_FEATURE_DEFAULT_POINT_WALLET = 536870912, // 2^29 是否自动添加默认积分钱包
  APP_FEATURE_DEFAULT_MONEY_WALLET = 1073741824 // 2^30 是否自动添加默认现金钱包
}

export enum Account_App_ProductActivity {
  APP_PRODUCT_ACTIVITY_GROUP_NIL = 0,
  APP_PRODUCT_ACTIVITY_GROUP_BUY = 1, // 是否开启团购
  APP_PRODUCT_ACTIVITY_FLASH_SALE = 2, // 是否开启限时购
  APP_PRODUCT_ACTIVITY_GROUP_BUY_AUTO_SUCCESS = 4, // 自动成团
  APP_PRODUCT_ACTIVITY_GROUP_BUY_EXTER_ACTIVITY_STOCK = 8, // 团购使用外部活动库存
  APP_PRODUCT_ACTIVITY_FLASH_SALE_EXTER_ACTIVITY_STOCK = 16 // 限时购使用外部活动库存
}

export enum Account_Account_Status {
  ACCOUNT_STATUS_NIL = 0,
  ACCOUNT_STATUS_MASTER = 65536, // 管理员帐号，全功能开启
  ACCOUNT_STATUS_ROBOT = 131072, // 机器人账号
  ACCOUNT_STATUS_MASTER_DISABLE = 262144, // 禁用管理员
  ACCOUNT_STATUS_CORP_CREATOR = 524288, // 企业创建者
  ACCOUNT_STATUS_DISABLE = 1048576 // 禁用账号
}

export enum Account_Account_UserStatus {
  Account_User_Status_Nil = 0,
  Account_User_Status_Valid = 1,
  Account_User_Status_Invalid = 2
}

export enum Account_Profile_Status {
  PROFILE_STATUS_NIL = 0,
  PROFILE_STATUS_CUSTOM_NAME = 65536, // 自定义名字
  PROFILE_STATUS_CUSTOM_AVATAR = 131072 // 自定义头像
}

export enum Account_AccountListOption {
  ACCOUNT_LIST_OPTION_STATUS = 0,
  ACCOUNT_LIST_OPTION_IDS = 1,
  ACCOUNT_LIST_OPTION_CORP_ID = 2, // 被莫名的放到了listPinAccount当中实现了
  ACCOUNT_LIST_OPTION_WECHAT_UNION_IDS = 3, //根据UNION_ID批量查询信息
  ACCOUNT_LIST_OPTION_WECHAT_NAME = 4, // 微信名
  ACCOUNT_LIST_OPTION_PHONE = 5, // 手机号
  ACCOUNT_LIST_OPTION_PHONE_OR_WECHAT_NAME = 6
}

export enum Account_GetProfileWithoutProfileIdReq_Term {
  TermNil = 0,
  TermWx = 1,
  TermAlipay = 2
}

export enum Account_ProfileListOption {
  PROFILE_LIST_OPTION_STATUS = 0,
  PROFILE_LIST_OPTION_IDS = 1,
  PROFILE_LIST_OPTION_ORDER_CREATED_AT = 2, // 0正序、1倒叙
  PROFILE_LIST_OPTION_NAME = 3,
  PROFILE_LIST_OPTION_Wechat_Phone = 4,
  PROFILE_LIST_OPTION_NOT_IDS = 5,
  PROFILE_LIST_OPTION_ORDER_BY_IDS = 6,
  PROFILE_LIST_OPTION_COUNT_ONLY = 7,
  PROFILE_LIST_OPTION_NEED_COUNT = 8,
  PROFILE_LIST_OPTION_NEED_WECHAT_PHONE_OR_NAME = 9,
  PROFILE_LIST_OPTION_PHONE_OR_NAME = 10
}

export enum Account_AppListOption {
  APP_LIST_OPTION_IDS = 0
}

export enum Account_PasswordHashType {
  PasswordHashTypeUndefined = 0,
  PasswordHashTypeSha256 = 1, // 小黑屋的hash方法
  PasswordHashTypeMd5AndBCryptHash = 2 // saas的hash方法
}

export enum Account_PinAccountState {
  PinAccountStateNormal = 0, // 正常状态
  PinAccountStateInactivated = 1 // 未激活
}

export enum Account_PinAccountStatus {
  PinAccountStatusNil = 0,
  PinAccountStatusAdmin = 1, // 管理员帐号(可以理解成creator下一级的管理员) <-- 未巢用的是这个
  PinAccountStatusMaster = 65536, // 管理员帐号(不建议用这个，master这个词用得不对，建议用 admin)
  PinAccountStatusDisable = 262144, // 禁用
  PinAccountStatusCreator = 524288 // 企业创建者
}

export enum Account_PinAccountListOption {
  PinAccountListOptionNil = 0,
  // @type: uint32
  PinAccountListOptionStatus = 1,
  // @type: uint32
  PinAccountListOptionState = 2,
  // @type: uint32
  PinAccountListOptionCorpId = 3,
  // @type: string
  PinAccountListOptionMail = 4,
  // @type: uint64List
  PinAccountListOptionIds = 5,
  // @type: uint32
  PinAccountListOptionAppId = 6,
  // @type: uint32
  PinAccountListOptionOrderCreate = 7,
  // @type: string
  PinAccountListOptionNameSearch = 8,
  // @type: string
  PinAccountListOptionPhoneSearch = 9,
  // @type: string
  PinAccountListOptionEmailSearch = 10,
  // @type: uint32List
  PinAccountListOptionStatusList = 11,
  // @type: string
  PinAccountListOptionPhone = 12,
  // @type: stringList
  PinAccountListOptionPhoneList = 13
}

export enum Wechat_WxAuthType {
  WxAuthTypeNil = 0,
  WxAuthTypeAuth = 1, // 已授权
  WxAuthTypeNonAuth = 2 // 未授权
}

export enum Wechat_WxAppType {
  WxAppTypeNil = 0,
  WxAppTypeMp = 1, // 小程序
  WxAppTypeOfficial = 2, // 公众号
  WxAppTypeComponent = 3 // 第三方平台
}

export enum Wechat_CustomMessageResponder_Rule_Status {
  CUSTOM_MESSAGE_RESPONDER_RULE_STATUS_NIL = 0,
  CUSTOM_MESSAGE_RESPONDER_RULE_STATUS_DEFAULT = 1, // 默认
  CUSTOM_MESSAGE_RESPONDER_RULE_STATUS_REGEX = 2 // 正则匹配
}

export enum Wechat_SendWxaTemplateReq_TemplateId {
  TEMPLATE_ID_NIL = 0,
  TEMPLATE_ID_BROADCAST_REWARD_NOTIFICATION_TIME = 1, // 传播-快得奖通知-时间
  TEMPLATE_ID_BROADCAST_REWARD_NOTIFICATION_STATISTIC = 2, // 传播-快得奖通知-阅读量
  TEMPLATE_ID_BROADCAST_SEND_REWARD = 3, // 传播-得奖通知
  TEMPLATE_ID_BROADCAST_VISITOR_CHAT = 4, // 传播-私信通知
  TEMPLATE_ID_BROADCAST_REWARD_FINISH = 5, // 传播-发奖通知
  TEMPLATE_ID_BROADCAST_MISS_PROFILE = 6, // 传播-填写信息通知
  TEMPLATE_ID_BROADCAST_NEW_VISITOR_NOTIFICATION = 7, // 传播-新访客提醒
  TEMPLATE_ID_BROADCAST_CREATOR_CHAT = 8, // 传播-私信通知
  TEMPLATE_ID_KOLRANK_VOTE_AFTER_ONE_DAY = 1, // 打榜-投票24小时后提醒
  TEMPLATE_ID_TAPMANIA_REWARD = 1, // 10秒-得奖通知
  TEMPLATE_ID_TAPMANIA_NOREWARD = 2, // 10秒-未得奖通知
  TEMPLATE_ID_SBUYER_NOTIFICATION = 1, // 返利-返利福利领取通知
  TEMPLATE_ID_NEWYEARPAINTING_NOTIFICATION = 1 // 年画-锦鲤活动中奖通知
}

export enum Wechat_GetAuthUrlReq_AuthAppType {
  AuthAppTypeNil = 0, // UNDEFINED
  AuthAppTypeOfficial = 1, // 仅公众号
  AuthAppTypeMp = 2, // 仅小程序
  AuthAppTypeBoth = 3 // 公众号和小程序
}

export enum Wechat_AuthUrl_AuthUrlType {
  AuthUrlTypeNil = 0, // UNDEFINED
  AuthUrlTypeUrl = 1, // 链接
  AuthUrlTypeQrCode = 2 // 二维码
}

export enum Wechat_AuditStatus {
  AuditStatusSuccess = 0, //审核成功
  AuditStatusRefuse = 1, //审核被拒绝
  AuditStatusProcess = 2, //审核中
  AuditStatusCancel = 3, //已撤回
  AuditStatusDelay = 4, //审核延后
  AuditStatusOnline = 5 //已上线
}

export enum Wechat_GetWxAppConfigListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 根据AppId筛选
  // @type: uint32List
  ListOptionAppIds = 1,
  // @desc: 按 wx_app_id 拉
  // @type: string
  ListOptionWxAppId = 2
}

export enum Orm_StmtType {
  CREATE_TABLE = 0,
  INSERT = 1,
  SELECT = 2,
  UPDATE = 3,
  DELETE = 4,
  REPLACE = 5,
  COUNT = 6
}

export enum Orm_OperatorType {
  // comparison
  EQ = 0,
  NE = 1,
  LT = 2,
  LE = 3,
  GT = 4,
  GE = 5,
  // like
  LIKE = 100,
  // bit
  BIT_AND = 200,
  BIT_NOT = 201
}

export enum Orm_LogicType {
  AND = 0,
  OR = 1
}

export enum Orm_OrderType {
  ASC = 0,
  DESC = 1
}

export enum Schedulerv2_Task_State {
  TASK_STATE_NIL = 0,
  TASK_STATE_INIT = 1, // 任务开启
  TASK_STATE_COMPLETED = 2 // 任务已完成
}

export enum Schedulerv2_Task_Type {
  TASK_TYPE_NIL = 0,
  TASK_TYPE_TIME = 100 // 指定时间
}

export enum Storage_TranscodeType {
  TranscodeTypeNil = 0,
  TranscodeTypeTvsNews = 1, // TvsNews转码
  TranscodeTypeTvsNewsReportNews = 2 // TvsNews爆料转码
}

export enum Storage_ModelConfig_StorageType {
  StorageTypeNil = 0,
  StorageTypeQiniu = 1, // 七牛
  StorageTypeTCCloud = 2, // 腾讯云
  StorageTypeAliyun = 3 // 阿里云
}

export enum Storage_ModelConfig_BucketAcl {
  BucketAclNil = 0,
  BucketAclPublic = 1, // 公共读写，支持：腾讯云、阿里云
  BucketAclPublicRead = 2, // 公共读，支持：七牛、腾讯云、阿里云
  BucketAclPrivate = 3 // 私有读写，支持：七牛、腾讯云、阿里云
}

export enum Storage_ModelResource_State {
  StateNil = 0,
  StatePending = 1, // 上传中
  StateSuccess = 2, // 上传成功
  StateFail = 3 // 上传失败
}

export enum Storage_ModelResourceTask_State {
  StateNil = 0,
  StatePending = 1, // 执行中
  StateSuccess = 2, // 执行成功
  StateFail = 3 // 执行失败
}

export enum Storage_ModelResourceTask_TaskType {
  TaskTypeNil = 0,
  TaskTypeVideoTranscode = 1, // 视频转码，腾讯云全支持，七牛支持但配置一样
  TaskTypeVideoSnapshot = 2 // 视频截图，腾讯云+七牛全支持
}

export enum Storage_ModelResourceTask_TaskSubType {
  TaskSubTypeNil = 0,
  VideoTranscodeTypeFluHls = 1, // 流畅.hls
  VideoTranscodeTypeSdHls = 2, // 标清.hls
  VideoTranscodeTypeHdHls = 3, // 高清.hls
  VideoSnapshotTypeDefault = 100 // 默认，截图的 width height 跟视频一致，jpg
}

export enum Storage_GetResourceListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionType = 1 // 根据资源类型获取数据
}

export enum Inneradmin_ModelClusterCmdMsg_State {
  StateNil = 0,
  StateProcessing = 1, // 处理中
  StateSucceed = 2, // 执行成功
  stateFailed = 3 // 执行失败
}

export enum Inneradmin_ModelClusterCmdMsg_Type {
  TypeNil = 0,
  TypeSoftwareListChange = 1, // 软件列表发生变化
  TypeClusterInfoChange = 2 // 集群信息发生变化
}

export enum Inneradmin_ModelStaff_Role {
  RoleNil = 0,
  RoleProductManager = 1,
  RoleDesign = 2,
  RoleFrontEnd = 3,
  RoleBackEnd = 4,
  RoleTest = 5,
  RoleProjectManager = 6
}

export enum Inneradmin_ModelProject_DocType {
  DocTypeNil = 0,
  DocTypeMd = 1
}

export enum Inneradmin_ModelProject_State {
  StateNil = 0,
  // @desc: 商务阶段
  StateBusiness = 1,
  // @desc: 开发中
  StateDev = 2,
  // @desc: 归档
  StateArch = 3
}

export enum Inneradmin_GetSoftwareGroupListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 软件筛选
  // @type: uint32
  ListOptionSoftwareId = 1
}

export enum Inneradmin_GetSoftwareListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 关键词搜索
  // @type: string
  ListOptionKey = 1,
  // @desc: 根据priority排序，true降序，false升序
  // @type: bool
  ListOptionOrderByPriority = 2
}

export enum Inneradmin_GetSoftwarePackageListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 软件筛选
  // @type: uint32
  ListOptionSoftwareId = 1,
  // @desc: 根据集群服务器ID筛选
  // @type: uint32
  ListOptionClusterServerId = 2,
  // @desc: 根据software_priority排序，true降序，false升序
  // @type: bool
  ListOptionOrderBySoftwarePriority = 3
}

export enum Inneradmin_GetApiPathListReq_ListOption {
  ListOptionNil = 0,
  // @type: stringList
  ListOptionServerName = 1
}

export enum Inneradmin_GetStaffRpcListReq_ListOption {
  ListOptionNil = 0
}

export enum Inneradmin_GetProjectTodoListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 搜索完成状态
  // @type: uint32List
  ListOptionStatus = 1,
  // @desc: 搜索优先级
  // @type: uint32List
  ListOptionPriority = 2,
  // @desc: 搜索(任务名)
  // @type: string
  ListOptionTaskName = 3
}

export enum Inneradmin_GetProjectTodoListReq_SortOrder {
  SortOrderNil = 0,
  SortOrderStatus = 1,
  SortOrderPriority = 2
}

export enum Inneradmin_SearchOssReportReq_ListOption {
  ListOptionNil = 0,
  // @desc: and 条件的关键词列表
  // @type: stringList
  ListOptionAndKeyWordList = 1,
  // @desc: cat 默认 term_report
  // @type: string
  ListOptionCat = 2
}

export enum Inneradmin_GetMockListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 按目录拉
  // @type: uint32
  ListOptionFolderId = 1
}

export enum Inneradmin_GetMockApiListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 按 mock id 拉
  // @type: uint32
  ListOptionMockId = 1,
  // @desc: 按 mock code 拉
  // @type: string
  ListOptionMockCode = 2,
  // @desc: 按 api 拉
  // @type: string
  ListOptionApi = 3
}

export enum Inneradmin_GetAccountListReq_TermType {
  TermTypeNil = 0,
  TermTypeWx = 1,
  TermTypeAli = 2
}

export enum Inneradmin_GetAccountListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  // @desc: 按昵称搜
  ListOptionName = 1,
  // @type: string
  // @desc: 按手机号查
  ListOptionPhone = 2
}

export enum Inneradmin_GetProjectListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 按项目名搜
  // @type: string
  ListOptionName = 1,
  // @desc: 按状态
  // @type: uint32
  ListOptionState = 2
}

export enum Wwcomm_SuiteType {
  SuiteType_UNDEFINED = 0,
  SuiteType_ORDINARY = 1,
  SuiteType_CONTACTS = 2
}

export enum Wwcomm_InfoType {
  InfoType_UNDEFINED = 0,
  InfoType_SUITE_TICKET = 1,
  InfoType_CREATE_AUTH = 2,
  InfoType_CHANGE_AUTH = 3,
  InfoType_CANCEL_AUTH = 4,
  InfoType_CHANGE_CONTACT = 5
}

export enum Wwcomm_AuthCorpAuthStatus {
  AuthCorpAuthStatus_UNDEFINED = 0,
  AuthCorpAuthStatus_UNAUTHORIZED = 1,
  AuthCorpAuthStatus_AUTHORIZED = 2
}

export enum Dbproxy_ModelTypeVersion_Type {
  TypeNil = 0,
  TypeObject = 1,
  TypeEnum = 2
}

export enum Dbproxy_ModelObjectTable_Usage {
  UsageNil = 0,
  UsageNormal = 1,
  UsageTest = 2
}

export enum Dbproxy_SyncCreateTableReq_Op {
  OpNil = 0,
  OpAdd = 1,
  OpDel = 2
}

export enum Corp_ModelCorp_Type {
  CORP_TYPE_NIL = 0,
  CORP_TYPE_PROPERTY = 1 // 物业公司
}

export enum Corp_ModelCorpManual_Type {
  CORP_TYPE_NIL = 0,
  CORP_TYPE_PROPERTY = 1 // 物业公司
}

export enum Corp_ListCorpReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionType = 1,
  // @type: uint32List
  ListOptionIds = 2,
  // @desc: 关键词搜索
  // @type: string
  ListOptionKey = 4
}

export enum Corp_ListAppReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionCorpId = 1,
  // @desc: 关键词搜索
  // @type: string
  ListOptionKey = 2
}

export enum Corp_GetCorpManualListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32List
  ListOptionIds = 1,
  // @desc: 关键词搜索
  // @type: string
  ListOptionKey = 2
}

export enum Corp_GetAppManualListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionCorpId = 1,
  // @desc: 关键词搜索
  // @type: string
  ListOptionKey = 2
}

export enum Lark_AppType {
  AppTypeUndefine = 0,
  AppTypeOwn = 1, //自建
  AppTypeStore = 2 //商店应用
}

export enum Lark_AppPlatform {
  AppPlatformUndefine = 0,
  AppPlatformFeishu = 1, //lark国内平台
  AppPlatformLark = 2 //lark海外平台
}

export enum Lark_ChargeMode {
  ChargeModeNil = 0,
  ChargeModeFree = 1, //免费
  ChargeModeUser = 2, //个人付费
  ChargeModeCompany = 3, //企业付费
  ChargeModeFull = 4, //一次性全额付清
  ChargeModeScore = 5 //按次(分值)收费
}

export enum Lark_AppChargeRuleType {
  AppChargeRuleTypeNil = 0,
  AppChargeRuleTypeQoll = 1 // 快问卷规则解析器 对应 AppChargeRuleQoll
}

export enum Lark_CorpChannel {
  CorpChannelNil = 0,
  CorpChannelLark = 1, // lark跟feishu 的渠道
  CorpChannelInternal = 2 // 内部渠道
}

export enum Lark_LarkOrderStatus {
  LarkOrderStatusNil = 0,
  LarkOrderStatusNormal = 1, // 正常
  LarkOrderStatusRefund = 2 // 退款
}

export enum Lark_I18nLanguage {
  I18nLanguageNil = 0,
  I18nLanguageZhCn = 1, // 中文
  I18nLanguageEnUs = 2, // 英文
  I18nLanguageJaJp = 3 // 日文
}

export enum Lark_ChatType {
  ChatTypeNil = 0,
  ChatTypePrivate = 1, // 私聊
  ChatTypeGroup = 2 // 群聊
}

export enum Lark_ScoreOpAction {
  ScoreOpActionNil = 0,
  ScoreOpActionInc = 1, //增加
  ScoreOpActionDec = 2, //减少
  ScoreOpActionInit = 3, //开通
  ScoreOpActionClean = 4 //过期清理
}

export enum Lark_EventCallbackType {
  EventCallbackTypeNil = 0,
  EventCallbackTypeP2PChatCreate = 1, //用户和机器人的会话首次被创建,对应的结构message为 P2PChatCreateEvent
  EventCallbackTypeAddBot = 2, //添加机器人进群,对应的结构message为 AddBotEvent
  EventCallbackTypeAppOpen = 3 //开通应用,对应的结构message为 AppOpenEvent
}

export enum Lark_ModelCardMsgCallback_State {
  StateNil = 0,
  StateInit = 1, //初始化状态
  StateCompleted = 2, //回调完成
  StateCalling = 3 //回调处理中
}

export enum Lark_ModelUserToken_TokenType {
  TokenTypeNil = 0,
  TokenTypeBearer = 1
}

export enum Lark_MessageType {
  MessageTypeUndefined = 0,
  MessageTypeText = 1,
  MessageTypeCard = 2
}

export enum Lark_GetUserListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionAllName = 1, //搜索name or name_py or en_name
  // @type: string
  ListOptionName = 2,
  // @type: string
  ListOptionPyName = 3,
  // @type: string
  ListOptionEnName = 4,
  // @type: stringList
  ListOptionOpenIdList = 5,
  // @type: stringList
  ListOptionNameList = 6,
  // @type: stringList
  ListOptionMobileList = 7,
  // @type: stringList
  ListOptionEmailList = 8
}

export enum Lark_ChatListOption {
  ChatListOptionNil = 0,
  // @type: string
  ChatListOptionOpenId = 1,
  // @type: string
  ChatListOptionName = 2
}

export enum Lark_Action_Tag {
  TagNil = 0,
  TagDatepicker = 1,
  TagOverflow = 2,
  TagStaticSelect = 3,
  TagButton = 4
}

export enum Lark_GetAppOrderListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32List
  ListOptionCorpIdList = 1,
  // @type: uint32
  ListOptionCreatedAtBegin = 2,
  // @type: uint32
  ListOptionCreatedAtEnd = 3
}

export enum Sns_ContentType {
  ContentTypeUndefined = 0,
  ContentTypeText = 1,
  ContentTypeImage = 2
}

export enum Sns_CommentBizType {
  CommentBizTypeUndefined = 0,
  // 活动评论
  CommentBizTypeActivity = 1,
  // 活动互动 -- 管理后台评论
  CommentBizTypeActivityInteractiveAdmin = 2,
  // 活动互动 -- 用户评论
  CommentBizTypeActivityInteractiveUser = 3,
  // 内容评论
  CommentBizTypeContent = 4
}

export enum Sns_GetCommentListAdminReq_ShowStatus {
  ShowStatusNil = 0,
  ShowStatusShow = 1,
  ShowStatusHide = 2
}

export enum Sns_GetCommentListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionBizType = 1,
  // @type: string
  ListOptionContent = 2,
  // @desc: 参考 enum ShowStatus
  // @type: uint32
  ListOptionShowStatus = 3
}

export enum Sns_CommentOpAdminReq_Op {
  OpNil = 0,
  OpShow = 1,
  OpHide = 2
}

export enum Oms_OrderState {
  OrderStateUndefined = 0,
  OrderStateInit = 1,
  // 订单OK了，待支付
  OrderStateReady2Pay = 2,
  OrderStatePaid = 3,
  // 这个暂时没啥用(外部系统用)
  OrderStateSysConfirm = 4, // 系统已确认
  OrderStateDelivering = 5, // 发货中 (已发货/待收货)
  // 这个暂时没啥用(确认收货即到11已完成)
  OrderStateConfirmReceived = 10, // 确认收货
  OrderStateConfirmFinished = 11, // 确认完成
  OrderStateSysCanceled = 12, // 未支付，系统取消
  OrderStateRefundCanceled = 13, // 全单已退款成功,订单关闭
  OrderStateOutCanceled = 14, // 订单被外部系统关闭
  OrderStateDeleted = 20 // 订单已删除
}

export enum Oms_OrderSku_State {
  StateNil = 0,
  StateInvalidSkuId = 1,
  StateNotEnoughStock = 2,
  StateGtMaxBuyCount = 3
}

export enum Oms_OrderSku_RefundState {
  RefundStateUndefined = 0,
  RefundStateSuccess = 1,
  RefundStateFailed = 2,
  //退款状态太多..先定个笼统的吧
  RefundStateRefunding = 3
}

export enum Oms_ModelOrderPayment_PaymentType {
  PaymentTypeNil = 0,
  PaymentTypeWechatPay = 1, // 微信支付
  PaymentTypeWalletPoint = 2 // 钱包积分支付
}

export enum Oms_PayStatus {
  PayStatusUndefined = 0,
  PayStatusNotPay = 1,
  PayStatusPaid = 2,
  PayStatusRefunded = 3
}

export enum Oms_MqOrderUpdateReq_Type {
  TypeUndefined = 0,
  TypePaid = 1,
  TypePayNotifyProcessError = 2,
  TypeSysConfirm = 3,
  TypeDelivering = 4
}

export enum Oms_SaveOrderReq_SaveType {
  SaveTypeNil = 0,
  SaveTypeCashCoupon = 1, // 修改优惠券
  SaveTypeDeliveryInfo = 2 // 修改地址
}

export enum select_Feight_Template_Table {
  selectTypeh = 1,
  selectTypes = 2,
}

export enum select_Feight_Table {
  ListOptionUndefined = 0,
  // @type: uint32
  ListOptionState = 1,
  // @type: uint32List
  ListOptionStates = 2,
  // @type: uint64List
  ListOptionIds = 3,
  // @type: string
  ListOptionOrderNumber = 4,
  // @type: timeStampRange
  ListOptionCreatedAt = 5, // 订单创建时间
  // @type: string
  ListOptionFuzzySearch1 = 6,
  // @type: string
  ListOptionFuzzySearch2 = 7,
  // @type: string
  ListOptionSearch1 = 8,
  // @type: string
  ListOptionSearch2 = 9
} 

export enum Oms_ListOption {
  ListOptionUndefined = 0,
  // @type: uint32
  ListOptionState = 1,
  // @type: uint32List
  ListOptionStates = 2,
  // @type: uint64List
  ListOptionIds = 3,
  // @type: string
  ListOptionOrderNumber = 4,
  // @type: timeStampRange
  ListOptionCreatedAt = 5, // 订单创建时间
  // @type: string
  ListOptionFuzzySearch1 = 6,
  // @type: string
  ListOptionFuzzySearch2 = 7,
  // @type: string
  ListOptionSearch1 = 8,
  // @type: string
  ListOptionSearch2 = 9
}

export enum Select_Table {

  // @type: timeStampRange
  ListOptionCreatedAt = 5, // 订单创建时间

}

export enum Oms_SaveOrderAdminReq_SaveType {
  SaveTypeNil = 0,
  SaveTypeSendDeliver = 1, // 发货
  SaveTypeDeliveryInfo = 2 // 修改地址
}

export enum Wechatpay_NoteId {
  NOTE_ID_NIL = 0,
  NOTE_ID_AUTO_REFUND = 1, // 自动退款
  NOTE_ID_TEST_ENVIRONMENT_BULK_REFUND = 2 // 测试环境批量退款
}

export enum Wechatpay_PayBizScene {
  PayBizSceneUndefined = 0,
  PayBizSceneStoreOrderPay = 1,
  PayBizSceneRedPacket = 2
}

export enum Wechatpay_Payment_Status {
  PAYMENT_STATUS_NIL = 0,
  PAYMENT_STATUS_REFUNDED = 65536 // 2^16 已退款
}

export enum Wechatpay_RefundBillState {
  RefundBillStateUndefined = 0,
  RefundBillStateSuccess = 1,
  RefundBillStateFailed = 2
}

export enum Wechatpay_GetPayConfigListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 按 mch id
  // @type: uint32
  ListOptionPayMchId = 1
}

export enum Delivery_ExpressCompanyType {
  ExpressCompanyNil = 0,
  ExpressCompanySfExpress = 1, //顺丰快递
  ExpressCompanySfCity = 2, //顺丰同城
  ExpressCompanyYTO = 3, //圆通快递
  ExpressCompanyZTO = 4 //中通快递
}

export enum Delivery_ModelDelivery_DeliveryType {
  DeliveryTypeNil = 0,
  DeliveryTypePickUp = 1, // 自提
  DeliveryTypeExpress = 2 // 物流
}

export enum Delivery_ModelDelivery_DeliveryState {
  DeliveryStateNil = 0,
  DeliveryStateInit = 1,
  DeliveryStateSent = 2,
  DeliveryStateFinished = 3,
  DeliveryStateFailed = 4
}

export enum Delivery_ModelExpress_DeliveryState {
  DeliveryStateNil = 0,
  DeliveryStateFailed = 1, //订单关闭或取消
  DeliveryStateInit = 2, //初始化，揽收等状态
  DeliveryStateSent = 3, //运输中
  DeliveryStateWaitReceive = 4, //等待收货
  DeliveryStateFinished = 5 //订单完成
}

export enum Delivery_ModelSfCity_SfCityState {
  DeliveryStateNil = 0,
  DeliveryStateFailed = 1, //订单关闭或取消
  DeliveryStateInit = 2, //门店生成订单、配送员接单、到店
  DeliveryStateSent = 3, //配送途中
  DeliveryStateFinished = 4 //订单完成
}

export enum Delivery_DeliveryCompanyKindType {
  DeliveryCompanyKindTypeNil = 0,
  DeliveryCompanyKindTypeExpress = 1,
  DeliveryCompanyKindTypeTakeAway = 2
}

export enum Delivery_GetExpressAppConfigListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionAppId = 1,
  // @type: uint32
  ListOptionCorpId = 2
}

export enum Store_GetProductListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionName = 1,
  // @type: bool
  ListOptionSoldOut = 2,
  // @type: uint32
  ListOptionCategoryId = 3 // 分类
}

export enum Store_GetProductListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionName = 1,
  // @type: bool
  ListOptionSoldOut = 2,
  // @type: uint32
  ListOptionCategory = 3
}

export enum Store_GetProductSkuListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionName = 1
}

export enum Commonv2_ModelTag_PrivilegeType {
  PrivilegeTypeNil = 0,
  PrivilegeTypePublic = 1, // 公共可见
  PrivilegeTypePrivately = 2 // 私有可见
}

export enum Commonv2_ModelIdentityCertType_IdentityCertType {
  IdentityCertTypeNil = 0,
  IdentityCertTypeIdFace = 1 // 身份证人脸识别
}

export enum Commonv2_ModelIdentityCertAccount_ProviderType {
  ProviderTypeNil = 0,
  ProviderTypeNuoZhengTong = 1 // 诺正通
}

export enum Commonv2_ModelImageCheckAccount_ProviderType {
  ProviderTypeNil = 0,
  ProviderTypeTuputech = 1 // 图谱
}

export enum Commonv2_TagListOption {
  TagListOptionNil = 0,
  // @type: string
  TagListOptionName = 1, // 标签名
  // @type: uint32
  TagListOptionOrderCreate = 2, // 创建时间排序
  // @type: uint64
  TagListOptionCreatedUid = 3, // 创建者
  // @type: uint64
  TagListOptionOwnerUid = 4, // 拥有者
  // @type: uint32
  TagListOptionPrivilegeType = 5, // 权限类型
  // @type: uint64List
  TagListOptionIds = 6 // id列表
}

export enum Commonv2_GetCategoryListV2Req_ListOption {
  ListOptionNil = 0,
  // @type: uint32List
  ListOptionCategoryTypes = 1,
  // @type: bool
  ListOptionIsDisable = 2,
  // @type: uint32List
  ListOptionIds = 3
}

export enum Commonv2_GetAsyncTaskStateRsp_State {
  StateNil = 0,
  StateInProgress = 1, // 正在运行
  StateSuccess = 2, // 运行成功
  StateFailure = 3 // 运行失败
}

export enum Stockmgr_StockOpType {
  StockOpTypeUndefined = 0,
  StockOpTypeIncTotal = 1,
  StockOpTypeDecTotal = 2,
  StockOpTypeLock = 3,
  StockOpTypeLock2Sale = 4,
  StockOpTypeLock2Unlock = 5,
  StockOpTypeSale = 6,
  StockOpTypeSetTotal = 7,
  StockOpTypeRollbackSale = 8,
  StockOpTypeReset = 9
}

export enum Shensuo_CategoryType {
  CategoryTypeNil = 0,
  CategoryTypeStore = 1 // 商品分类
}

export enum Shensuo_StoreType {
  StoreTypeNil = 0,
  StoreTypeComm = 1 // 普通商城
}

export enum Shensuo_QrcodeSize {
  QrcodeSizeNil = 0,
  QrcodeSizeSmall = 1, //小尺寸128 X 128
  QrcodeSizeMedium = 2, //小尺寸256 X 256
  QrcodeSizeLarge = 3 //小尺寸512 X 512
}

export enum Shensuo_QrcodeLevel {
  QrcodeLevelNil = 0,
  QrcodeLevelLow = 1,
  QrcodeLevelMedium = 2,
  QrcodeLevelHigh = 3,
  QrcodeLevelHighest = 4
}

export enum Shensuo_ModelUser_Type {
  TypeNil = 0,
  TypeMember = 1, // 会员
  // 以下的type是代理
  TypeChiefBrandOfficer = 2, // 首席品牌官
  TypeAreaAgency = 3, // 区荣誉特使
  TypeCityAgency = 4, // 城市代言人
  TypeProvinceAgency = 5, // 省级代言人
  TypeRegionalPartner = 6, // 大区合伙人
  TypeTutorClassTrainee = 7, // 导师班学员
  TypeSeniorPartner = 8, // 高级合伙人
  TypeStaff = 9, // 身所员工
  TypeFounder = 10, // 身所创始人
  TypeTutor = 11, // 明星导师
  TypeNormalTutor = 12, // 普通导师
  TypeGroupProjectShareholder = 13 // 集团项目股东
}

export enum Shensuo_ModelLessonScheduleApplication_State {
  StateNil = 0,
  StatePending = 1, // 待处理
  StateIgnore = 2, // 已忽略
  StateScheduleDone = 3 // 已排课
}

export enum Shensuo_ModelLessonBooking_State {
  StateNil = 0,
  StateUserSubmitApplication = 1, // 用户提交申请
  StateAgencySubmitApplication = 2, // 等待审核
  StateAdminPassAudit = 3, // 管理员通过审核
  StateAdminRefuseAudit = 4 // 管理员拒绝审核
}

export enum Shensuo_ModelLessonBooking_Type {
  TypeNil = 0,
  TypeInitialTraining = 1, // 初训
  TypeRetraining = 2 // 复训
}

export enum Shensuo_ModelLessonPresented_State {
  StateNil = 0,
  StatePending = 1 // 待发放
}

export enum Shensuo_ModelLessonPresented_Type {
  TypeNil = 0,
  TypeAdminPresented = 1, // 管理员赠送
  TypeBookingDeduct = 2 // 预约扣除
}

export enum Shensuo_ModelBanner_Type {
  TypeNil = 0,
  TypeH5HomePage = 1, // h5 的首页的 banner
  TypeStorePage = 2, // 商城首页的 banner
  TypeH5LessonIcon = 3 // 课程
}

export enum Shensuo_ModelBanner_JumpType {
  JumpTypeNil = 0,
  JumpTypeLessonSchedule = 1, // 转跳到排课
  JumpTypeNews = 2, // 资讯
  JumpTypeLesson = 3 // 转跳到课程
}

export enum Shensuo_ModelContent_Type {
  TypeNil = 0,
  TypeUserPublish = 1, // 用户发表的
  TypeAdminPublish = 2 // 管理员发表的
}

export enum Shensuo_ModelContent_State {
  StateNil = 0,
  StateVideoTranscodingSuccess = 1 // 视频转码成功
}

export enum Shensuo_ModelSubOnlineLesson_State {
  StateNil = 0,
  StateVideoTranscodingSuccess = 1 // 视频转码成功
}

export enum Shensuo_ModelOnlineLesson_State {
  StateNil = 0,
  StateOnShelves = 1, // 上架
  StateOffShelves = 2 // 下架
}

export enum Shensuo_ModelOnlineLessonOrder_State {
  StateNil = 0,
  StateInit = 1,
  StatePaid = 2,
  StateRefund = 3,
  // @desc: 已取消
  StateCancel = 4
}

export enum Shensuo_SendVerifyCodeReq_Type {
  TypeNil = 0,
  TypeLogin = 1, // 登陆
  TypeChangeCustomerPhone = 2 // 修改手机号
}

export enum Shensuo_UserListOption {
  UserListOptionNil = 0,
  // @type: string
  UserListOptionName = 1,
  // @type: string
  UserListOptionPhone = 2,
  // @type: uint32
  UserListOptionType = 3,
  // @type: string
  UserListOptionInviteCode = 4,
  // 过滤可以代发内容的，先临时这样写，后期需要做授权管理这个东西
  // @type: bool
  UserListOptionCanBeSendContent = 5,
  // @type: uint32List
  UserListOptionTypeList = 6
}

export enum Shensuo_SetLessonScheduleOptionAdminReq_TopType {
  TopTypeNil = 0,
  TopTypeTop = 1, // 置顶
  TopTypeCancelTop = 2 // 取消置顶
}

export enum Shensuo_SetLessonScheduleOptionAdminReq_CancelOption {
  CancelOptionNil = 0,
  CancelOptionClose = 1, // 不取消
  CancelOptionOpen = 2 // 取消
}

export enum Shensuo_LessonScheduleListOption {
  LessonScheduleListOptionNil = 0,
  // @type: string
  LessonScheduleListOptionName = 1, // 排课名
  // @type: uint32List
  LessonScheduleListOptionLessonScheduleTime = 2, // 排课时间
  // @type: string
  LessonScheduleListOptionCity = 3, // 城市
  // @type: uint32
  LessonScheduleListOptionLessonId = 4, // 课程
  // @type: bool
  LessonScheduleListOptionCanBooking = 5, // 可以预约的
  // @type: uint64
  LessonScheduleListOptionTutorId = 6, // 导师的id
  // @type: string
  LessonScheduleListOptionSponsor = 7, // 主办方
  // @type: string
  LessonScheduleListOptionDistrict = 8, // 地区
  // @type: bool
  LessonScheduleListOptionCancel = 9, // 是否已取消
  // @type: uint32
  LessonScheduleListOptionState = 10, // 课程状态
  // @type: string
  LessonScheduleListOptionLessonScheduleName = 11, // 课程名，模糊搜索
  // @type: uint32List
  LessonScheduleListOptionLessonIdList = 12 // 多课程id的筛选
}

export enum Shensuo_LessonScheduleState {
  LessonScheduleStateNil = 0,
  LessonScheduleStateNotBegin = 1, // 未开始
  LessonScheduleStateUnderway = 2, // 进行中
  LessonScheduleStateFinished = 3 // 已结束
}

export enum Shensuo_LessonBookingListOption {
  LessonBookingListOptionNil = 0,
  // @type: bool
  LessonBookingListOptionIsVisit = 1, // 已回访状态
  // @type: string
  LessonBookingListOptionUserName = 2, // 客户姓名
  // @type: string
  LessonBookingListOptionUserPhone = 3, // 客户手机号
  // @type: uint32
  LessonBookingListOptionHandlerType = 4, // 处理人类型
  // @type: uint32List
  LessonBookingListOptionState = 5, // 预约状态
  // @type: bool
  LessonBookingListOptionShow = 6, // 是否展示剩余赠课
  // @type: uint32
  LessonBookingListOptionLessonScheduleId = 7, // 排课
  // @type: string
  LessonBookingListOptionReferrerName = 8, // 推荐人姓名
  // @type: uint32
  LessonBookingListOptionLessonId = 9 //课程类型
}

export enum Shensuo_LessonBookingHandlerType {
  LessonBookingHandlerTypeNil = 0,
  LessonBookingHandlerTypeHeadquarters = 1, // 总部
  LessonBookingHandlerTypeAgent = 2 // 代理
}

export enum Shensuo_SetSubLessonBookingReq_Visit {
  VisitNil = 0,
  VisitDone = 1, // 已回访
  VisitUndone = 2 // 未回访
}

export enum Shensuo_SetLessonBookingAdminReq_Visit {
  VisitNil = 0,
  VisitDone = 1, // 已回访
  VisitUndone = 2 // 未回访
}

export enum Shensuo_SetLessonBookingAdminReq_AuditType {
  AuditTypeNil = 0,
  AuditTypePass = 1, // 审核通过
  AuditTypeRefuse = 2 // 审核拒绝
}

export enum Shensuo_BannerListOption {
  BannerListOptionNil = 0,
  BannerListOptionState = 1 // 状态
}

export enum Shensuo_BannerState {
  BannerStateNil = 0,
  BannerStateNoActive = 1, // 未生效
  BannerStateValid = 2, // 生效
  BannerStateInvalid = 3 // 失效
}

export enum Shensuo_LessonPresentedListOption {
  LessonPresentedNil = 0,
  // @type: uint32
  LessonPresentedLessonId = 1 // 指定课程的记录
}

export enum Shensuo_LessonCommissionListOption {
  LessonCommissionListOptionNil = 0,
  // @type: string
  LessonCommissionListOptionUserName = 1, // 用户姓名
  // @type: uint32
  LessonCommissionListOptionSettlementMonth = 2 // 结算时间
}

export enum Shensuo_GetContentListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint64
  ListOptionUid = 1
}

export enum Shensuo_GetContentListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionName = 1
}

export enum Shensuo_DistrictListOption {
  DistrictListOptionNil = 0,
  // @type: uint32
  DistrictListOptionParentId = 1, // 父 id 用于分层获取
  // @type: uint32
  DistrictListOptionLevel = 2 // 用户获取某一级的数据，比如获取城市的列表
}

export enum Shensuo_ProxyRequestReq_Type {
  TypeNil = 0,
  TypeLbs = 1 // 地理位置信息
}

export enum Shensuo_GetLessonScheduleApplicationListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionUserName = 1, // 代理名
  // @type: uint32List
  ListOptionState = 2 // 状态
}

export enum Shensuo_GetLessonScheduleApplicationListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionUserName = 1, // 代理名
  // @type: uint32List
  ListOptionState = 2 // 状态
}

export enum Shensuo_GetOnlineLessonCommissionListAdminReq_ListOption {
  ListOptionNil = 0,
  // @desc: 精确搜索用户姓名或手机号
  // @type: string
  ListOptionUserNameOrPhone = 1
}

export enum Shensuo_GetOnlineLessonListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 是否随机
  // @type: bool
  ListOptionNeedRandom = 1
}

export enum Shensuo_GetOnlineLessonListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionName = 1,
  // @type: uint32
  ListOptionState = 2
}

export enum Shensuo_GetOnlineLessonOrderListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionUserName = 1,
  // @type: string
  ListOptionReferrerName = 2,
  // @type: string
  ListOptionUserPhone = 3,
  // @type: string
  ListOptionReferrerPhone = 4,
  // @type: timeStampRange
  ListOptionTimeRange = 5
}

export enum Shensuo_ModelWithdrawApply_State {
  StateNil = 0,
  StateUserSubmit = 1, // 用户已提交
  StateAdminPass = 2, // 管理员同意
  StateAdminRefuse = 3, // 管理员拒绝
  StateDefeatedWithdraw = 4 // 失败的提现
}

export enum Shensuo_GetWithdrawApplyListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionNameOrPhone = 1
}

export enum Shensuo_SetWithdrawApplyAdminReq_Type {
  TypeNil = 0,
  TypePass = 1,
  TypeRefuse = 2
}

export enum Shensuo_WithdrawRecord_Type {
  TypeNil = 0,
  TypeOnlineLessonCommission = 4, // 线上课程返利
  TypeOnlineLessonCommissionWithdraw = 5 // 线上课程返利提现
}

export enum Omsv2_OrderState {
  OrderStateUndefined = 0,
  OrderStateInit = 1,
  // @desc: 订单OK了，待支付
  OrderStateReady2Pay = 2,
  OrderStatePaid = 3,
  // @desc: 系统已确认
  OrderStateSysConfirm = 4,
  // @desc: 发货中 (已发货/待收货)
  OrderStateDelivering = 5,
  // @desc: 确认完成
  OrderStateConfirmFinished = 8,
  // @desc: 未支付，系统取消
  OrderStateSysCanceled = 12
}

export enum Omsv2_PayChannel {
  PayChannelNil = 0,
  PayChannelWx = 1,
  PayChannelAlipay = 2
}

export enum Omsv2_PayState {
  PayStateNil = 0,
  PayStateInit = 1,
  PayStatePaid = 2
}

export enum Omsv2_RefundState {
  RefundStateNil = 0,
  RefundStateInit = 1,
  RefundStateSuccess = 2,
  RefundStateFailed = 3
}

export enum Omsv2_DeliveryType {
  DeliveryTypeNil = 0,
  // @desc: 自提
  DeliveryTypePickUp = 1,
  // @desc: 物流
  DeliveryTypeExpress = 2
}

export enum Omsv2_Address_Type {
  TypeNil = 0,
  // @desc: 字符串地址
  TypeStr = 1,
  // @desc: 行政区划(Administrative Divisions)
  TypeAdminDiv = 2
}

export enum Omsv2_GetOrderListReq_ListOption {
  ListOptionNil = 0,
  // @desc: 按状态
  // @type: uint32List
  ListOptionStates = 1,
  // @desc: 按 order type
  // @type: uint32List
  ListOptionTypes = 2,
  // @desc: user_deleted
  // @type: bool
  ListOptionUserDeleted = 3,
  // @desc: 按 order id
  // @type: uint64List
  ListOptionOrderIds = 4,
  // @desc: 按 order number
  // @type: stringList
  ListOptionOrderNumbers = 5,
  // @desc: 按用户
  // @type: uint64
  ListOptionUid = 6,
  // @desc: 搜索, search0 or search1 ...
  // @type: string
  ListOptionSearch = 7
}

export enum Omsv2_SetOrderReq_SetFlag {
  SetFlagNil = 0,
  SetFlagCoupon = 1,
  SetFlagDelivery = 2
}

export enum Omsv2_OrderChangedReq_ChangeType {
  ChangeTypeNil = 0,
  ChangeTypePaid = 1
}

export enum Omsv2_OrderChangedRsp_Action {
  ActionNil = 0,
  ActionRefundAll = 1
}

export enum Alipay_ModelPayment_Status {
  PAYMENT_STATUS_NIL = 0,
  PAYMENT_STATUS_REFUNDED = 65536 // 2^16 已退款
}

export enum Alipay_GetAccountListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionPhone = 1,
  // @type: string
  ListOptionName = 2
}

export enum Admin_LoginIdType {
  LoginIdTypeUndefined = 0,
  LoginIdTypePhone = 1
}

export enum Admin_GetAdminListReq_ListOption {
  ListOptionNil = 0,
  // @type: string
  ListOptionNameSearch = 1
}

export enum Admin_GetUserListAdminReq_ListOption {
  ListOptionNil = 0,
  // @type: uint64List
  ListOptionUids = 1
}

export enum Rbacv2_GetRoleListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionAppId = 2,
  // @type: string
  ListOptionName = 3,
  // @type: uint32List
  ListOptionIds = 4
}

export enum Rbacv2_GetRoleUserListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint64List
  ListOptionUid = 1,
  // @type: uint32List
  ListOptionRoleId = 2,
  // @type: uint32
  ListOptionAppId = 4,
  // @type: uint32
  ListOptionCorpId = 5
}

export enum Rbacv2_GetRoleListInternalReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32
  ListOptionAppId = 2,
  // @type: string
  ListOptionName = 3,
  // @type: uint32List
  ListOptionIds = 4
}

export enum Rbacv2_GetMenuApiMapListReq_ListOption {
  ListOptionNil = 0,
  // @type: uint32List
  ListOptionMenuIdList = 1
}

export enum ErrCode {
  Success = 0,

  // accountlogic enum start

  // range 15000 - 16000

  accountlogic_ErrWrongPassword = 15004, // 密码错误
  accountlogic_ErrInvalidRequest = 15005, // 无效的请求    // accountlogic enum end

  // account enum start

  // range 14000 - 15000

  account_ErrAccountNotFound = 14008, // 账号不存在
  account_ErrProfileNotFound = 14009, // 没有找到用户资料
  account_ErrAppNotFound = 14010, // 没有找到应用
  account_ErrInvalidAccount = 14011, // 无效的账号
  account_ErrInvalidRequest = 14012, // 无效的请求
  account_ErrPhoneExisted = 14013, // 手机号已存在
  account_ErrAccountIdExisted = 14014, // 账号id已存在    // account enum end

  // session enum start

  // 4000 - 5000
  SessionNotFound = 4000, // session enum end

  // wechat enum start

  // 11000 - 12000
  wechat_ErrWechat = 11007, // 系统错误
  wechat_ErrNotFoundFormId = 11003,
  wechat_ErrWxAppNotFound = 11004,
  wechat_ErrAccessTokenNotFound = 11005,
  wechat_ErrNotFoundWxwConf = 11006,
  wechat_ErrWxwGetUnionIdwechat_Error = 11008, // 获取union id 失败
  wechat_ErrAppNotAuth = 11009, // 小程序未授权
  wechat_ErrMpComponentNotFound = 11010, // 第三方平台不存在
  wechat_ErrAppTypeNotSupported = 11011, // 应用类型不支持
  wechat_ErrWxAppConfigNotFound = 11012,
  wechat_ErrInvalidRequest = 11013, // 无效的请求
  wechat_ErrRiskyContent = 11014, // 含有违法违规内容
  wechat_ErrFileTooLarge = 11015, // 文件太大，请重试
  wechat_ErrRequireAppIdAndComponentKey = 11016, //appId和componentKey不能为空
  wechat_ErrRequireComponentKey = 11017, // componentKey不能为空
  wechat_ErrRequireAppId = 11018, // appId不能为空
  wechat_ErrUnknownAppType = 11019, // 未知的appType
  wechat_ErrInvalidCode = 11020, // 网页授权的code无效
  wechat_ErrNotOfficialApp = 11021, // 非公众号
  wechat_ErrUnknownAuthType = 11022, // 未知的authType
  wechat_ErrIpNotInWhiteList = 11023, // IP地址未加入白名单列表
  wechat_ErrCodeTemplateIsEmpty = 11024, // 代码模版为空
  wechat_ErrProcessingAuditExists = 11025, // 存在审核中的记录
  wechat_ErrAuthTypeNotSupported = 11026, // 授权类型不支持
  wechat_ErrCancelAuditLimit = 11027, // 撤回次数达到上限（每天一次，每个月 10 次）
  wechat_ErrUnknownMetaRole = 11028, // 未知的rpc.Meta.Role
  wechat_ErrNotSupportSingleMp = 11029, // 不支持个人版小程序
  wechat_ErrUnsupportedMsgType = 11030, // 微信公众号消息类型不存在
  wechat_ErrDecryptFailure = 11031, // 解密失败
  wechat_ErrUnsupportedEvent = 11032, // 不支持的事件
  wechat_ErrEncryptFailure = 11033, // 加密失败    // wechat enum end

  // schedulerv2 enum start

  // range 101000 - 102000

  schedulerv2_ErrInvalidRequest = 101001,
  schedulerv2_ErrTaskDuplication = 101002,
  schedulerv2_ErrTaskNotFound = 101003, // schedulerv2 enum end

  // weblogic enum start

  // 41000 - 42000
  weblogic_ErrMissedAppIdHeader = 41000,
  weblogic_ErrInvalidLogin = 41001,
  weblogic_ErrMissedPath = 41002,
  weblogic_ErrMissedAppId = 41003, // weblogic enum end

  // storage enum start

  // 26000 - 27000

  storage_ErrStorageTypeOnlySupportedQiniu = 26001,
  storage_ErrUploadFail = 26002,
  storage_ErrAppIdNotFound = 26003,
  storage_ErrQiniuNoSuchFileOrDirectory = 26004,
  storage_ErrTimeout = 26005,
  storage_ErrResourceNotFound = 26010,
  storage_ErrResourceConfigNotFound = 26011,
  storage_ErrStorageTypeNotSupport = 26012,
  storage_ErrInvalidParam = 26013,
  storage_ErrConfigNotFound = 26014, // 配置不存在
  storage_ErrConfigMiss = 26015, // 配置缺失
  storage_ErrResourceConflict = 26016, // 资源已存在
  storage_ErrInvalidResourceState = 26017,
  storage_ErrInvalidRequest = 26018, // 无效的请求
  storage_ErrInvalidBucketAcl = 26019,
  storage_ErrInvalidConfig = 26020, // 非法任务
  storage_ErrConfigConflict = 26021, // 配置已存在
  storage_ErrInvalidContentType = 26022,
  storage_ErrNoSuchFileOrDirectory = 26023, // 文件不存在
  storage_ErrInvalidTaskType = 26024, // 非法任务类型
  storage_ErrUnsupportedTaskArgs = 26025, // 不支持的任务参数
  storage_ErrTaskNotFound = 26026, // 任务不存在
  storage_ErrInvalidTask = 26027, // 非法任务
  storage_ErrGetDownloadUrlFail = 26028, // storage enum end

  // inneradmin enum start

  // range 57000 - 58000

  inneradmin_ErrStateNotExistOrExpires = 57002,
  inneradmin_ErrUserNotFound = 57003,
  inneradmin_ErrClusterNotFound = 57004, // 集群不存在
  inneradmin_ErrClusterServerNotFound = 57005, // 集群服务器不存在
  inneradmin_ErrSoftwareGroupNotFound = 57006, // 软件组不存在
  inneradmin_ErrInvalidRequest = 57007, // 无效的请求
  inneradmin_ErrSoftwareGroupHasClusterUsed = 57008, // 有集群正在使用该软件组
  inneradmin_ErrSoftwareGroupHasClusterServerUsed = 57009, // 有集群服务器正在使用该软件组
  inneradmin_ErrSoftwarePackageNotFound = 57010, // 软件包不存在
  inneradmin_ErrSoftwareGroup2SoftwarePackageConflict = 57011,
  inneradmin_ErrSoftwarePackageConflict = 57012,
  inneradmin_ErrSoftwareNotFound = 57013,
  inneradmin_ErrClusterServerRunning = 57014, // 集群服务器运行中
  inneradmin_ErrAutoConfigTypeNotFound = 57015,
  inneradmin_ErrFrontEndDepServerNotFound = 57016,
  inneradmin_ErrMissedCorpIdOrAppId = 57017, // 缺少 corp_id 或 app_id 参数
  inneradmin_ErrStaffRpcNotFound = 57018, // 内部工具不存在
  inneradmin_ErrProjectNotFound = 57019,
  inneradmin_ErrProjectBasicInfoNotFound = 57020,
  inneradmin_ErrTodoJobNotFound = 57021,
  inneradmin_ErrInvalidRpcName = 57022,
  inneradmin_ErrNotFoundProto = 57024,
  inneradmin_ErrParsePbFail = 57025,
  inneradmin_ErrNotFoundRpc = 57026,
  inneradmin_ErrMockFolderNotFound = 57027,
  inneradmin_ErrParentMockFolderNotFound = 57028,
  inneradmin_ErrMockFolderNameConflict = 57029,
  inneradmin_ErrMockFolderHasMock = 57030,
  inneradmin_ErrMockFolderHasChildFolder = 57031,
  inneradmin_ErrMockNotFound = 57032,
  inneradmin_ErrMissedMockFolder = 57033,
  inneradmin_ErrMissedMockName = 57034,
  inneradmin_ErrMockNameConflict = 57035,
  inneradmin_ErrMockApiNotFound = 57036,
  inneradmin_ErrInvalidStaffId = 57037,
  inneradmin_ErrProjectStaffIsEmpty = 57038, // inneradmin enum end

  // dbproxy enum start

  dbproxy_ErrObjectTypeDupCreate = 16001,
  dbproxy_ErrEnumTypeDupCreate = 16002,
  dbproxy_ErrNotFoundObjectType = 16003, // 对象类型不存在
  dbproxy_ErrModelUniqueIndexConflict = 16004,
  dbproxy_ErrTableNotCreate = 16005,
  dbproxy_ErrInvalidJsonData = 16006,
  dbproxy_ErrMissedId = 16007,
  dbproxy_ErrTransactionNotFound = 16008,
  dbproxy_ErrInvalidSyncTableOp = 16009,
  dbproxy_ErrInvalidLinkUsage = 16010,
  dbproxy_ErrNotPermitDropNormalTable = 16011,
  dbproxy_ErrCanNotSkipAllFields = 16012, // dbproxy enum end

  // corp enum start

  // 102000 - 103000
  corp_ErrCorpNotFound = 102000,
  corp_ErrAppNotFound = 102001,
  corp_ErrCorpExisted = 102002,
  corp_ErrAppExisted = 102003,
  corp_ErrAppConflict = 102004,
  corp_ErrCorpConflict = 102005,
  corp_ErrCorpManualNotFound = 102006,
  corp_ErrAppManualNotFound = 102007, // corp enum end

  // lark enum start

  // 120000 - 121000
  lark_ErrStrIdExisted = 120000,
  lark_ErrAppIdExisted = 120001,
  lark_ErrAppNotFound = 120002,
  lark_ErrInvalidCode = 120003,
  lark_ErrInvalidMessageType = 120004,
  lark_ErrDeptListTooLong = 120005,
  lark_ErrOpenIdListTooLong = 120006,
  lark_ErrInvalidTextMessage = 120007,
  lark_ErrInvalidCardMessage = 120008,
  lark_ErrLarkApilark_Error = 120009,
  lark_ErrStoreTenantAccessTokenNotFound = 120010,
  lark_ErrInvalidEvent = 120011,
  lark_ErrAppChargeRuleNotFound = 120012,
  lark_ErrAppChargeNotInScope = 120013,
  lark_ErrAppChargeNoActiveLicense = 120014,
  lark_ErrAppChargeInvalid = 120015,
  lark_ErrUserNotFound = 120016,
  lark_ErrCorpChargePlanNotFound = 120017,
  lark_ErrMissReceiver = 120018,
  lark_ErrContentNotFound = 120019,
  lark_ErrMsgDirectiveNotFound = 120020,
  lark_ErrCorpNotFound = 120021,
  lark_ErrMissCorpIdOrTenantKey = 120022,
  lark_ErrCorpChargePlanVersionException = 120023,
  lark_ErrScoreNotEnough = 120024,
  lark_ErrOperationLogNotFound = 120025,
  lark_ErrOperationLogDupAction = 120026,
  lark_ErrMissChargeIdOrPricePlanId = 120027,
  lark_ErrChatNotFound = 120028,
  lark_ErrCardMsgCallbackNotFound = 120029,
  lark_ErrMissUidOrOpenId = 120030,
  lark_ErrChargePlanRuleByIdNotFound = 120031,
  lark_ErrUserTokenNotFound = 120032,
  lark_ErrUserAccessTokenExpired = 120033,
  lark_ErrUserTokenVersionException = 120034,
  lark_ErrOrderNotFound = 120035,
  lark_ErrUpdateCardFailed = 120036,
  lark_Errlark_ErrLarkApiNotPermit = 120037,
  lark_Errlark_ErrLarkApiSyslark_Err = 120038,
  lark_ErrMissTenantKey = 120039,
  lark_ErrEventCallbackNotFound = 120040,
  lark_ErrDeptNotFound = 120041,
  //lark api return 10013、10014
  lark_ErrAppUnauthorized = 120042,
  //发消息频率超过频控限制 99991400 11232、11233
  lark_ErrAppMsgFrequencyLimit = 120043, // lark enum end

  // sns enum start

  // 108000 - 109000
  sns_ErrInvalidCommentLen = 108000, // 非法评论长度
  sns_ErrInvalidOp = 108001, // 非法操作    // sns enum end

  // oms enum start

  // range 64000 - 65000

  oms_ErrOrderDeliverieNotFound = 64000,
  oms_ErrMissedAppId = 64001,
  oms_ErrMissedUid = 64002,
  oms_ErrMissedOpenId = 64003,
  oms_ErrNotPermit = 64004,
  oms_ErrSkusEmpty = 64005,
  oms_ErrInvalidActivityType = 64007,
  oms_ErrCountCanNotBeZero = 64008,
  oms_ErrMissedPayDesc = 64009,
  oms_ErrOrderNotFound = 64010,
  oms_ErrOrderHasPaid = 64011,
  oms_ErrUpdateOrderConflict = 64012,
  oms_ErrMissedOrderId = 64013,
  oms_ErrRefundedFeeZero = 64014,
  oms_ErrOrderNotPaid = 64015,
  oms_ErrGroupBuyCanNotTogetherWithOther = 64016,
  oms_ErrCreateOutOrderFailed = 64017,
  oms_ErrSpuIdCanNotBeZero = 64018,
  oms_ErrSkuIdCanNotBeZero = 64019,
  oms_ErrQueryPostFeeFailed = 64020,
  oms_ErrQueryReturnInvalidFee = 64021,
  oms_ErrMissedReceiveAddress = 64022,
  oms_ErrOrderCanNotSaveOrder = 64023,
  oms_ErrCanNotChoiceMultipleCashCoupon = 64024,
  oms_ErrCommitOutOrderFailed = 64025,
  oms_ErrInvalidCashCouponId = 64026,
  oms_ErrCommitOrderReturnInvalidTotalFee = 64027,
  oms_ErrInvalidOrderState = 64028,
  oms_ErrInvalidActivitySkuId = 64029,
  oms_ErrInsufficientStock = 64030,
  oms_ErrInvalidAddressId = 64031,
  oms_ErrLockStockFail = 64032,
  oms_ErrLock2UnlockFail = 64033,
  oms_ErrLock2SaleFail = 64034,
  oms_ErrRollbackFail = 64035,
  oms_ErrGtMaxBuyCount = 64036,
  oms_ErrInvalidSkuId = 64037,
  oms_ErrMissingDeliverInfo = 64038, // oms enum end

  // wechatpay enum start

  // range 66000 - 67000

  wechatpay_ErrPaymentNotFound = 66001,
  wechatpay_ErrWechatTransfer = 66002,
  wechatpay_ErrWechatTransferNonRealName = 66003,
  wechatpay_ErrWxFreqLimit = 66004,
  wechatpay_ErrWxSystemwechatpay_Error = 66005,
  wechatpay_ErrWxAccountNoAuth = 66006,
  wechatpay_ErrMissedAppId = 66007,
  wechatpay_ErrConfirmedPayCanNotRefund = 66008,
  wechatpay_ErrNotPayCanNotRefund = 66009,
  wechatpay_ErrPayHasRefund = 66010,
  wechatpay_ErrRefundDescEmpty = 66011,
  wechatpay_ErrRefundAmountExceedTotal = 66012,
  wechatpay_ErrRefundApiwechatpay_Error = 66013,
  wechatpay_ErrPaymentRepeated = 66014, // wechatpay enum end

  // plugin enum start

  // 109000 - 110000
  plugin_ErrInvalidPluginName = 109000, // plugin enum end

  // delivery enum start

  delivery_ErrDeliveryNotFound = 239001,
  delivery_ErrExpressCompanyAccountNotFound = 239002,
  delivery_ErrExpressAppConfigNotFound = 239003,
  delivery_ErrMissOrderIdOrExpressNumber = 239004,
  delivery_ErrMissAppIdOrCorpId = 239005,
  delivery_ErrYTOdelivery_Errors = 239006, //圆通的各种错误返回,类型比较多，先统一命名。
  delivery_ErrExpressCompanyType = 239007, //错误的公司编号；如快递类型使用了外卖的公司标号
  delivery_ErrExpressCompanyAccountInvalid = 239008, //开发者账号信息有误
  delivery_ErrSFCitydelivery_Errors = 239009, //顺丰同城返回的错误
  delivery_ErrSfCityOrderId = 239010, //请确认你的顺丰同城订单号是否正确
  delivery_ErrExpressDupCreate = 239011,
  delivery_ErrExpressDupSet = 239012,
  delivery_ErrSfCityOrderOverweight = 239021, //物品重量超出上限
  delivery_ErrSfCityOrderOverDistance = 239022, //超出配送距离
  delivery_ErrSfCityOrderOverExceededMaximumAppointmentTime = 239023, //超过最大预约时间
  delivery_ErrSfCityOrderOutOfBizTime = 239024, //不在营业时间
  delivery_ErrSfCityOrderNotFound = 239025, //订单不存在
  delivery_ErrSfCityOrderAlreadyCreated = 239026, //订单已经创建过
  delivery_ErrSfCityOrderDetails = 239027, //订单缺少详情等信息    // delivery enum end

  // store enum start

  store_ErrProductNotFound = 236001,
  store_ErrProductSkuNotFound = 236002,
  store_ErrCategoryNotFound = 236003,
  store_ErrWrongSkuKey = 236004,
  store_ErrWrongSpecKey = 236005,
  store_ErrProductSpecNotFound = 236006, // store enum end

  // commonv2 enum start

  commonv2_ErrTagDupCreate = 124001, // 标签已存在
  commonv2_ErrTagNotFound = 124002, // 标签不存在
  commonv2_ErrTagDupSet = 124003, // 标签已存在
  commonv2_ErrCategoryDupCreate = 124004, // 分类已存在
  commonv2_ErrCategoryNotFound = 124005, // 分类不存在
  commonv2_ErrCategoryDupSet = 124006, // 分类已存在
  commonv2_ErrCategoryParentNotFound = 124007, // 没有找到父节点
  commonv2_ErrCategoryParentHasDisable = 124008, // 父节点被禁用
  commonv2_ErrCategoryInvalidParentId = 114005, // 无效的父id
  commonv2_ErrCategoryNotLeaf = 114008, // 该分类有子分类，不允许删除
  commonv2_ErrCategoryCanNotMove = 114009, // 不可以移动
  commonv2_ErrInvalidCategoryTree = 114010, // 非法分类树
  commonv2_ErrCategoryNameExisted = 114011, // 分类名已存在
  commonv2_ErrBannerDupCreate = 124009,
  commonv2_ErrBannerDupSet = 124010,
  commonv2_ErrBannerNotFound = 124011,
  commonv2_ErrAsyncTaskNotFound = 124012, // 没有找到异步任务
  commonv2_ErrAsyncTaskFailure = 124013, // 异步任务失败
  commonv2_ErrIdentityCertConfigNotFound = 124014, // 身份验证配置未找到
  commonv2_ErrUnsupportedTaskArgs = 124015, // 身份验证配置未找到
  commonv2_ErrRequestExternalInterfaceFailed = 124016, // 请求外部接口失败
  commonv2_ErrImageCheckAccountNotFound = 124017, // 图片账号不存在    // commonv2 enum end

  // stockmgr enum start

  // range 85000 - 86000

  stockmgr_ErrMissedAppId = 85000,
  stockmgr_ErrOpDuplicated = 85001,
  stockmgr_ErrDecTotalCountTooLarge = 85002,
  stockmgr_ErrStockNotEnough = 85003, // 库存不足
  stockmgr_ErrLockRecordNotFound = 85004,
  stockmgr_ErrUnknownOpType = 85005,
  stockmgr_ErrSaleRecordNotFound = 85006,
  stockmgr_ErrStockNotFound = 85007,
  stockmgr_ErrVersionException = 85008, // stockmgr enum end

  // shensuo enum start

  shensuo_ErrUserNotFound = 232001, // 用户不存在
  shensuo_ErrLessonScheduleNotFound = 232002, // 排课未找到
  shensuo_ErrLessonBookingNotFound = 232003, // 预约未找到
  shensuo_ErrLessonNotFound = 232004, // 课程未找到
  shensuo_ErrUserUpdateDistrictTimesLimit = 232005, // 超过用户最大修改地区次数
  shensuo_ErrInvalidInviteCode = 232006, // 无效的邀请码
  shensuo_ErrInvalidArgs = 232007, // 无效的参数
  shensuo_ErrSigningRegionDup = 232008, // 签约地区冲突
  shensuo_ErrLessonBookingDupCreate = 232009, // 课程预约冲突
  shensuo_ErrIncompleteUserInfo = 232011, // 客户信息不完整，请完善信息后添加。
  shensuo_ErrBannerNotFound = 232012, // 没有找到banner
  shensuo_ErrNotAvailableLessonPresented = 232013, // 没有可用的赠课
  shensuo_ErrAlreadyUseLessonPresentedToBookingLesson = 232014, // 已经使用过赠课预约
  shensuo_ErrAlreadyCancelLessonBooking = 232015, // 课程已取消过
  shensuo_ErrLessonPresentedNotFound = 232016, // 没有赠课
  shensuo_ErrNotFoundRefusalCause = 232017, // 没有填写拒绝理由
  shensuo_ErrGenInviteFailure = 232018, // 生成邀请码失败
  shensuo_ErrInvalidReferrer = 232019, // 无效的推荐人
  shensuo_ErrLessonBookingFailure = 232020, // 课程预约失败
  shensuo_ErrCantLessonBookingForNonSub = 232021, // 不可以给非自己下级预约课程
  shensuo_ErrLessonBookingCantCheckIn = 232022, // 课程未通过预约，不可以签到
  shensuo_ErrLessonBookingAlreadyCheckIn = 232023, // 已经签过到
  shensuo_ErrUsersNotFound = 232024, // 没有找到用户
  shensuo_ErrLessonScheduleAlreadyBegin = 232025, // 课程已经开始，无法修改
  shensuo_ErrProvinceRegionalMapNotFound = 232026, // 没有发现省份与地区的对应关联
  shensuo_ErrInvalidSmsType = 232027, // 无效的发送类型
  shensuo_ErrContentNotFound = 232028,
  shensuo_ErrDistrictNotFound = 232029,
  shensuo_ErrStartTimeEarlierThanEndTime = 232030, // 开始时间不早于开始时间
  shensuo_ErrLessonScheduleHasCancel = 232031, // 课程已被取消
  shensuo_ErrLessonScheduleHasBegin = 232032, // 课程已开始，不可以被预约
  shensuo_ErrLessonScheduleTopLimit = 232033, // 置顶超过了限制
  shensuo_ErrLessonBookingAlready = 232034, // 您已报名该课程
  shensuo_ErrLessonBookingAlreadyUser = 232035, // 客户已报名该课程
  shensuo_ErrLessonBookingCannotUseLessonPresented = 232036, // 不可以使用赠课预约
  shensuo_ErrInvalidDistrictTree = 232037, // 无效的地区树
  shensuo_ErrInvalidDistrictParent = 232038, // 无效的父节点
  shensuo_ErrCategoryDupCreate = 232039, //
  shensuo_ErrCategoryHasLeaf = 232040, // 还存在下级地区
  shensuo_ErrSendVerifyCodeFailure = 232041, // 发送验证码失败
  shensuo_ErrCheckInTimeExceeded = 232042, // 不在签到范围内
  shensuo_ErrDistrictCannotEdit = 232043, // 地区不可以编辑
  shensuo_ErrPermissionDenied = 232044, // 权限不足
  shensuo_ErrCannotDeleteOtherContent = 232045, // 不可以删除别人的文章
  shensuo_ErrTutorNotFound = 232046, // 导师不存在
  shensuo_ErrProxyNotSupport = 232047, // 尚不支持
  shensuo_ErrLessonScheduleApplicationStateNotFound = 232048, // 排课申请未找到
  shensuo_ErrLessonScheduleApplicationScheduleDone = 232049, // 已排课，不允许修改
  shensuo_ErrLessonScheduleApplicationIgnore = 232050, // 已忽略，不可以修改
  shensuo_ErrLessonBookingHistoryNotFound = 232051, // 历史上课记录没有找到
  shensuo_ErrProductRecommendNotFound = 232052,
  shensuo_ErrOnlineLessonNotFound = 232053, // 线上课程不存在
  shensuo_ErrMissedOpenId = 232054,
  shensuo_ErrOnlineLessonOrderNotFound = 232055, // 在线课程购买订单不存在
  shensuo_ErrUpdateOnlineLessonOrderConflict = 232056,
  shensuo_ErrOnlineLessonNeedNotPay = 232057, // 免费课程，不需要支付
  shensuo_ErrGenQrcodeFailed = 232058,
  shensuo_ErrOnlineLessonNotPay = 232059, // 课程还未支付
  shensuo_ErrSubOnlineLessonNotFound = 232060,
  shensuo_ErrSubOnlineLessonVideoNotFound = 232061,
  shensuo_ErrCheckStateNotFound = 232062,
  shensuo_ErrInvalidOrderState = 232063,
  shensuo_ErrWithdrawApplyNotFound = 232064,
  shensuo_ErrBalanceNotEnough = 232065, // 余额不足
  shensuo_ErrInvalidWithdrawApplyState = 232066, // shensuo enum end

  // omsv2 enum start

  // range 247000 - 248000

  omsv2_ErrOrderDupCreate = 247000,
  omsv2_ErrOrderNotFound = 247001,
  omsv2_ErrUpdateOrderConflict = 247002,
  omsv2_ErrInvalidOrderState = 247003,
  omsv2_ErrInvalidPayChannel = 247004,
  omsv2_ErrMissedOpenId = 247005,
  omsv2_ErrMissedPayDesc = 247006,
  omsv2_ErrRefundExceededPaid = 247007,
  omsv2_ErrMissedRefundDesc = 247008,
  omsv2_ErrRefundFailed = 247009,
  omsv2_ErrInvalidDelivery = 247010,
  omsv2_ErrOrderHasExpired = 247011,
  omsv2_ErrMissedAlipayUserId = 247012, // omsv2 enum end

  // alipay enum start

  // 11000 - 12000
  alipay_ErrAli = 11007, // 系统错误
  alipay_ErrNotFoundFormId = 11003,
  alipay_ErrAliAppNotFound = 11004,
  alipay_ErrAccessTokenNotFound = 11005,
  alipay_ErrNotFoundAliConf = 11006,
  alipay_ErrAppNotAuth = 11009, // 小程序未授权
  alipay_ErrMpComponentNotFound = 11010, // 第三方平台不存在
  alipay_ErrAppTypeNotSupported = 11011, // 应用类型不支持
  alipay_ErrAliAppConfigNotFound = 11012,
  alipay_ErrAccountNotFound = 11013,
  alipay_ErrPaymentRepeated = 11014,
  alipay_ErrPaymentNotFound = 11015, // 找不到支付订单
  alipay_ErrPaymentNotCorrect = 11016, // 支付金额不正确
  alipay_ErrInvalidRequest = 14001, // 参数错误
  alipay_ErrPhoneNotFound = 14002, // alipay enum end

  // admin enum start

  admin_ErrWrongPassword = 233000, // 帐号或密码错误
  admin_ErrAccountHasDisable = 233001, // 帐号已禁用
  admin_ErrPhoneExisted = 233002, // 手机号已存在
  admin_ErrEmailExisted = 233003, // 邮箱已存在
  admin_ErrCanNotDelSelf = 233004, // 不能删除自己
  admin_ErrCanNotDelSuperAdmin = 233005, // 不能删除超级管理员
  admin_ErrCanNotEditSuperAdmin = 233006, // 不能编辑超级管理员    // admin enum end

  // rbacv2 enum start

  // 18000 - 19000
  rbacv2_ErrRoleDupCreate = 18000,
  rbacv2_ErrMenuNotFound = 18001,
  rbacv2_ErrParentMenuNotFound = 18002,
  rbacv2_ErrInvalidMenuTreeNode = 18003,
  rbacv2_ErrCanNotDelNotLeafMenu = 18004,
  rbacv2_ErrRoleNotFound = 18005, // 角色不存在
  rbacv2_ErrNotPermit = 18006,
  rbacv2_ErrMenuNameConflict = 18007, // 菜单名称已存在
  rbacv2_ErrMenuPathActionConflict = 18008, // path+action已存在
  rbacv2_ErrMenuApiMapDupCreate = 18009 // rbacv2 enum end
}
