import _array from './_array';
import _date from './_date';
import _event from './_event';
import _string from './_string';
import _number from './_number';
import validate from './validate';
import uri from './uri';

export default {
  ..._array,
  ..._date,
  ..._string,
  ..._number,
  ..._event,
  ...uri,
  ...validate,

  deepClone<T extends any>(obj: T): T {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((v: T) => {
        if (typeof v === 'object' && v !== null) {
          return this.deepClone(v);
        } else {
          return v;
        }
      });
    } else {
      const newObj: any = {};

      Object.keys(obj).forEach(v => {
        if (typeof obj[v] === 'object' && obj[v] !== null) {
          newObj[v] = this.deepClone(obj[v]);
        } else {
          newObj[v] = obj[v];
        }
      });

      return newObj;
    }
  },

  convertToBoolean(input: string): boolean | undefined {
    try {
      return JSON.parse(input);
    } catch (e) {
      return undefined;
    }
  },

  /**
   * 解析浏览器 userAgent [key: client]: version
   * @param userAgent
   */
  parseBrowerVersion(userAgent: string = window.navigator.userAgent) {
    return userAgent
      .replace(/\(.+?\)/g, '')
      .split(' ')
      .filter(String)
      .reduce((acc: any, item) => {
        const v = item.split('/');
        v.length && (acc[v[0]] = v[1] || '');
        return acc;
      }, {});
  },

  /**
   * 对象key转换为小写
   * @param ob
   */
  lowerCaseObjectKey<T>(ob: T): T {
    const obj = {};
    for (const k in ob) {
      obj[k.toLowerCase()] = ob[k];
    }
    return obj as T;
  }
};
