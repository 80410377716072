import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { routerConfig, routerConfigMap } from '@/router/routing';
import { IRouterConfItem } from '@/router/lib/routers';
import { mergeRouterConfig } from '@/router/service';
import { IMenuModel } from '@/Layouts/BasicLayout/menu';
import t from 'libs/utils';

class AppStore {
  @persist @observable isMenuCollapsed = false;
  @observable routing: IRouterConfItem[] = [];

  @action
  toggleCollapsed = (value: boolean = this.isMenuCollapsed) => {
    this.isMenuCollapsed = !!!value;
  };

  @action
  updateRouter = (menuList: IMenuModel[]) => {
    this.routing = mergeRouterConfig(
      menuList,
      routerConfigMap,
      routerConfig.routers
    );
  };

  @computed get getRouterConfig() {
    const config = t.deepClone(routerConfig);
    if (this.routing.length) {
      config.routers = this.routing;
    }
    return config;
  }
}

const appStore = new AppStore();

export default appStore;

export { AppStore };
