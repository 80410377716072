import React from 'react';
import { Icon, Modal, Button, Popconfirm, Progress } from 'antd';
import Uploader, { IUploaderProps } from '../NewUploader';
import { Container, Draggable } from 'react-smooth-dnd';
import 'antd/lib/icon/style';
import styles from './index.module.scss';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

export interface IUploaderWithListProps extends IUploaderProps {
  isDrag?: boolean;
  maxLen?: number;
  onFileChange?: (info: UploadChangeParam<UploadFile>) => void;
}

interface IUploaderWithListState {
  previewVisible: boolean;
  previewDragVisible: boolean;
  previewImage: string;
  uploadFileList: UploadFile[];
  images_list: UploadFile[];
  previewImg: { url: string; idx: number };
  isFirst: boolean;
}

class UploaderWithList extends React.Component<IUploaderWithListProps> {
  static defaultProps: IUploaderWithListProps = {
    isDrag: false,
    maxLen: 5
  };
  state: IUploaderWithListState = {
    previewVisible: false,
    previewDragVisible: false,
    previewImage: '',
    uploadFileList: [],
    images_list: [],
    previewImg: { url: '', idx: 0 },
    isFirst: true
  };

  deleteItem: UploadFile[] = [];

  uploadButton = () =>
    this.props.listType && this.props.listType !== 'picture-card' ? (
      <Button>
        <Icon type="plus" />
        上传
      </Button>
    ) : (
      <div>
        <Icon type="plus" className={styles.uploadIcon} />
        <div className={styles.uploadText}>上传</div>
      </div>
    );

  handlePreview = (file: UploadFile) => {
    const { url: previewImage } = file;
    this.setState({ previewImage, previewVisible: true });
  };

  handleClosePreview = () => {
    this.setState({ previewVisible: false, previewDragVisible: false });
  };

  isOverMaxLen = () => {
    const { value, maxLen } = this.props;
    const { uploadFileList } = this.state;

    let result;
    if (Array.isArray(value)) {
      result =
        maxLen && (uploadFileList.length >= maxLen || value.length >= maxLen);
    } else {
      result =
        (uploadFileList.map(item => item.status).includes('uploading') ||
          value) &&
        maxLen;
    }
    return result;
  };

  componentDidUpdate() {
    this.handleClearUploadFileList();
    this.handleUpdateImageList();
  }

  handleClearUploadFileList = () => {
    // const { value } = this.props;
    const { uploadFileList } = this.state;
    const status = uploadFileList.map(item => item.status);

    !status.includes('uploading') &&
      uploadFileList.length &&
      this.setState({ uploadFileList: [] });
    // if (Array.isArray(value)) {
    //   !status.includes('uploading') && status.length === value.length && this.setState({uploadFileList: []})
    // } else {
    //   !status.includes('uploading') && value
    // }
  };

  handleUpdateImageList = () => {
    const { value, isDrag } = this.props;
    if (!isDrag) return;
    if (Array.isArray(value) && value.length > 0) {
      if (this.state.isFirst) {
        this.setState({
          images_list: value.map((item, idx) => ({
            uid: new Date().getTime() + '' + idx,
            url: item
          })),
          isFirst: false
        });
      }
    }
  };

  handleUploadFileListChange = (info: UploadChangeParam<UploadFile>) => {
    if (this.props.isDrag) {
      const { images_list } = this.state;
      info.fileList.forEach(item => {
        const index = this.deleteItem.findIndex((v: any) => v.url === item.url);
        if (index < 0) {
          const idx = this.state.images_list.findIndex(
            (v: UploadFile) => v.url === item.url
          );
          if (idx < 0) {
            const imageList = [...images_list, item];
            this.setState({
              images_list: imageList
            });
          } else {
            images_list.splice(idx, 1, item);
            this.setState({});
          }
          this.props.onFileChange && this.props.onFileChange(info);
        }
      });
    } else {
      this.setState({
        uploadFileList: info.fileList
      });
      this.props.onFileChange && this.props.onFileChange(info);
    }
  };

  applyDrag = (
    arr: any,
    dragResult: {
      removedIndex: number | null;
      addedIndex: number | null;
      payload: any;
    }
  ) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    this.props.onChange &&
      this.props.onChange(result.map((v: UploadFile) => v.url!));

    return result;
  };

  deleteImage = (idx: number) => {
    const deleteItem = this.state.images_list.splice(idx, 1);
    this.deleteItem.push(...deleteItem);
    this.setState({});
    this.props.onChange &&
      this.props.onChange(
        this.state.images_list.map((v: UploadFile) => v.url!)
      );
  };

  render() {
    const { children, value, isDrag, maxLen, ...options } = this.props;
    const {
      previewImage,
      previewVisible,
      previewDragVisible,
      images_list,
      previewImg
    } = this.state;

    return (
      <>
        {!isDrag ? (
          <>
            <Uploader
              value={value}
              accept="image/*"
              incorrectFileTypeTip="请上传正确格式的图片"
              listType="picture-card"
              onPreview={this.handlePreview}
              {...options}
              className="fire-uploader"
              onFileChange={this.handleUploadFileListChange}
            >
              {children || (this.isOverMaxLen() ? null : this.uploadButton())}
            </Uploader>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleClosePreview}
            >
              <img
                alt=""
                style={{ width: '100%', minHeight: '500px' }}
                src={previewImage}
              />
            </Modal>
          </>
        ) : (
          <div>
            <div style={{ width: '65vw', overflowX: 'auto' }}>
              <Container
                orientation="horizontal"
                onDrop={(e: any) => {
                  this.setState({
                    images_list: this.applyDrag(images_list, e)
                  });
                }}
              >
                {images_list.map((item: UploadFile, idx: number) => {
                  return (
                    <Draggable key={item.uid}>
                      <div
                        className={`${styles.main_container_lessonImage} draggable-item-horizontal`}
                      >
                        <img
                          className={styles.main_container_lessonImage_img}
                          src={item.url}
                          alt=""
                        />
                        <div
                          className={styles.main_container_lessonImage_loading}
                          style={{
                            opacity: item.status === 'uploading' ? 1 : 0
                          }}
                        >
                          <span
                            className={
                              styles.main_container_lessonImage_loading_text
                            }
                          >
                            文件上传中
                          </span>
                          <div
                            className={
                              styles.main_container_lessonImage_loading_progress
                            }
                          >
                            <Progress
                              type="line"
                              strokeWidth={2}
                              showInfo={false}
                              percent={item.percent}
                            />
                          </div>
                        </div>
                        <span className={styles.mask}>
                          <Icon
                            type="eye"
                            className={styles.mask_item}
                            onClick={() => {
                              this.setState({
                                previewDragVisible: true,
                                previewImg: { url: item.url, idx }
                              });
                            }}
                          />
                          <Popconfirm
                            title="确认删除该图片吗？"
                            onConfirm={this.deleteImage.bind(this, idx)}
                            onCancel={() => {}}
                            okText="是"
                            cancelText="否"
                          >
                            <Icon type="delete" className={styles.mask_item} />
                          </Popconfirm>
                        </span>
                      </div>
                    </Draggable>
                  );
                })}
              </Container>
            </div>

            {images_list.length < maxLen! && (
              <Uploader
                value={value}
                accept="image/*"
                showUploadList={false}
                listType="picture-card"
                {...options}
                onFileChange={this.handleUploadFileListChange}
              >
                {this.uploadButton()}
              </Uploader>
            )}
          </div>
        )}
        <Modal
          visible={previewDragVisible}
          footer={null}
          onCancel={this.handleClosePreview}
        >
          <img alt="" style={{ width: '100%' }} src={previewImg.url} />
        </Modal>
      </>
    );
  }
}

export default UploaderWithList;
