import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';
import CURDTable from '@/components/Schema/CURDTable';
import { toJS } from 'mobx';
import { message } from 'antd';
import { ShensuoServices } from 'services/apiServices.js';
import { Shensuo_GetOnlineLessonCommissionListAdminReq_ListOption } from 'services/enumTypes';
import t from '@/libs/utils';
import styles from './index.module.scss';

type IFetchListReq = shensuo.GetOnlineLessonCommissionListAdminReq;
type IFetchListRsq = shensuo.GetOnlineLessonCommissionListAdminRsp;
type IModel = shensuo.OnlineLessonCommission;

type SchemaState = {
  addDialog: boolean;
  curCommision: IModel;
};

const CommisionList: ICURDTableSchema<IModel, SchemaState, IFetchListRsq> = {
  header: {
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '推广人',
              type: 'input',
              placeholder: '请输入姓名或手机号',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName:
                Shensuo_GetOnlineLessonCommissionListAdminReq_ListOption.ListOptionUserNameOrPhone
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'uid',
    scroll: { x: 1400, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        dataType: 'btn-group',
        fixed: 'left',
        width: 200,
        renderBtnGroup(record: IModel) {
          return {
            needDivider: false,
            btns: [
              // {
              //   type: 'link',
              //   size: 'small',
              //   children: '发放佣金',
              //   onClick(this: SchemaCURDTable) {
              //     this.methods.toggleModelDialog();
              //     this.methods.setCommision(record);
              //   }
              // },
              {
                type: 'link',
                size: 'small',
                children: '佣金记录',
                proxyOnClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.props.history!.push({
                    pathname: '/storeManagement/comrecordlist',
                    search: '?id=' + record!.uid
                  });
                }
              }
            ]
          };
        }
      },
      {
        title: '推广人姓名',
        render(record: shensuo.OnlineLessonCommission) {
          return `${record.user_name}(${record.user_phone})`;
        }
      },
      {
        dataIndex: 'commission',
        title: '累积金额（元）',
        render(record: number) {
          return t.div(record, 100).toFixed(2);
        }
      },
      {
        dataIndex: 'invited_user_count',
        title: '邀请好友（人）',
        width: 200
      },
      {
        dataIndex: 'order_count',
        title: '累积订单（笔）',
        width: 200
      },
      {
        dataIndex: 'had_withdrawn',
        title: '已提现（元）',
        render(record: number) {
          console.log(record);
          return t.div(record, 100).toFixed(2);
        }
      },
      {
        dataIndex: 'frozen_commission',
        title: '冻结金额（元）',
        width: 200,
        render(record: number) {
          console.log(record);
          return t.div(record!, 100).toFixed(2);
        }
      },
      {
        title: '未提现（元）',
        render(record: shensuo.OnlineLessonCommission) {
          const no_withdrawn = record.commission
            ? record.commission - (record.had_withdrawn || 0)
            : 0;
          // console.log(no_withdrawn);
          return t.div(no_withdrawn, 100).toFixed(2);
        }
      }
    ],
    rowClassName: () => styles.row
  },
  observableData: {
    addDialog: false,
    curCommision: {}
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
      }
      this.loading = result.loading;
    },

    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },

    setCommision(v: shensuo.OnlineLessonCommission) {
      this.observableData.curCommision = v;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(
        ShensuoServices.GetOnlineLessonCommissionListAdmin
      );
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, curCommision } = toJS(observableData) as SchemaState;
    const no_withdrawn = curCommision.commission
      ? curCommision.commission - (curCommision.had_withdrawn || 0)
      : 0;
    return (
      <SchemaDialog
        schema={{
          visible: addDialog,
          title: '发放佣金',
          children: (
            <SchemaForm
              wrappedComponentRef={(ref: any) => {
                this.customNodeRef.addMenuDialog = ref;
              }}
              schema={{
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                labelAlign: 'right',
                rows: [
                  [
                    {
                      label: '金额',
                      propName: 'money',
                      type: 'number',
                      placeholder: '请输入金额',
                      componentProps: {
                        precision: 2
                      },
                      FieldDecoratorOptions: {
                        rules: [
                          {
                            required: true,
                            message: '金额不能为空, 且不能大于未提现金额'
                          }
                        ]
                      }
                    }
                  ]
                ]
              }}
            />
          ),
          onCancel: () => {
            this.methods.toggleModelDialog();
            this.customNodeRef.addMenuDialog.props.form.resetFields();
          },
          onOk: async () => {
            this.customNodeRef.addMenuDialog.props.form.validateFields(
              async (errors: Error[], values: any) => {
                if (!errors) {
                  console.log('value', values);
                  if (values.money <= 0) {
                    message.warning('发放佣金不能小于等于0');
                    return;
                  } else if (
                    values.money > +t.div(no_withdrawn, 100).toFixed(2)
                  ) {
                    message.warning('发放佣金不能大于未提现佣金');
                    return;
                  }
                  try {
                    await ShensuoServices.PayOnlineLessonCommission({
                      uid: curCommision.uid,
                      commission: t.mul(values.money, 100)
                    });
                    message.success('发放成功');
                    this.methods.toggleModelDialog();
                    this.customNodeRef.addMenuDialog.props.form.resetFields();
                    this.listService.refreshList();
                  } catch (error) {
                    console.log(error);
                    message.warning('发放失败');
                  }
                }
              }
            );
          }
        }}
      />
    );
  }
};

export default CommisionList;
