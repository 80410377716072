import { MediaType } from 'braft-editor';

import { StorageServices } from 'services/apiServices.js';

export const media: MediaType = {
  async uploadFn(param) {
    console.log(param);
    try {
      const { upload_url, ...resp } = await StorageServices.PreUpload({});

      const serverURL = '//' + upload_url!.replace(/(http:|https:)\/\//g, '');
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      fd.append('token', resp.token!);
      fd.append('key', resp.resource_key!);

      const successFn = (response: ProgressEvent<XMLHttpRequest>) => {
        // 假设服务端直接返回文件上传后的地址
        // 调用param.progress告知编辑器上传成功后的文件地址
        if (response.target!.status === 200) {
          // @ts-ignore
          param.success({
            url: ['http:/', resp!.domain!, resp!.resource_key!].join('/')
          });
        } else {
          param.error({
            msg: `[response.target!.status]:${response.target!.responseText}`
          });
        }
      };

      const progressFn = (event: ProgressEvent) => {
        // 调用param.progress告知编辑器当前的上传进度
        param.progress((event.loaded / event.total) * 100);
      };

      const errorFn = (response: ProgressEvent) => {
        // 调用param.progress告知编辑器上传发生了问题
        param.error({
          msg: 'unable to upload.'
        });
      };

      xhr.upload.addEventListener('progress', progressFn, false);
      xhr.addEventListener('load', successFn as any, false);
      xhr.addEventListener('error', errorFn, false);
      xhr.addEventListener('abort', errorFn, false);

      fd.append('file', param.file);
      xhr.open('POST', serverURL, true);
      xhr.send(fd);
    } catch (error) {
      param.error({
        msg: error
      });
    }
  }
};
