import { createHashHistory } from 'history';
import queryString from 'query-string';

const hashHistory = createHashHistory();

let currentPath = hashHistory.location.pathname;

hashHistory.listen(ev => {
  currentPath = ev.pathname;
});

const whiteList = ['/login$', '/404$'];

function handleToLogin(curPath: string = currentPath) {
  if (!whiteList.some(v => new RegExp(v).test(curPath))) {
    hashHistory.replace({
      pathname: '/login',
      search: (() => {
        const { query } = queryString.parseUrl(hashHistory.location.search);
        query['redirect_url'] = curPath;
        return `?${queryString.stringify(query)}`;
      })()
    });
    return Promise.reject();
  }
}

export { handleToLogin };
