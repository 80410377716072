import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import CURDTable from '@/components/Schema/CURDTable';
import { Icon, InputNumber } from 'antd';
import { ShensuoServices } from 'services/apiServices.js';
import {
  Shensuo_GetOnlineLessonListAdminReq_ListOption,
  Shensuo_ModelOnlineLesson_State
} from 'services/enumTypes';
import styles from './index.module.scss';

type IFetchListReq = shensuo.GetOnlineLessonListAdminReq;
type IFetchListRsq = shensuo.GetOnlineLessonListAdminRsp;
type IModel = shensuo.ModelOnlineLesson;

type SchemaState = {
  subOnlineLessonMap: { [key: number]: shensuo.SubOnlineLessonList };
};

const OnlineLessonList: ICURDTableSchema<IModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '新建课程',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          // @ts-ignore
          this.props.history.push({
            pathname: '/contentmanagement/onlineLessonAdd'
          });
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          console.log(this);
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '课程名称',
              type: 'input',
              placeholder: '请输入课程名称',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName:
                Shensuo_GetOnlineLessonListAdminReq_ListOption.ListOptionName
            },
            {
              label: '课程状态',
              type: 'select',
              placeholder: '请选择课程状态',
              componentProps: {
                style: {
                  width: 200
                }
              },
              selectOptions: [
                {
                  state: Shensuo_ModelOnlineLesson_State.StateNil,
                  status: '全部'
                },
                {
                  state: Shensuo_ModelOnlineLesson_State.StateOnShelves,
                  status: '上架'
                },
                {
                  state: Shensuo_ModelOnlineLesson_State.StateOffShelves,
                  status: '下架'
                }
              ],
              selectOption: {
                valueKey: 'state',
                labelKey: 'status'
              },
              propName:
                Shensuo_GetOnlineLessonListAdminReq_ListOption.ListOptionState
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    rowClassName: () => styles.row,
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        width: 220,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: IModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '查看',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.props.history.push({
                    pathname: '/contentmanagement/onlineLessonDetail',
                    search: '?id=' + record.id
                  });
                }
              },
              {
                type: 'link',
                size: 'small',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.props.history.push({
                    pathname: '/contentmanagement/onlineLessonEdit',
                    search: '?id=' + record.id
                  });
                }
              },
              {
                type: 'link',
                size: 'small',
                children:
                  record.state ===
                  Shensuo_ModelOnlineLesson_State.StateOffShelves
                    ? '上架'
                    : '下架',
                async onClick(this: SchemaCURDTable) {
                  let state = Shensuo_ModelOnlineLesson_State.StateNil;
                  if (
                    record.state ===
                    Shensuo_ModelOnlineLesson_State.StateOnShelves
                  ) {
                    state = Shensuo_ModelOnlineLesson_State.StateOffShelves;
                  } else if (
                    record.state ===
                    Shensuo_ModelOnlineLesson_State.StateOffShelves
                  ) {
                    state = Shensuo_ModelOnlineLesson_State.StateOnShelves;
                  }
                  const { subOnlineLessonMap } = this.observableData;

                  await ShensuoServices.SetOnlineLessonAdmin({
                    online_lesson: record!,
                    sub_online_lesson_list: subOnlineLessonMap[record!.id!]
                      ? subOnlineLessonMap[record!.id!].sub_online_lesson_list
                      : [],
                    state
                  });
                  try {
                    this.message.success(
                      `${
                        record.state ===
                        Shensuo_ModelOnlineLesson_State.StateOffShelves
                          ? '上架'
                          : '下架'
                      }成功`
                    );
                    this.listService.refreshList();
                  } catch (error) {}
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  async onConfirm(this: CURDTable) {
                    await ShensuoServices.DelOnlineLessonAdmin({
                      id: Number(record!.id)
                    });
                    try {
                      this.message.success('删除成功');
                      this.listService.refreshList();
                    } catch (error) {}
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      },
      {
        title: '排序',
        width: 220,
        render(this: SchemaCURDTable, record: shensuo.ModelOnlineLesson) {
          return (
            <InputNumber
              value={record.view_order}
              style={{ width: '150px' }}
              onChange={(e: number | undefined) => {
                if (this.table.dataSource) {
                  const index = this.table.dataSource.findIndex(
                    v => v.view_order === record.view_order
                  );
                  if (index !== -1) {
                    e = e
                      ? e > Math.pow(2, 32) - 1
                        ? Math.pow(2, 32) - 1
                        : e
                      : undefined;
                    this.methods.setOrderView(e, index);
                  }
                }
              }}
              onBlur={async () => {
                const _record = {
                  ...record!,
                  view_order: record.view_order || 0
                };
                const { subOnlineLessonMap } = this.observableData;
                await ShensuoServices.SetOnlineLessonAdmin({
                  online_lesson: _record!,
                  sub_online_lesson_list: subOnlineLessonMap[record!.id!]
                    ? subOnlineLessonMap[record!.id!].sub_online_lesson_list
                    : [],
                  state: 0
                });
                try {
                  this.message.success('修改成功');
                  this.listService.refreshList();
                } catch (error) {}
              }}
            />
          );
        }
      },
      {
        dataIndex: 'name',
        title: '课程名称',
        ellipsis: true
      },
      {
        dataIndex: 'created_at',
        title: '创建时间',
        dataType: 'date',
        ellipsis: true
      },
      {
        title: '包含子课程数量',
        render(this: SchemaCURDTable, record: IModel) {
          const { subOnlineLessonMap } = this.observableData;
          return subOnlineLessonMap[record.id!]
            ? subOnlineLessonMap[record.id!].sub_online_lesson_list.length
            : 0;
        }
      },
      {
        dataIndex: 'state',
        title: '状态',
        width: 100,
        ellipsis: true,
        render(record: number) {
          return record === 1 ? '上架' : '下架';
        }
      },
      {
        dataIndex: 'commission_ratio',
        title: '返佣比例',
        render(record: number) {
          return record + '%';
        }
      }
    ]
  },
  observableData: {
    subOnlineLessonMap: {}
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      // console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.subOnlineLessonMap = result.resp!.sub_online_lesson_map;
      }
      this.loading = result.loading;
    },

    setOrderView(v: number, idx: number) {
      this.table.dataSource![idx!].view_order = v;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(ShensuoServices.GetOnlineLessonListAdmin);
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  }
};

export default OnlineLessonList;
