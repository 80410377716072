import React from 'react';
import { toJS } from 'mobx';
import { message } from 'antd';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';
import CURDTable from '@/components/Schema/CURDTable';

import { StoreServices } from 'services/apiServices.js';
import {
  Shensuo_StoreType,
  Store_GetProductSkuListReq_ListOption
} from 'services/enumTypes';

type IFetchListReq = store.GetProductSkuListReq;
type IFetchListRsq = store.GetProductSkuListRsp;
type IProductModel = store.ModelProductSku;
type IAddModel = store.Stock;

type SchemaState = {
  addDialog: boolean;
  isAdd: boolean;
  info: { spu_id: number; sku_id: number; left_count: number };
  stock_map?: { [key: number]: stockmgr.ModelStock };
};

const InventoryList: ICURDTableSchema<
  IProductModel,
  SchemaState,
  IFetchListRsq
> = {
  header: {
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '商品名称',
              type: 'input',
              placeholder: '请输入商品名称',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName: Store_GetProductSkuListReq_ListOption.ListOptionName
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        width: 180,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: IProductModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '增加库存',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.methods.toggleModelDialog(true, record);
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                children: '减少库存',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.methods.toggleModelDialog(false, record);
                }
              }
            ]
          };
        }
      },
      {
        title: '商品名称',
        render(record: store.ModelProductSku) {
          return <span>{`${record.name}（${record.spec_value}）`}</span>;
        }
      },
      {
        title: '实物库存',
        width: 100,
        dataType: 'status',
        statusFn(this: SchemaCURDTable, record: store.ModelProductSku) {
          const { id } = record!;
          return this.observableData.stock_map[+id!]
            ? this.observableData.stock_map[+id!].total_count
            : '-';
        }
      },
      {
        title: '占用库存',
        width: 100,
        dataType: 'status',
        statusFn(this: SchemaCURDTable, record: store.ModelProductSku) {
          const { id } = record!;
          return this.observableData.stock_map[+id!]
            ? this.observableData.stock_map[+id!].lock_count
            : '-';
        }
      },
      {
        title: '可用库存',
        dataType: 'status',
        width: 100,
        statusFn(this: SchemaCURDTable, record: store.ModelProductSku) {
          const { id } = record!;
          const sale_count = this.observableData.stock_map[+id!]
            ? this.observableData.stock_map[+id!].sale_count
            : 0;
          const total_count = this.observableData.stock_map[+id!]
            ? this.observableData.stock_map[+id!].total_count
            : 0;
          const lock_count = this.observableData.stock_map[+id!]
            ? this.observableData.stock_map[+id!].lock_count
            : 0;
          const left_count = total_count - sale_count - lock_count;
          return left_count > 0 ? left_count : 0;
        }
      }
    ]
  },
  observableData: {
    addDialog: false,
    isAdd: false,
    stock_map: {},
    info: { spu_id: 0, sku_id: 0, left_count: 0 }
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.stock_map = result.resp!.stock_map;
      }
      this.loading = result.loading;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    toggleModelDialog(
      this: SchemaCURDTable,
      isAdd: boolean = false,
      record: IProductModel
    ) {
      this.observableData.isAdd = isAdd;
      this.observableData.addDialog = !this.observableData.addDialog;
      if (record && Object.keys(record).length > 0) {
        const sale_count = this.observableData.stock_map[+record.id!]
          ? this.observableData.stock_map[+record.id!].sale_count
          : 0;
        const total_count = this.observableData.stock_map[+record.id!]
          ? this.observableData.stock_map[+record.id!].total_count
          : 0;
        const lock_count = this.observableData.stock_map[+record.id!]
          ? this.observableData.stock_map[+record.id!].lock_count
          : 0;
        const left_count = total_count - sale_count - lock_count;
        this.observableData.info = {
          sku_id: record ? record.id : 0,
          spu_id: record ? record.product_id : 0,
          left_count
        };
      }
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(StoreServices.GetProductSkuList);
      this.listService.setReq<IFetchListReq>({
        list_option: {},
        store_id: Shensuo_StoreType.StoreTypeComm
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, isAdd, info } = toJS(observableData) as SchemaState;
    // TODO mount dialog service
    return (
      <SchemaDialog
        schema={{
          visible: addDialog,
          title: isAdd ? '增加库存' : '减少库存',
          children: (
            <SchemaForm
              wrappedComponentRef={(ref: any) => {
                this.customNodeRef.addMenuDialog = ref;
              }}
              schema={
                {
                  labelCol: { span: 4 },
                  wrapperCol: { span: 14 },
                  labelAlign: 'right',
                  rows: [
                    [
                      {
                        label: isAdd ? '增加数量' : '减少数量',
                        propName: 'stock',
                        type: 'number',
                        componentProps: {
                          autoFocus: true
                        },
                        placeholder: '请输入数量',
                        FieldDecoratorOptions: {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: '请输入数量'
                            }
                          ]
                        }
                      }
                    ]
                  ]
                } as IFormSchema<IAddModel>
              }
            />
          ),
          onCancel: () => {
            this.methods.toggleModelDialog();
            this.customNodeRef.addMenuDialog.props.form.resetFields();
          },
          onOk: async () => {
            this.customNodeRef.addMenuDialog.props.form.validateFields(
              async (errors: Error[], values: any) => {
                if (!errors) {
                  const { left_count, ...other } = info;
                  if (values.stock <= 0) {
                    message.warning('修改库存数量必须大于0');
                    return;
                  } else if (values.stock >= 9999999) {
                    message.warning('修改库存数量过大');
                    return;
                  }
                  let value = { ...other, ...values };
                  if (isAdd) {
                    await StoreServices.IncrStock({ stock: { ...value } });
                  } else {
                    if (values.stock > left_count) {
                      message.warning('减少库存数量大于可用库存！');
                      return;
                    }
                    await StoreServices.DecrStock({ stock: { ...value } });
                  }
                  message.success('新建成功');
                  this.methods.toggleModelDialog();
                  this.listService.refreshList();
                  this.customNodeRef.addMenuDialog.props.form.resetFields();
                }
              }
            );
          }
        }}
      />
    );
  }
};

export default InventoryList;
