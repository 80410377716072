import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';
import CURDTable from '@/components/Schema/CURDTable';
import { toJS } from 'mobx';
import { Icon, message } from 'antd';
import { ShensuoServices } from 'services/apiServices.js';
import {
  Shensuo_UserListOption,
  Shensuo_GetContentListAdminReq_ListOption
} from 'services/enumTypes';
import DeMethods from 'libs/utils/_event';
import styles from './index.module.scss';

type IFetchListReq = shensuo.GetContentListAdminReq;
type IFetchListRsq = shensuo.GetContentListAdminRsp;
type IDybamicModel = shensuo.ModelContent;
type IAddModel = shensuo.AddContentAdminReq;

type SchemaState = {
  addDialog: boolean;
  user_map?: { [key: string]: shensuo.ModelUser };
  content_like_map?: { [key: string]: number };
  content_comment_map?: { [key: string]: number };
  userList: shensuo.ModelUser[];
  mediaType: 'video' | 'pic';
  isFirst: boolean;
  videoLen: number;
  picLen: number;
};

const MessageList: ICURDTableSchema<
  IDybamicModel,
  SchemaState,
  IFetchListRsq
> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '发布动态',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          this.methods.toggleModelDialog();
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '发布人',
              type: 'input',
              placeholder: '请输入发布人',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName: Shensuo_GetContentListAdminReq_ListOption.ListOptionName
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    scroll: { x: 1400, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        dataType: 'btn-group',
        fixed: 'left',
        width: 170,
        renderBtnGroup(record: IDybamicModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '查看详情',
                onClick(this: SchemaCURDTable) {
                  this.props.history!.push({
                    pathname: '/contentmanagement/messageDetail',
                    search: '?id=' + record.id
                  });
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  async onConfirm(this: CURDTable) {
                    await ShensuoServices.DelContentAdmin({
                      id: record.id!
                    });
                    this.message.success('删除成功');
                    this.listService.refreshList();
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      },
      {
        dataIndex: 'created_at',
        title: '发布时间',
        dataType: 'date'
      },
      {
        dataIndex: 'uid',
        title: '发布人',
        dataType: 'status',
        statusFn(this: SchemaCURDTable, uid: string) {
          return this.observableData.user_map[uid]
            ? this.observableData.user_map[uid].name || '--'
            : '-';
        }
      },
      {
        title: '帖子主图',
        width: 200,
        render(record: shensuo.ModelContent) {
          const { head_image_list, video, video_cover } = record!;
          let src = video
            ? video_cover
            : head_image_list!.head_image_list
            ? head_image_list!.head_image_list[0]
            : '';
          return (
            <div className="thumbnail-list">
              <img src={src} alt="" />
            </div>
          );
        }
      },
      {
        dataIndex: 'body',
        title: '帖子正文',
        width: 300,
        render(this: SchemaCURDTable, body: string) {
          return <p className={styles.content_body}>{body ? body : '/'}</p>;
        }
      },
      {
        title: '点赞数',
        dataType: 'status',
        statusFn(this: SchemaCURDTable, record: shensuo.ModelContent) {
          let { id } = record!;
          return this.observableData.content_like_map[+id!]
            ? this.observableData.content_like_map[+id!]
            : this.observableData.content_like_map[+id!] === 0
            ? 0
            : '-';
        }
      },
      {
        title: '评论数',
        dataType: 'status',
        statusFn(this: SchemaCURDTable, record: shensuo.ModelContent) {
          let { id } = record!;
          return this.observableData.content_comment_map[+id!]
            ? this.observableData.content_comment_map[+id!]
            : this.observableData.content_comment_map[+id!] === 0
            ? 0
            : '-';
        }
      }
    ],
    rowClassName: () => styles.row
  },
  observableData: {
    addDialog: false,
    userList: [],
    user_map: {},
    content_like_map: {},
    content_comment_map: {},
    mediaType: 'video',
    isFirst: true,
    videoLen: 0,
    picLen: 0
  },
  methods: {
    toggleModelDialog(this: SchemaCURDTable) {
      this.observableData.addDialog = !this.observableData.addDialog;
    },
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.user_map = result.resp!.user_map;
        this.observableData.content_like_map = result.resp!.content_like_map;
        this.observableData.content_comment_map = result.resp!.content_comment_map;
      }
      this.loading = result.loading;
    },
    setUserList(
      list: (shensuo.ModelUser & { displayLabel: string | React.ReactNode })[]
    ) {
      this.observableData.userList = list!.map(v => {
        v.displayLabel = [v.name, v.phone].filter(String).join(' - ');
        return v;
      });
    },
    getUserList: DeMethods.debounce(async function(
      this: SchemaCURDTable,
      searchStr: string = ''
    ) {
      let { list } = await ShensuoServices.GetUserListAdmin({
        list_option: {
          options: [
            {
              type: Shensuo_UserListOption.UserListOptionCanBeSendContent,
              value: '1'
            },
            {
              type: Shensuo_UserListOption.UserListOptionName,
              value: searchStr.trim()
            }
          ]
        }
      });
      this.methods.setUserList(list!);
    },
    200),

    SET_MEDIA_TYPE(type: 'video' | 'pic', fileLen: number = 0) {
      this.observableData.mediaType = type;
      if (type === 'video') {
        this.observableData.videoLen = fileLen;
        this.observableData.picLen = 0;
      } else {
        this.observableData.videoLen = 0;
        this.observableData.picLen = fileLen;
      }
      this.methods.SET_ISFIRST(false);
    },

    SET_ISFIRST(val: boolean = false) {
      this.observableData.isFirst = val;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(ShensuoServices.GetContentListAdmin);
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const {
      methods: { getUserList, SET_MEDIA_TYPE },
      observableData
    } = this;
    const { addDialog, userList, mediaType, isFirst, videoLen, picLen } = toJS(
      observableData
    ) as SchemaState;
    console.log('pic', userList);

    return (
      <SchemaDialog
        schema={{
          visible: addDialog,
          title: '发布动态',
          children: (
            <SchemaForm
              wrappedComponentRef={(ref: any) => {
                this.customNodeRef.addMenuDialog = ref;
              }}
              schema={
                {
                  labelCol: { span: 4 },
                  wrapperCol: { span: 14 },
                  labelAlign: 'right',
                  rows: [
                    [
                      {
                        label: '发布人',
                        propName: 'uid',
                        type: 'select',
                        placeholder: '请输入发布人',
                        componentProps: {
                          showSearch: true,
                          onSearch: getUserList,
                          onFocus: getUserList
                        },
                        selectOptions: userList!,
                        selectOption: {
                          valueKey: 'id',
                          labelKey: 'displayLabel'
                        },
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required: true,
                              message: '请输入发布人'
                            }
                          ]
                        }
                      }
                    ],
                    [
                      {
                        label: '上传图片',
                        propName: '_head_image_list',
                        type: 'uploader-with-list',
                        componentProps: {
                          accept: 'image/*',
                          maxLen: 9,
                          onFileChange(files: any) {
                            const { fileList } = files!;
                            if (fileList.length > 0) {
                              SET_MEDIA_TYPE('pic', fileList.length);
                            }
                          },
                          onChange(files: any) {
                            if (files.length === 0) {
                              SET_MEDIA_TYPE('pic', 0);
                            }
                          }
                        },
                        style: {
                          display:
                            isFirst ||
                            mediaType === 'pic' ||
                            (videoLen === 0 && picLen === 0)
                              ? 'block'
                              : 'none'
                        },
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required:
                                (videoLen === 0 && picLen === 0) ||
                                mediaType === 'pic'
                                  ? true
                                  : false,
                              message: '请上传图片'
                            }
                          ]
                        }
                      }
                    ],
                    [
                      {
                        label: '上传视频',
                        propName: '_video',
                        type: 'uploader',
                        componentProps: {
                          accept: 'video/*',
                          maxLen: 1,
                          maxFileSize: 209715200 * 5,
                          listType: 'text',
                          onFileChange(files: any) {
                            const { fileList } = files!;
                            if (fileList.length > 0) {
                              SET_MEDIA_TYPE('video', fileList.length);
                            }
                          },
                          onChange(files: any) {
                            if (files.length === 0) {
                              SET_MEDIA_TYPE('video', 0);
                            }
                          }
                        },
                        style: {
                          display:
                            isFirst ||
                            mediaType === 'video' ||
                            (videoLen === 0 && picLen === 0)
                              ? 'block'
                              : 'none'
                        },
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required:
                                (videoLen === 0 && picLen === 0) ||
                                mediaType === 'video'
                                  ? true
                                  : false,
                              message: '请上传视频'
                            }
                          ]
                        }
                      }
                    ],
                    [
                      {
                        label: '正文',
                        propName: 'body',
                        type: 'text-area',
                        placeholder: '请输入正文',
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required: false,
                              message: '正文不能为空，且字数不能大于500',
                              max: 500
                            }
                          ]
                        }
                      }
                    ]
                  ]
                } as IFormSchema<IAddModel>
              }
            />
          ),
          onCancel: () => {
            this.methods.toggleModelDialog();
            this.methods.SET_ISFIRST(true);
            this.customNodeRef.addMenuDialog.props.form.resetFields();
          },
          onOk: async () => {
            this.customNodeRef.addMenuDialog.props.form.validateFields(
              async (errors: Error[], values: any) => {
                if (!errors) {
                  let { _head_image_list, _video, ...other } = values;
                  let head_image_list = {
                    head_image_list: _head_image_list || []
                  };
                  let video = _video ? _video[0] : '';
                  let _values = { ...other, head_image_list, video };
                  await ShensuoServices.AddContentAdmin({
                    ..._values
                  });
                  message.success('新建成功');
                  this.methods.toggleModelDialog();
                  this.methods.SET_ISFIRST(true);
                  this.customNodeRef.addMenuDialog.props.form.resetFields();
                  this.listService.refreshList();
                  SET_MEDIA_TYPE('video', 0);
                }
              }
            );
          }
        }}
      />
    );
  }
};

export default MessageList;
