import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import App from './App';
import * as serviceWorker from './serviceWorker';
import StoreHydration from 'components/StoreHydration';
import 'promise-polyfill/src/polyfill';

import stores from '@/store/index';

ReactDOM.render(
  <StoreHydration>
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  </StoreHydration>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
