/** code generate by generateapi don't edit */

/**
 * @type {(r: object, o?: object) => Promise<T>}
 */
let client

/**
 * @param {(r: object, o?: object) => Promise<T>}  c - http client.
 * @return {void}
 */
export function setClient(c) {
  if (typeof c === "function") {
    client = c
  }
}

function getClient(r, o) {
  if (o && o.client && typeof o.client === "function") {
    return o.client.call(this, r, o)
  }
  if (client && typeof client === "function") {
    return client.call(this, r, o)
  }
  return ((req, opt) =>
    console.warn("you must register a http client", req, opt)).call(this, r, o)
}

/**
 * @type {accountlogic.accountlogicService}
 */
export const AccountlogicServices = {
  Login(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/Login", pem: [], auth: "0" },
      ...o
    })
  },
  GetProfile(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/GetProfile", pem: [], auth: "1" },
      ...o
    })
  },
  SetProfile(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/SetProfile", pem: [], auth: "1" },
      ...o
    })
  },
  GetApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/GetApp", pem: [], auth: "1" },
      ...o
    })
  },
  SetApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/SetApp", pem: [], auth: "1" },
      ...o
    })
  },
  ListApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/ListApp", pem: [], auth: "1" },
      ...o
    })
  },
  SaasLogin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/SaasLogin", pem: [], auth: "0" },
      ...o
    })
  },
  ChangePassword(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/ChangePassword", pem: [], auth: "0" },
      ...o
    })
  },
  Logout(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/Logout", pem: [], auth: "0" },
      ...o
    })
  },
  PinLogin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/PinLogin", pem: [], auth: "1" },
      ...o
    })
  },
  DelProfilePhone(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/DelProfilePhone", pem: [], auth: "1" },
      ...o
    })
  },
  DelAccountProfile(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/accountlogic/DelAccountProfile", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {weblogic.weblogicService}
 */
export const WeblogicServices = {
  GetWxaCodeUnlimit(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/wechat/get_wxa_code_unlimit", pem: [], auth: "0" },
      ...o
    })
  },
  CreateWxaQrcode(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/wechat/create_wxa_qrcode", pem: [], auth: "0" },
      ...o
    })
  },
  EncodeWxaScene(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/EncodeWxaScene", pem: [], auth: "0" },
      ...o
    })
  },
  DecodeWxaScene(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/DecodeWxaScene", pem: [], auth: "0" },
      ...o
    })
  },
  ReportWxaFormId(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/ReportWxaFormId", pem: [], auth: "0" },
      ...o
    })
  },
  GetWxaCallback(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/wechat/callback/:app_id", pem: [], auth: "0" },
      ...o
    })
  },
  GetIp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/union/get_ip", pem: [], auth: "0" },
      ...o
    })
  },
  Upload(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/storage/upload", pem: [], auth: "0" },
      ...o
    })
  },
  PreUpload(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/PreUpload", pem: [], auth: "0" },
      ...o
    })
  },
  UploadCallBack(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/weblogic/UploadCallBack", pem: [], auth: "0" },
      ...o
    })
  },
  ReceiveOfficialAccountEvent(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/weblogic/wechat/official/callback/:appid",
        pem: [],
        auth: "0"
      },
      ...o
    })
  }
}

/**
 * @type {inneradmin.inneradminService}
 */
export const InneradminServices = {
  Login(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/Login", pem: [], auth: "0" },
      ...o
    })
  },
  WwLogin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/WwLogin", pem: [], auth: "0" },
      ...o
    })
  },
  WwLoginCallback(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/WwLoginCallback", pem: [], auth: "0" },
      ...o
    })
  },
  WwLoginByCode(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/WwLoginByCode", pem: [], auth: "0" },
      ...o
    })
  },
  Logout(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/Logout", pem: [], auth: "0" },
      ...o
    })
  },
  GetUserInfo(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetUserInfo", pem: [], auth: "1" },
      ...o
    })
  },
  ReviewCorp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/ReviewCorp", pem: [], auth: "1" },
      ...o
    })
  },
  GetModelList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetModelList", pem: [], auth: "1" },
      ...o
    })
  },
  AddCluster(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddCluster", pem: [], auth: "1" },
      ...o
    })
  },
  SetCluster(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetCluster", pem: [], auth: "1" },
      ...o
    })
  },
  DelCluster(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelCluster", pem: [], auth: "1" },
      ...o
    })
  },
  GetClusterList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetClusterList", pem: [], auth: "1" },
      ...o
    })
  },
  GetCluster(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetCluster", pem: [], auth: "1" },
      ...o
    })
  },
  SetClusterServer(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetClusterServer", pem: [], auth: "1" },
      ...o
    })
  },
  BatchDelClusterServer(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/BatchDelClusterServer", pem: [], auth: "1" },
      ...o
    })
  },
  AddSoftwareGroup(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddSoftwareGroup", pem: [], auth: "1" },
      ...o
    })
  },
  SetSoftwareGroup(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetSoftwareGroup", pem: [], auth: "1" },
      ...o
    })
  },
  DelSoftwareGroup(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelSoftwareGroup", pem: [], auth: "1" },
      ...o
    })
  },
  GetSoftwareGroup(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetSoftwareGroup", pem: [], auth: "1" },
      ...o
    })
  },
  GetSoftwareGroupList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetSoftwareGroupList", pem: [], auth: "1" },
      ...o
    })
  },
  SetSoftwareGroupUsedSoftwarePackage(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/inneradmin/SetSoftwareGroupUsedSoftwarePackage",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  SetSoftware(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetSoftware", pem: [], auth: "1" },
      ...o
    })
  },
  GetSoftwareList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetSoftwareList", pem: [], auth: "1" },
      ...o
    })
  },
  GetSoftwarePackageList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetSoftwarePackageList", pem: [], auth: "1" },
      ...o
    })
  },
  AddApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddApp", pem: [], auth: "1" },
      ...o
    })
  },
  SetApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetApp", pem: [], auth: "1" },
      ...o
    })
  },
  DelApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelApp", pem: [], auth: "1" },
      ...o
    })
  },
  GetApp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetApp", pem: [], auth: "1" },
      ...o
    })
  },
  GetAppList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAppList", pem: [], auth: "1" },
      ...o
    })
  },
  GetApiPathList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetApiPathList", pem: [], auth: "1" },
      ...o
    })
  },
  AddCorp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddCorp", pem: [], auth: "1" },
      ...o
    })
  },
  SetCorp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetCorp", pem: [], auth: "1" },
      ...o
    })
  },
  DelCorp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelCorp", pem: [], auth: "1" },
      ...o
    })
  },
  GetCorp(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetCorp", pem: [], auth: "1" },
      ...o
    })
  },
  GetCorpList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetCorpList", pem: [], auth: "1" },
      ...o
    })
  },
  AddStorageConfig(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddStorageConfig", pem: [], auth: "1" },
      ...o
    })
  },
  SetStorageConfig(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetStorageConfig", pem: [], auth: "1" },
      ...o
    })
  },
  DelStorageConfig(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelStorageConfig", pem: [], auth: "1" },
      ...o
    })
  },
  GetStorageConfig(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetStorageConfig", pem: [], auth: "1" },
      ...o
    })
  },
  GetStorageConfigList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetStorageConfigList", pem: [], auth: "1" },
      ...o
    })
  },
  AddAutoConfigType(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddAutoConfigType", pem: [], auth: "1" },
      ...o
    })
  },
  DelAutoConfigType(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelAutoConfigType", pem: [], auth: "1" },
      ...o
    })
  },
  GetAutoConfigTypeDetail(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAutoConfigTypeDetail", pem: [], auth: "1" },
      ...o
    })
  },
  SetAutoConfigType(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetAutoConfigType", pem: [], auth: "1" },
      ...o
    })
  },
  GetAutoConfigTypeList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAutoConfigTypeList", pem: [], auth: "1" },
      ...o
    })
  },
  AddAutoConfigData(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddAutoConfigData", pem: [], auth: "1" },
      ...o
    })
  },
  DelAutoConfigData(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelAutoConfigData", pem: [], auth: "1" },
      ...o
    })
  },
  SetAutoConfigData(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetAutoConfigData", pem: [], auth: "1" },
      ...o
    })
  },
  GetAutoConfigDataList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAutoConfigDataList", pem: [], auth: "1" },
      ...o
    })
  },
  SetFrontEndDepServer(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetFrontEndDepServer", pem: [], auth: "1" },
      ...o
    })
  },
  GetFrontEndDepServer(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetFrontEndDepServer", pem: [], auth: "0" },
      ...o
    })
  },
  SendRichTextMsgFromPinfireAssistant(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/inneradmin/SendRichTextMsgFromPinfireAssistant",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  GetStaffRpcList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetStaffRpcList", pem: [], auth: "1" },
      ...o
    })
  },
  CallStaffRpc(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/CallStaffRpc", pem: [], auth: "1" },
      ...o
    })
  },
  SyncMenuTree(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SyncMenuTree", pem: [], auth: "1" },
      ...o
    })
  },
  SearchOssReport(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SearchOssReport", pem: [], auth: "1" },
      ...o
    })
  },
  ExtraProtoSource(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/ExtraProtoSource", pem: [], auth: "1" },
      ...o
    })
  },
  AddMockFolder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddMockFolder", pem: [], auth: "1" },
      ...o
    })
  },
  DelMockFolder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelMockFolder", pem: [], auth: "1" },
      ...o
    })
  },
  GetMockFolderList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetMockFolderList", pem: [], auth: "1" },
      ...o
    })
  },
  SetMockFolder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetMockFolder", pem: [], auth: "1" },
      ...o
    })
  },
  AddMock(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddMock", pem: [], auth: "1" },
      ...o
    })
  },
  DelMock(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelMock", pem: [], auth: "1" },
      ...o
    })
  },
  GetMockList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetMockList", pem: [], auth: "1" },
      ...o
    })
  },
  SetMock(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetMock", pem: [], auth: "1" },
      ...o
    })
  },
  AddMockApi(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddMockApi", pem: [], auth: "1" },
      ...o
    })
  },
  DelMockApi(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelMockApi", pem: [], auth: "1" },
      ...o
    })
  },
  GetMockApiList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetMockApiList", pem: [], auth: "1" },
      ...o
    })
  },
  GetMockApi(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetMockApi", pem: [], auth: "1" },
      ...o
    })
  },
  SetMockApi(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetMockApi", pem: [], auth: "1" },
      ...o
    })
  },
  GetMockRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetMockRule", pem: [], auth: "1" },
      ...o
    })
  },
  SetMockRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetMockRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetAllMockRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAllMockRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetApiCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetApiCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  SetApiCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetApiCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetAllApiCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAllApiCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  SetFrontLogCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetFrontLogCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetFrontLogCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetFrontLogCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetAllFrontLogCaptureRule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAllFrontLogCaptureRule", pem: [], auth: "1" },
      ...o
    })
  },
  GetAccountList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetAccountList", pem: [], auth: "1" },
      ...o
    })
  },
  AddProject(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/AddProject", pem: [], auth: "1" },
      ...o
    })
  },
  GetProject(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetProject", pem: [], auth: "1" },
      ...o
    })
  },
  GetProjectList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetProjectList", pem: [], auth: "1" },
      ...o
    })
  },
  DelProject(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/DelProject", pem: [], auth: "1" },
      ...o
    })
  },
  SetProject(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetProject", pem: [], auth: "1" },
      ...o
    })
  },
  InitProject(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/InitProject", pem: [], auth: "1" },
      ...o
    })
  },
  SyncStaffFromLark(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SyncStaffFromLark", pem: [], auth: "1" },
      ...o
    })
  },
  GetProjectStaff(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetProjectStaff", pem: [], auth: "1" },
      ...o
    })
  },
  SetProjectStaff(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/SetProjectStaff", pem: [], auth: "1" },
      ...o
    })
  },
  CreateProjectLarkChat(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/CreateProjectLarkChat", pem: [], auth: "1" },
      ...o
    })
  },
  GetScanLoginUrlLark(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/GetScanLoginUrlLark", pem: [], auth: "0" },
      ...o
    })
  },
  ScanLoginLark(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/ScanLoginLark", pem: [], auth: "0" },
      ...o
    })
  },
  MpLoginLark(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/inneradmin/MpLoginLark", pem: [], auth: "0" },
      ...o
    })
  }
}

/**
 * @type {storage.storageService}
 */
export const StorageServices = {
  Upload(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/Upload", pem: [] },
      ...o
    })
  },
  PreUpload(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/PreUpload", pem: [], auth: "1" },
      ...o
    })
  },
  GetQiniuAvinfo(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/GetQiniuAvinfo", pem: [] },
      ...o
    })
  },
  QiniuPfop(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/QiniuPfop", pem: [] },
      ...o
    })
  },
  QiniuPfopV1(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/QiniuPfopV1", pem: [] },
      ...o
    })
  },
  GetResource(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/GetResource", pem: [] },
      ...o
    })
  },
  TCCloudCallback(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/TCCloudCallback", pem: [] },
      ...o
    })
  },
  GetPrivateUrl(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/GetPrivateUrl", pem: [] },
      ...o
    })
  },
  BatchGetUrl(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/BatchGetUrl", pem: [] },
      ...o
    })
  },
  QiniuCallback(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/QiniuCallback", pem: [], auth: "1" },
      ...o
    })
  },
  GetResourceList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/storage/GetResourceList", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {sns.snsService}
 */
export const SnsServices = {
  GetCommentList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/sns/GetCommentList", pem: [], auth: "0" },
      ...o
    })
  },
  GetCommentCount(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/sns/GetCommentCount", pem: [], auth: "0" },
      ...o
    })
  },
  GetCommentListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/sns/GetCommentListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  CommentOpAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/sns/CommentOpAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  BatchGetCommentCount(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/sns/BatchGetCommentCount", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {oms.omsService}
 */
export const OmsServices = {
  PayNotify(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/PayNotify", pem: [], auth: "1" },
      ...o
    })
  },
  CreateOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/CreateOrder", pem: [], auth: "1" },
      ...o
    })
  },
  PayOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/PayOrder", pem: [], auth: "1" },
      ...o
    })
  },
  GetOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/GetOrder", pem: [], auth: "1" },
      ...o
    })
  },
  RefundOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/RefundOrder", pem: [], auth: "1" },
      ...o
    })
  },
  SaveOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/SaveOrder", pem: [], auth: "1" },
      ...o
    })
  },
  GetOrderList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/GetOrderList", pem: [], auth: "1" },
      ...o
    })
  },
  CancelPayingOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/CancelPayingOrder", pem: [], auth: "1" },
      ...o
    })
  },
  SchedulerUnpaid2Canceled(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/SchedulerUnpaid2Canceled", pem: [], auth: "1" },
      ...o
    })
  },
  CancelOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/CancelOrder", pem: [], auth: "1" },
      ...o
    })
  },
  CancelOrderNotification(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/CancelOrderNotification", pem: [], auth: "1" },
      ...o
    })
  },
  DelOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/DelOrder", pem: [], auth: "1" },
      ...o
    })
  },
  FinishOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/FinishOrder", pem: [], auth: "1" },
      ...o
    })
  },
  GetOrderListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/GetOrderListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SaveOrderBySceneAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/SaveOrderBySceneAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetOrderAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/GetOrderAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SaveOrderAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/ss_feight/delivery/orderDelivery", pem: [], auth: "1" },
      ...o
    })
  },
  CancelPayingOrderAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/CancelPayingOrderAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SchedulerDelivering2Finished(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/oms/SchedulerDelivering2Finished", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {commonv2.commonv2Service}
 */
export const Commonv2Services = {
  AddTag(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/AddTag", pem: [], auth: "1" },
      ...o
    })
  },
  AddTagAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/AddTagAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SetTag(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/SetTag", pem: [], auth: "1" },
      ...o
    })
  },
  SetTagAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/SetTagAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetTag(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetTag", pem: [], auth: "1" },
      ...o
    })
  },
  GetTagList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetTagList", pem: [], auth: "1" },
      ...o
    })
  },
  GetTagListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetTagListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  DelTag(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/DelTag", pem: [], auth: "1" },
      ...o
    })
  },
  DelTagAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/DelTagAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  AddCategory(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/AddCategory", pem: [], auth: "1" },
      ...o
    })
  },
  GetCategory(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetCategory", pem: [], auth: "0" },
      ...o
    })
  },
  SetCategory(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/SetCategory", pem: [], auth: "1" },
      ...o
    })
  },
  GetCategoryList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetCategoryList", pem: [], auth: "0" },
      ...o
    })
  },
  DelCategory(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/DelCategory", pem: [], auth: "1" },
      ...o
    })
  },
  GetCategoryListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetCategoryListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  BatchGetCategoryList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/BatchGetCategoryList", pem: [], auth: "0" },
      ...o
    })
  },
  AddBannerAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/AddBannerAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SetBannerAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/SetBannerAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetBannerList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetBannerList", pem: [], auth: "0" },
      ...o
    })
  },
  GetBannerListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetBannerListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  DelBannerAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/DelBannerAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetAsyncTaskState(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/commonv2/GetAsyncTaskState", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {shensuo.shensuoService}
 */
export const ShensuoServices = {
  Login(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/Login", pem: [], auth: "0" },
      ...o
    })
  },
  SendVerifyCode(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SendVerifyCode", pem: [], auth: "0" },
      ...o
    })
  },
  SetUser(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetUser", pem: [], auth: "1" },
      ...o
    })
  },
  SetUserAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetUserAdmin", pem: ["user#edit"], auth: "1" },
      ...o
    })
  },
  GetUser(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetUser", pem: [], auth: "1" },
      ...o
    })
  },
  GetUserAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetUserAdmin", pem: ["user#get"], auth: "1" },
      ...o
    })
  },
  GetUserByInviteCode(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetUserByInviteCode", pem: [], auth: "1" },
      ...o
    })
  },
  GetTutorList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetTutorList", pem: [], auth: "0" },
      ...o
    })
  },
  GetSubUserList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubUserList", pem: [], auth: "1" },
      ...o
    })
  },
  GetUserListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetUserListAdmin", pem: ["user#list"], auth: "1" },
      ...o
    })
  },
  GetTutor(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetTutor", pem: [], auth: "0" },
      ...o
    })
  },
  AddLessonScheduleAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/AddLessonScheduleAdmin",
        pem: ["lesson_schedule#add"],
        auth: "1"
      },
      ...o
    })
  },
  SetLessonScheduleAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetLessonScheduleAdmin",
        pem: ["lesson_schedule#edit"],
        auth: "1"
      },
      ...o
    })
  },
  SetLessonScheduleOptionAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetLessonScheduleOptionAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonSchedule(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonSchedule", pem: [], auth: "0" },
      ...o
    })
  },
  GetLessonScheduleAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonScheduleAdmin",
        pem: ["lesson_schedule#list"],
        auth: "1"
      },
      ...o
    })
  },
  GetLessonScheduleList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonScheduleList", pem: [], auth: "0" },
      ...o
    })
  },
  GetLessonScheduleListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonScheduleListAdmin",
        pem: ["lesson_schedule#list"],
        auth: "1"
      },
      ...o
    })
  },
  AddLessonBooking(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddLessonBooking", pem: [], auth: "1" },
      ...o
    })
  },
  AddSubLessonBooking(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddSubLessonBooking", pem: [], auth: "1" },
      ...o
    })
  },
  AddLessonBookingAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddLessonBookingAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetMyLessonBookingList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetMyLessonBookingList", pem: [], auth: "1" },
      ...o
    })
  },
  GetSubLessonBookingList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubLessonBookingList", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonBookingListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonBookingListAdmin",
        pem: ["lesson_booking#list"],
        auth: "1"
      },
      ...o
    })
  },
  SetLessonBooking(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetLessonBooking", pem: [], auth: "1" },
      ...o
    })
  },
  SetSubLessonBooking(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetSubLessonBooking", pem: [], auth: "1" },
      ...o
    })
  },
  SetLessonBookingAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetLessonBookingAdmin",
        pem: ["lesson_booking#edit"],
        auth: "1"
      },
      ...o
    })
  },
  CancelLessonBookingListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/CancelLessonBookingListAdmin",
        pem: ["lesson_booking#edit"],
        auth: "1"
      },
      ...o
    })
  },
  AddLessonAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddLessonAdmin", pem: ["lesson#add"], auth: "1" },
      ...o
    })
  },
  SetLessonListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetLessonListAdmin",
        pem: ["lesson#edit"],
        auth: "1"
      },
      ...o
    })
  },
  GetLessonList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonList", pem: [], auth: "0" },
      ...o
    })
  },
  GetLessonListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonListAdmin",
        pem: ["lesson#list"],
        auth: "1"
      },
      ...o
    })
  },
  AddBannerAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddBannerAdmin", pem: ["banner#add"], auth: "1" },
      ...o
    })
  },
  SetBannerAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetBannerAdmin", pem: ["banner#edit"], auth: "1" },
      ...o
    })
  },
  GetBannerList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetBannerList", pem: [], auth: "0" },
      ...o
    })
  },
  GetBannerListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetBannerListAdmin",
        pem: ["banner#list"],
        auth: "1"
      },
      ...o
    })
  },
  DelBannerListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelBannerListAdmin", pem: ["banner#del"], auth: "1" },
      ...o
    })
  },
  AddLessonPresentedAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/AddLessonPresentedAdmin",
        pem: ["lesson_presented#add"],
        auth: "1"
      },
      ...o
    })
  },
  GetLessonPresentedRecodeList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonPresentedRecodeList", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonPresentedList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonPresentedList", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonPresentedListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonPresentedListAdmin",
        pem: ["lesson_presented#list"],
        auth: "1"
      },
      ...o
    })
  },
  GetTopCityList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetTopCityList", pem: [], auth: "0" },
      ...o
    })
  },
  GetLessonCommissionList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonCommissionList", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonCommissionListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetLessonCommissionListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonCommissionRecodeListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonCommissionRecodeListAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  AddContent(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddContent", pem: [], auth: "1" },
      ...o
    })
  },
  AddContentAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddContentAdmin", pem: ["content#add"], auth: "1" },
      ...o
    })
  },
  GetContentList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetContentList", pem: [], auth: "0" },
      ...o
    })
  },
  GetMyContentList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetMyContentList", pem: [], auth: "1" },
      ...o
    })
  },
  GetContentListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetContentListAdmin",
        pem: ["content#list"],
        auth: "1"
      },
      ...o
    })
  },
  GetContent(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetContent", pem: [], auth: "0" },
      ...o
    })
  },
  GetContentAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetContentAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  DelContent(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelContent", pem: [], auth: "1" },
      ...o
    })
  },
  DelContentAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelContentAdmin", pem: ["content#del"], auth: "1" },
      ...o
    })
  },
  AddContentComment(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddContentComment", pem: [], auth: "1" },
      ...o
    })
  },
  DelContentCommentAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/DelContentCommentAdmin",
        pem: ["content_comment#del"],
        auth: "1"
      },
      ...o
    })
  },
  AddContentLike(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddContentLike", pem: [], auth: "1" },
      ...o
    })
  },
  DelContentLike(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelContentLike", pem: [], auth: "1" },
      ...o
    })
  },
  AddDistrictAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddDistrictAdmin", pem: ["district#add"], auth: "1" },
      ...o
    })
  },
  SetDistrictAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetDistrictAdmin",
        pem: ["district#edit"],
        auth: "1"
      },
      ...o
    })
  },
  GetDistrictList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetDistrictList", pem: [], auth: "1" },
      ...o
    })
  },
  GetDistrictListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetDistrictListAdmin",
        pem: ["district#list"],
        auth: "1"
      },
      ...o
    })
  },
  DelDistrictAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelDistrictAdmin", pem: ["district#del"], auth: "1" },
      ...o
    })
  },
  QiniuTranscodeCallback(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/QiniuTranscodeCallback", pem: [], auth: "0" },
      ...o
    })
  },
  WxLogin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/WxLogin", pem: [], auth: "1" },
      ...o
    })
  },
  JsConfig(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/JsConfig", pem: [], auth: "0" },
      ...o
    })
  },
  ProxyRequest(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/ProxyRequest", pem: [], auth: "0" },
      ...o
    })
  },
  AddLessonScheduleApplication(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddLessonScheduleApplication", pem: [], auth: "1" },
      ...o
    })
  },
  SetLessonScheduleApplicationStateAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetLessonScheduleApplicationStateAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  GetLessonScheduleApplicationList(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonScheduleApplicationList",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  GetLessonScheduleApplicationListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonScheduleApplicationListAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  AddLessonBookingHistoryAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddLessonBookingHistoryAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetSubLessonBookingHistoryList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubLessonBookingHistoryList", pem: [], auth: "1" },
      ...o
    })
  },
  GetLessonBookingHistoryListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetLessonBookingHistoryListAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  AddProductRecommendAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddProductRecommendAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SetProductRecommendAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetProductRecommendAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetProductRecommendAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetProductRecommendAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetProductRecommendList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetProductRecommendList", pem: [], auth: "0" },
      ...o
    })
  },
  GetProductRecommendListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetProductRecommendListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  DelProductRecommendAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelProductRecommendAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SetProductRecommendViewOrderAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/SetProductRecommendViewOrderAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  CreateOnlineLessonOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/CreateOnlineLessonOrder", pem: [], auth: "1" },
      ...o
    })
  },
  PayOnlineLessonOrder(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/PayOnlineLessonOrder", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLessonCommissionList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonCommissionList", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLessonCommissionListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/shensuo/GetOnlineLessonCommissionListAdmin",
        pem: [],
        auth: "1"
      },
      ...o
    })
  },
  PayOnlineLessonCommission(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/PayOnlineLessonCommission", pem: [], auth: "1" },
      ...o
    })
  },
  AddOnlineLessonAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddOnlineLessonAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  SetOnlineLessonAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetOnlineLessonAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLesson(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLesson", pem: [], auth: "0" },
      ...o
    })
  },
  GetOnlineLessonAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLessonList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonList", pem: [], auth: "0" },
      ...o
    })
  },
  GetOnlineLessonListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  DelOnlineLessonAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/DelOnlineLessonAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLessonOrderListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonOrderListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetOnlineLessonOrderAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetOnlineLessonOrderAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GenQRCode(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GenQRCode", pem: [], auth: "0" },
      ...o
    })
  },
  GetSubOnlineLesson(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubOnlineLesson", pem: [], auth: "1" },
      ...o
    })
  },
  GetSubOnlineLessonVideoAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubOnlineLessonVideoAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  CheckState(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/CheckState", pem: [], auth: "1" },
      ...o
    })
  },
  GetSubOnlineLessonOrderList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetSubOnlineLessonOrderList", pem: [], auth: "1" },
      ...o
    })
  },
  GetWithdrawApplyListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetWithdrawApplyListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  AddWithdrawApply(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/AddWithdrawApply", pem: [], auth: "1" },
      ...o
    })
  },
  SetWithdrawApplyAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/SetWithdrawApplyAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  GetWithdrawApplyList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetWithdrawApplyList", pem: [], auth: "1" },
      ...o
    })
  },
  GetWithdrawRecordListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/GetWithdrawRecordListAdmin", pem: [], auth: "1" },
      ...o
    })
  },
  ExportOnlineLessonOrderList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/ExportOnlineLessonOrderList", pem: [], auth: "1" },
      ...o
    })
  },
  ExportOnlineStoreOrderList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/shensuo/ExportOnlineStoreOrderList", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {admin.adminService}
 */
export const AdminServices = {
  Login(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/Login", pem: [], auth: "0" },
      ...o
    })
  },
  AddAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/AddAdmin", pem: ["admin#edit"], auth: "1" },
      ...o
    })
  },
  DelAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/DelAdmin", pem: ["admin#edit"], auth: "1" },
      ...o
    })
  },
  SetAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/SetAdmin", pem: ["admin#edit"], auth: "1" },
      ...o
    })
  },
  GetAdminList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/GetAdminList", pem: ["admin#list"], auth: "1" },
      ...o
    })
  },
  GetUserListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/GetUserListAdmin", pem: ["user#list"], auth: "1" },
      ...o
    })
  },
  DisableUserAdmin(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/admin/DisableUserAdmin", pem: ["user#edit"], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {store.storeService}
 */
export const StoreServices = {
  AddProduct(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/AddProduct", pem: ["store/product#add"], auth: "1" },
      ...o
    })
  },
  selectFeightTemplateTables(r, o) {
    return getClient.call(this, r, {
      ...{ url:"/ss_feight/feightTemplate/selectFeightTemplateTable", pem: [], auth:"1"},
      ...o
    })
  },
  getFeightTemplateById(r, o) {
    return getClient.call(this, r, {
      ...{ url:"/ss_feight/feightTemplate/getFeightTemplateById", pem: [], auth:"1"},
      ...o
    })
  },
  optionFeightTemplate(r, o) {
    return getClient.call(this, r, {
      ...{ url:"/ss_feight/feightTemplate/optionFeightTemplate", pem: [], auth:"1"},
      ...o
    })
  },
  getAllRegion(r, o) {
    return getClient.call(this, r, {
      ...{ url:"/ss_feight/geographicalRegions/getAllRegion", pem: [], auth:"1"},
      ...o
    })
  },
  getTemplate(r,o) {
    return getClient.call(this,r,{
      ...{ url:"/ss_feight/feightTemplate/selectFeightTemplateList", pem: [], auth:"1"},
      ...o
    })
  },
  optionFeight(r,o) {
    return getClient.call(this,r,{
      ...{ url:"/ss_feight/productFeight/optionFeight", pem: [], auth:"1"},
      ...o
    })
  },
  getOptionFeight(r,o) {
    return getClient.call(this,r,{
      ...{ url:"ss_feight/productFeight/getOptionFeight", pem: [], auth:"1"},
      ...o
    })
  },
  getDictionaryExpressTable(r,o) {
    return getClient.call(this,r,{
      ...{ url:"/ss_feight/delivery/getDictionaryExpressTable", pem: [], auth:"1"},
      ...o
    })
  },
  SetProduct(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/SetProduct", pem: ["store/product#edit"], auth: "1" },
      ...o
    })
  },
  GetProduct(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetProduct", pem: [], auth: "0" },
      ...o
    })
  },
  GetProductAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/store/GetProductAdmin",
        pem: ["store/product#get"],
        auth: "1"
      },
      ...o
    })
  },
  GetProductListAdmin(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/store/GetProductListAdmin",
        pem: ["store/product#list"],
        auth: "1"
      },
      ...o
    })
  },
  GetProductList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetProductList", pem: [], auth: "0" },
      ...o
    })
  },
  DelProduct(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/DelProduct", pem: ["store/product#del"], auth: "1" },
      ...o
    })
  },
  DelFeight(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/ss_feight/feightTemplate/deleteFeightTemplateById", pem: ["store/product#del"], auth: "1" },
      ...o
    })
  },
  SetCategoryProduct(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/store/SetCategoryProduct",
        pem: ["store/product#edit"],
        auth: "1"
      },
      ...o
    })
  },
  GetProductListByCategories(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetProductListByCategories", pem: [], auth: "0" },
      ...o
    })
  },
  GetProductListByIds(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetProductListByIds", pem: [], auth: "0" },
      ...o
    })
  },
  GetSkuListByIds(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetSkuListByIds", pem: [], auth: "1" },
      ...o
    })
  },
  GetProductSkuList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetProductSkuList", pem: [], auth: "0" },
      ...o
    })
  },
  IncrStock(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/IncrStock", pem: [], auth: "1" },
      ...o
    })
  },
  DecrStock(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/DecrStock", pem: [], auth: "1" },
      ...o
    })
  },
  SetCart(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/SetCart", pem: [], auth: "1" },
      ...o
    })
  },
  GetCart(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/GetCart", pem: [], auth: "1" },
      ...o
    })
  },
  DelCart(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/DelCart", pem: [], auth: "1" },
      ...o
    })
  },
  SetProductSoldOutByIds(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/SetProductSoldOutByIds", pem: [], auth: "1" },
      ...o
    })
  },
  AddCart(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/store/AddCart", pem: [], auth: "1" },
      ...o
    })
  }
}

/**
 * @type {rbacv2.rbacv2Service}
 */
export const Rbacv2Services = {
  AddRole(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/AddRole", pem: ["rbac/role#edit"], auth: "1" },
      ...o
    })
  },
  DelRole(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/DelRole", pem: ["rbac/role#edit"], auth: "1" },
      ...o
    })
  },
  SetRole(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/SetRole", pem: ["rbac/role#edit"], auth: "1" },
      ...o
    })
  },
  GetRoleList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/GetRoleList", pem: ["rbac/role#list"], auth: "1" },
      ...o
    })
  },
  AddMenu(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/AddMenu", pem: [], auth: "1" },
      ...o
    })
  },
  SetMenu(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/SetMenu", pem: [], auth: "1" },
      ...o
    })
  },
  DelMenu(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/DelMenu", pem: [], auth: "1" },
      ...o
    })
  },
  GetMenuTree(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/GetMenuTree", pem: [], auth: "1" },
      ...o
    })
  },
  GetRoleUserList(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/rbacv2/GetRoleUserList",
        pem: ["rbac/role#list_user"],
        auth: "1"
      },
      ...o
    })
  },
  AddUsersToRole(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/rbacv2/AddUsersToRole",
        pem: ["rbac/role#edit_user"],
        auth: "1"
      },
      ...o
    })
  },
  DelUsersFromRole(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/rbacv2/DelUsersFromRole",
        pem: ["rbac/role#edit_user"],
        auth: "1"
      },
      ...o
    })
  },
  SetUserRoles(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/rbacv2/SetUserRoles",
        pem: ["rbac/role#edit_user"],
        auth: "1"
      },
      ...o
    })
  },
  SetRolePerm(r, o) {
    return getClient.call(this, r, {
      ...{
        url: "/rbacv2/SetRolePerm",
        pem: ["rbac/role#edit_perm"],
        auth: "1"
      },
      ...o
    })
  },
  GetRolePerm(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/GetRolePerm", pem: [], auth: "1" },
      ...o
    })
  },
  AddRoleInternal(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/AddRoleInternal", pem: [], auth: "1" },
      ...o
    })
  },
  GetRoleListInternal(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/GetRoleListInternal", pem: [], auth: "1" },
      ...o
    })
  },
  AddMenuApiMap(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/AddMenuApiMap", pem: [], auth: "1" },
      ...o
    })
  },
  GetMenuApiMapList(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/GetMenuApiMapList", pem: [], auth: "1" },
      ...o
    })
  },
  DelMenuApiMap(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/DelMenuApiMap", pem: [], auth: "1" },
      ...o
    })
  },
  ReplaceMenuApiMap(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/ReplaceMenuApiMap", pem: [], auth: "1" },
      ...o
    })
  },
  CheckPerm(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/CheckPerm", pem: [], auth: "1" },
      ...o
    })
  },
  AddRoleWithPerm(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/AddRoleWithPerm", pem: [], auth: "1" },
      ...o
    })
  },
  SetRoleWithPerm(r, o) {
    return getClient.call(this, r, {
      ...{ url: "/rbacv2/SetRoleWithPerm", pem: [], auth: "1" },
      ...o
    })
  }
}
