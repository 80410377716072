import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';

import CURDTable from '@/components/Schema/CURDTable';
import { Icon, message } from 'antd';
import { ShensuoServices } from 'services/apiServices.js';
import styles from './index.module.scss';

type IFetchListReq = shensuo.GetProductRecommendListAdminReq;
type IFetchListRsq = shensuo.GetProductRecommendListAdminRsp;
type IProductModel = shensuo.ModelProductRecommend;

type SchemaState = {
  product_map: { [key: number]: store.ModelProduct };
};

const GoodsList: ICURDTableSchema<IProductModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '新建推荐位',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          // @ts-ignore
          this.props.history.push({
            pathname: '/storeManagement/recommendAdd'
          });
        }
      }
    ]
  },
  table: {
    rowKey: 'id',
    rowClassName: () => styles.row,
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    onRow(this: SchemaCURDTable, record, index) {
      return {
        index,
        moveRow: this.methods.moveRow
      };
    },
    columns: [
      {
        title: '操作',
        width: 180,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: IProductModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.props.history.push({
                    pathname: '/storeManagement/recommendEdit',
                    search: '?id=' + record.id
                  });
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  async onConfirm(this: CURDTable) {
                    if (this.table.dataSource!.length === 1) {
                      message.warning('至少保留一条推荐位');
                      return;
                    }
                    await ShensuoServices.DelProductRecommendAdmin({
                      id: Number(record!.id)
                    });
                    try {
                      this.message.success('删除成功');
                      this.listService.refreshList();
                    } catch (error) {}
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      },
      // {
      //   dataIndex: 'view_order',
      //   title: '排序',
      //   width: 120
      // },
      {
        dataIndex: 'name',
        title: '推荐位名称',
        ellipsis: true,
        width: 200
      },
      {
        dataIndex: 'primary_img',
        title: '主推商品',
        width: 250,
        render(src: string) {
          return (
            <div className="thumbnail-list">
              <img src={src} alt="" />
            </div>
          );
        }
      },
      {
        dataIndex: 'sub_recommend_product_list',
        title: '副推荐位商品',
        ellipsis: true,
        // width: 300
        render(
          this: SchemaCURDTable,
          list: shensuo.ModelProductRecommend_RecommendProductList
        ) {
          return (
            <div className="thumbnail-lists">
              {list.recommend_product_list!.map(v => {
                const src = this.observableData.product_map[v.product_id]
                  ? this.observableData.product_map[v.product_id].main_image
                  : '';
                return (
                  <div className="thumbnail-list" key={src}>
                    <img src={src} alt="" />
                  </div>
                );
              })}
            </div>
          );
        }
      },
      {
        dataIndex: 'jump_content',
        title: '更多推荐跳转',
        width: 200,
        ellipsis: true
      }
    ]
  },
  observableData: {
    product_map: {}
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.product_map = result.resp!.product_map;
      }
      this.loading = result.loading;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    async moveRow(
      this: SchemaCURDTable,
      dragIndex: number,
      hoverIndex: number
    ) {
      let { dataSource } = this.table;
      const o = dataSource![dragIndex].view_order;
      dataSource![dragIndex].view_order = dataSource![hoverIndex].view_order;
      dataSource![hoverIndex].view_order = o;
      await ShensuoServices.SetProductRecommendViewOrderAdmin({
        list: dataSource
      });
      this.listService.refreshList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(
        ShensuoServices.GetProductRecommendListAdmin
      );
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  }
};

export default GoodsList;
