export default {
  /**
   * 将数组按照chunklen大小进行分段
   * @param sourceArr Array
   * @param chunkLen number
   */
  chunkArray<T>(sourceArr: T[], chunkLen: number): T[] {
    const res: any[] = [];

    for (let i = 0, len = sourceArr.length; i < len; i += chunkLen) {
      res.push(sourceArr.slice(i, i + chunkLen));
    }

    return res;
  },

  unique<T>(...arr: T[]) {
    return [...new Set(arr.flat(Infinity))];
  },

  // 交集
  intersect<T>(arr1: T[], arr2: T[]) {
    return this.unique(arr1).filter(v => arr2.includes(v));
  },

  // 差集
  minus<T>(arr1: T[], arr2: T[]) {
    return this.unique(arr1).filter(v => !arr2.includes(v));
  },

  // 并集
  union<T>(arr1: T[], arr2: T[]) {
    return this.unique(arr1, arr2);
  },

  arrayMoveMutate<T>(array: T[], from: number, to: number) {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  },

  arrayMove<T>(array: T[], from: number, to: number): T[] {
    array = array.slice();
    this.arrayMoveMutate(array, from, to);
    return array;
  }
};
