import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import CURDTable from '@/components/Schema/CURDTable';
import SchemaDialog from '@/components/Schema/Dialog';
import SchemaForm, { IFormSchema, Form } from '@/components/Schema/Form';
import { Icon, message } from 'antd';
import { StoreServices, Commonv2Services } from 'services/apiServices.js';
// import { Rbacv2Services } from '@/services/apiServices.js';
import {
  Shensuo_StoreType,
  Store_GetProductListAdminReq_ListOption,
  Shensuo_CategoryType
} from 'services/enumTypes';
import styles from './index.module.scss';
import { toJS } from 'mobx';

type IFetchListReq = store.GetProductListAdminReq;
type IFetchListRsq = store.GetProductListAdminRsp;
type IProductModel = store.ModelProduct;
type IModel = admin.Account & { password?: string };
type SchemaState = {
  addDialog: boolean;
  model: IModel;
  category_list: commonv2.ModelCategory[];
  recordId: number;
};
let product_template_map: any[] | never[] = [];
let list: any[] | never[] = [];
let feitLIst: any[] | never[] = [];
// let datalist:any[] | never[] = [];
const GoodsList: ICURDTableSchema<IProductModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '新建商品',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          // @ts-ignore
          this.props.history.push({
            pathname: '/storeManagement/goodsAdd'
          });
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        methods: {
          async componentDidMount(this: Form) {
            const { list } = await Commonv2Services.GetCategoryListAdmin({
              category_type: Shensuo_CategoryType.CategoryTypeStore
            });
            this.updateFormItemData(
              Store_GetProductListAdminReq_ListOption.ListOptionCategoryId,
              {
                selectOptions: list! || []
              }
            );
          }
        },
        onSubmit(this: CURDTable, e) {
          // console.log(this);
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '商品名称',
              type: 'input',
              placeholder: '请输入商品名称',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName: Store_GetProductListAdminReq_ListOption.ListOptionName
            },
            {
              label: '商品状态',
              type: 'select',
              placeholder: '请选择商品状态',
              componentProps: {
                style: {
                  width: 300
                }
              },
              selectOptions: [
                { id: '', status: '全部' },
                { id: '0', status: '上架' },
                { id: '1', status: '下架' }
              ],
              selectOption: {
                valueKey: 'id',
                labelKey: 'status'
              },
              propName:
                Store_GetProductListAdminReq_ListOption.ListOptionSoldOut
            },
            {
              label: '商品分类',
              type: 'select',
              placeholder: '请选择商品分类',
              componentProps: {
                style: {
                  width: 300
                }
              },
              needObservable: true,
              selectOptions: [],
              selectOption: {
                valueKey: 'id',
                labelKey: 'name'
              },
              propName:
                Store_GetProductListAdminReq_ListOption.ListOptionCategoryId
            },
            {
              label: '商品分类',
              type: 'select',
              placeholder: '请选择商品分类',
              componentProps: {
                style: {
                  width: 300
                }
              },
              needObservable: true,
              selectOptions: [],
              selectOption: {
                valueKey: 'id',
                labelKey: 'name'
              },
              propName:
                Store_GetProductListAdminReq_ListOption.ListOptionCategoryId
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    rowClassName: () => styles.row,
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        key: 'operation',
        width: 180,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: IProductModel) {
          return {
            needDivider: false,
            btns: [
              {
                type: 'link',
                size: 'small',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  // @ts-ignore
                  this.props.history.push({
                    pathname: '/storeManagement/goodsEdit',
                    search: '?id=' + record.id
                  });
                }
              },
              {
                type: 'link',
                size: 'small',
                children: !record.sold_out ? '下架' : '上架',
                async onClick(this: SchemaCURDTable) {
                  await StoreServices.SetProductSoldOutByIds({
                    ids: [Number(record!.id)],
                    sold_out: !record.sold_out
                  });
                  try {
                    this.message.success(
                      `${!record.sold_out ? '下架' : '上架'}成功`
                    );
                    this.listService.refreshList();
                  } catch (error) { }
                }
              },
              {
                type: 'link',
                size: 'small',
                style: {
                  color: '#ff4d4f'
                },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  async onConfirm(this: CURDTable) {
                    await StoreServices.DelProduct({
                      id: Number(record!.id)
                    });
                    try {
                      this.message.success('删除成功');
                      this.listService.refreshList();
                    } catch (error) { }
                  }
                },
                children: '删除'
              },
              {
                type: 'link',
                size: 'small',
                children: '运费配置',
                async onClick(this: SchemaCURDTable) {
                  // this.methods.setModelData();
                    //@ts-ignore
                  feitLIst =  await StoreServices.getOptionFeight({ productId: record!.id })
                  //@ts-ignore
                  console.log('feitLIstsss',feitLIst);
                  this.methods.toggleModelDialog(record!.id);
                }
              }
            ]
          };
        }
      },
      {
        dataIndex: 'category_id',
        title: '商品分类',
        ellipsis: true,
        render(this: SchemaCURDTable, id: number) {
          const { category_list } = toJS(this.observableData);
          const category_name = category_list.find(
            (item: commonv2.ModelCategory) => item.id === id
          )
            ? category_list.find(
              (item: commonv2.ModelCategory) => item.id === id
            ).name
            : '';
          return category_name;
        }
      },
      {
        // dataIndex: 'id',
        key: 'productID',
        title: '模板配置',
        ellipsis: true,
        render(list) {
          //@ts-ignore
          for (var k in product_template_map) {
            //@ts-ignore
            // eslint-disable-next-line
            if (k == list.id) {
              return (
                <span>已配置</span>
              )
            }
          } return (
            <span>未配置</span>
          )
        }
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: '商品名称',
        ellipsis: true
      },
      {
        dataIndex: 'main_image',
        title: '商品图片',
        width: 200,
        render(src: string) {
          return (
            <div className="thumbnail-list">
              <img src={src} alt="" />
            </div>
          );
        }
      },
      {
        dataIndex: 'created_at',
        title: '创建时间',
        dataType: 'date',
        ellipsis: true
      },
      {
        dataIndex: 'sold_out',
        title: '状态',
        width: 100,
        ellipsis: true,
        render(sold_out: boolean) {
          return sold_out ? '下架' : '上架';
        }
      }
    ]
  },
  observableData: {
    addDialog: false,
    model: {
      name: ''
    },
    category_list: [],
    recordId: 0
  },
  methods: {
    toggleModelDialog(this: SchemaCURDTable, recordId) {
      this.observableData.addDialog = !this.observableData.addDialog;
      if (this.observableData.addDialog) {
        this.observableData.recordId = recordId;
      };
      // console.log('addDialog', this.observableData.addDialog);
    },
    getFetchListResult(this: SchemaCURDTable, result) {
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        //@ts-ignore
        list = result.resp!.list
        //@ts-ignore
        product_template_map = result.resp!.product_template_map;
        console.log(list, product_template_map);
      }
      this.loading = result.loading;
    },

    async getCategoryList(this: SchemaCURDTable) {
      const { list } = await Commonv2Services.GetCategoryListAdmin({
        category_type: Shensuo_CategoryType.CategoryTypeStore
      });
      this.methods.set_category_list(list!);
    },
    set_category_list(v: commonv2.ModelCategory) {
      this.observableData.category_list = v;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.methods.getCategoryList();
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.methods.getCategoryList();
      this.listService.setFetchListFn(StoreServices.GetProductListAdmin);
      this.listService.setReq<IFetchListReq>({
        list_option: {},
        store_id: Shensuo_StoreType.StoreTypeComm
      });
      this.listService.getList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, recordId } = toJS(observableData) as SchemaState;
    return (
      <>
        <SchemaDialog
          schema={{
            visible: addDialog,
            destroyOnClose: true,
            title: `设置运费`,
            children: (
              <SchemaForm
                wrappedComponentRef={(ref: any) => {
                  this.customNodeRef.addMenuDialog = ref;
                  console.log('this', this.customNodeRef.addMenuDialog)
                }}
                schema={
                  {
                    // labelCol: { span: 10 },
                    // wrapperCol: { span: 9 },
                    methods: {
                      async componentDidMount(this: Form) {
                        // @ts-ignore
                        const data = [];
                        data.push(await StoreServices.getTemplate({}));
                        console.log('data', data);
                        this.updateFormItemData('id', {
                          selectOptions: data[0]!
                        });
                      }
                    },
                    labelAlign: 'left',
                    rows: [
                      [
                        {
                          propName: 'type',
                          type: 'radio',
                          radioGroup: [
                            {
                              value: 1,
                              children: '固定运费',
                            }
                          ],
                          FieldDecoratorOptions: {
                            //@ts-ignore
                            initialValue: feitLIst.type,
                            rules: [
                              {
                                required: true,
                                message: '请选择运费状态'
                              }
                            ]
                          }
                        },
                        {
                          // label: '固定运费',
                          propName: 'fixedFeight',
                          type: 'input',
                          placeholder: '请输入运费价格',
                          componentProps: {
                            autoComplete: 'off',
                            width: '200'
                          },
                          FieldDecoratorOptions: {
                             //@ts-ignore
                             initialValue: feitLIst.fixedFeight?feitLIst.fixedFeight/100:[],
                            // rules: [
                            //   {
                            //     // required: true,
                            //     message: '请输入运费价格'
                            //   }
                            // ]
                          }
                        },
                        {
                          propName: 'type',
                          type: 'radio',
                          radioGroup: [
                            {
                              value: 2,
                              children: '请选择运费模板'
                            }
                          ],
                          FieldDecoratorOptions: {
                            //@ts-ignore
                            initialValue: feitLIst.type,
                            rules: [
                              {
                                required: true,
                                message: '请选择运费状态'
                              }
                            ]
                          }
                        },
                        {
                          // label: '运费模版',
                          propName: 'id',
                          type: 'select',
                          selectOptions: [],
                          selectOption: {
                            valueKey: 'id',
                            labelKey: 'name'
                          },
                          needObservable: true,
                          placeholder: '请选择运费模板',
                          FieldDecoratorOptions: {
                            //@ts-ignore
                            initialValue:feitLIst.feightTemplateId,
                            rules: [
                              {
                                required: false,
                                message: '请选择运费模板'
                              }
                            ]
                          }
                        }
                        // {
                        //   // label: '运费模版',
                        //   children: '创建新运费模板',
                        //   type:'Button',
                        //   onClick(this: SchemaCURDTable) {
                        //     // @ts-ignore
                        //     this.props.history.push({
                        //       pathname: '/storeManagement/creatFire'
                        //     });
                        //   },
                        //   componentProps: {
                        //     autoComplete: 'off',
                        //     width: '200',
                        //     color: '#ccc'
                        //   }
                        // }
                      ]
                    ]
                  } as IFormSchema<IModel>
                }
              />
            ),
            onCancel: () => {
              this.methods.toggleModelDialog();
              this.customNodeRef.addMenuDialog.props.form.resetFields();
            },
            onOk: async () => {
              //@ts-ignore
              feitLIst =  await StoreServices.getOptionFeight({ productId: recordId })
              //@ts-ignore
              console.log('feitLIst测试feightTemplateId是多少',feitLIst);
              this.customNodeRef.addMenuDialog.props.form.validateFields(
                async (errors: Error[], values: any) => {
                  if (!errors) {
                    const opt = {
                      ...values,
                      fixedFeight:values.fixedFeight*100,
                      productId: recordId
                    }
                    const data = [];
                    data.push(await StoreServices.getTemplate({}));
                    //@ts-ignore
                    console.log(data[0]);
                     //@ts-ignore
                    var tmp = data[0].find(t => t.id === values.id);
                    console.log(tmp);
                     console.log('tmpsssss',tmp.id)
                    // data.map()
                    const opts = {
                      type: values.type,
                      productId: recordId,
                      // @ts-ignore
                      feightTemplateId: tmp.id ? Number(tmp.id)  : Number(feitLIst.feightTemplateId) ,
                    }
                    //@ts-ignore
                    if (values.type === 1) {
                      //@ts-ignore
                      if (values.fixedFeight || feitLIst.fixedFeight) {
                        await StoreServices.optionFeight(opt);
                        message.success('编辑成功！');
                        this.customNodeRef.addMenuDialog.props.form.resetFields();
                        this.listService.refreshList();
                        this.methods.toggleModelDialog();
                        return Promise.resolve(values);
                      } else {
                        message.warning('请输入运费价格')
                      }
                    } else if (values.type === 2) {
                      if (values.id) {
                        //@ts-ignore
                        await StoreServices.optionFeight(opts);
                        message.success('编辑成功！');
                        this.customNodeRef.addMenuDialog.props.form.resetFields();
                        this.listService.refreshList();
                        this.methods.toggleModelDialog();
                        return Promise.resolve(values);
                      } else {
                        message.warning('请选择运费模板')
                      }

                    }
                    
                  }
                }
              );
            }
          }}
        />
      </>
    );
  }
};

export default GoodsList;
