import React from 'react';
import {
  HashRouter,
  BrowserRouter,
  BrowserRouterProps,
  HashRouterProps,
  SwitchProps
} from 'react-router-dom';

import {
  IRouterConfItem,
  RenderRouters,
  ICanIVisitFn,
  ICanIVisitOptions,
  RouteItemProps
} from './routers';
import RouterProviderContext from './context';
import KeepAliveProvider, {
  IKeepAliveProviderProps
} from './keepAlive/Provider';

type IRouterConfig = BrowserRouterProps &
  HashRouterProps & {
    mode?: 'hash' | 'history';
    routers: IRouterConfItem[];
    canIVisit?: ICanIVisitFn;
    // todo支持检查loading
    canIVisitOptions?: ICanIVisitOptions;
    switchProps?: Omit<SwitchProps, 'children'>;
    aliveProps?: IKeepAliveProviderProps;
  };

type IRouterConfigMap = {
  [path: string]: RouteItemProps;
};

// TODO 支持有param的path
function Router() {
  return (
    <RouterProviderContext.Consumer>
      {config => {
        const {
          mode = 'hash',
          routers = [],
          canIVisit,
          canIVisitOptions,
          switchProps,
          aliveProps,
          ...params
        } = config;

        const routerChildren = (
          <KeepAliveProvider
            {...aliveProps}
            children={RenderRouters(
              routers,
              switchProps,
              canIVisit,
              canIVisitOptions
            )}
          />
        );

        return mode === 'history' ? (
          <BrowserRouter {...params} children={routerChildren} />
        ) : (
          <HashRouter {...params} children={routerChildren} />
        );
      }}
    </RouterProviderContext.Consumer>
  );
}

export { Router, IRouterConfig, IRouterConfigMap };
export * from './routers';
