import { useState } from 'react';

import { hydrationsStore } from 'store';

function StoreHydration(props: any) {
  const [hydrated, setHydrated] = useState(false);
  // @ts-ignore
  Promise.all(hydrationsStore).then(() => {
    setHydrated(true);
  });
  if (hydrated) {
    return props.children;
  } else {
    return null;
  }
}

export default StoreHydration;
