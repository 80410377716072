import React from 'react';
import { Upload, Button, message, Modal } from 'antd';
import { UploadProps, RcFile } from 'antd/lib/upload';
import {
  measureImgResolution,
  IUploadParams,
  getUploadUrlAndParams,
  getUploadResult,
  UploadResult
} from './services';

import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

import 'antd/lib/upload/style';
import { defaultProps } from '@/decorators';

export type IUploaderValue = string[] | any;

export interface IUploaderProps<Input = any> extends UploadProps {
  value?: IUploaderValue;
  preUploadReq?: weblogic.PreUploadReq;
  beforeUpload?:
    | ((file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>)
    | undefined;
  onChange?: (
    files: (string | UploadFile)[] | UploadChangeParam<UploadFile>
  ) => void;
  maxFileSize?: number | [number, number];
  maxLen?: number;
  fileOverSizeTip?: string;
  onFileOverSize?(file: UploadFile): PromiseLike<boolean | void> | boolean;
  incorrectFileTypeTip?: string;
  resolution?: string;
  onResolutionNotMatch?(
    file: UploadFile,
    size: { width: number; height: number }
  ): PromiseLike<boolean | void> | boolean;
  inputFormater?(params: Input): string[];
  outputFormater?(params: string[]): any;
  onFileChange?: (info: UploadChangeParam<UploadFile>) => void;
}

export interface IUploaderState {
  uploadUrl: string;
  uploadParams: IUploadParams;
  fileList: UploadFile[];
}

@defaultProps({
  maxFileSize: [5242880, 20971520],
  fileOverSizeTip: '文件大小不能超过20M',
  incorrectFileTypeTip: '文件类型不正确'
})
class Uploader extends React.Component<
  IUploaderProps & { onlineVideo?: boolean }
> {
  state: IUploaderState = {
    uploadUrl: '',
    uploadParams: null,
    fileList: []
  };

  preUploadRsp: {
    [uid: string]: storage.ModelResource;
  } = {};

  uploadCallBackRsp: {
    [uid: string]: storage.UploadCallBackV2Rsp;
  } = {};

  // prevLen: number = 0;

  processPreUpload = async (file: RcFile) => {
    try {
      const preUploadReq: weblogic.PreUploadReq = this.props.preUploadReq || {
        content_type: ''
      };
      preUploadReq!.content_type = file.type!;

      if (this.props.onlineVideo) {
        preUploadReq.resource_type = 'online_lesson';
      }

      if (!preUploadReq!.content_type) {
        message.error('未知文件类型');
        return Promise.reject('未知文件类型');
      }

      const {
        uploadUrl,
        uploadParams,
        resp: { resource }
      } = await getUploadUrlAndParams(preUploadReq);

      this.preUploadRsp[file.uid!] = resource!;

      this.setState({ uploadUrl, uploadParams });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  beforeUpload: (
    file: RcFile,
    FileList: RcFile[]
  ) => PromiseLike<void> = async (file: RcFile, FileList: RcFile[]) => {
    try {
      await this.checkFileType(file);
      await this.checkFileSize(file);
      await this.checkResolutionIsMatch(file);
      await this.handleCustomBeforeUpload(file, FileList);
      await this.processPreUpload(file);
    } catch (e) {
      console.log('beforeUpload', e);
      return Promise.reject(e);
    }
  };

  isOverMaxLen = () => {
    const { value, maxLen } = this.props;
    const { fileList } = this.state;

    let result;
    if (Array.isArray(value)) {
      result = maxLen && (fileList.length >= maxLen || value.length >= maxLen);
    } else {
      result =
        (fileList.map(item => item.status).includes('uploading') || value) &&
        maxLen;
    }
    return result;
  };

  checkFileSize = async (file: UploadFile) => {
    const { maxFileSize, fileOverSizeTip, onFileOverSize } = this.props;
    // check file size
    try {
      const warningSize = Array.isArray(maxFileSize)
        ? maxFileSize.sort((a, b) => a - b)[0]
        : maxFileSize;
      const errorSize = Array.isArray(maxFileSize)
        ? maxFileSize.sort((a, b) => a - b)[1]
        : maxFileSize;

      if (!warningSize || !errorSize) {
        return;
      }

      // 如果有检查文件大小回调
      if (
        onFileOverSize &&
        (file.size > errorSize || file.size > warningSize)
      ) {
        try {
          const resp = await onFileOverSize(file);
          if (resp === false) {
            return Promise.reject();
          }
        } catch (e) {
          return Promise.reject(e);
        }
        // 超出最大限制大小
      } else if (file.size > errorSize) {
        message.error(fileOverSizeTip);
        return Promise.reject(fileOverSizeTip);
        // 超出提示大小
      } else if (file.size > warningSize) {
        return new Promise((resolve, reject) => {
          Modal.confirm({
            title: '文件超过建议大小',
            content: `建议上传文件大小不超过${(
              warningSize /
              1024 /
              1024
            ).toFixed(2)}M，当前文件约为${(file.size / 1024 / 1024).toFixed(
              2
            )}M是否继续上传？`,
            onOk: () => {
              resolve(file);
            },
            onCancel: () => {
              reject(file);
            }
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkFileType = (file: UploadFile) => {
    const { accept, incorrectFileTypeTip } = this.props;
    try {
      if (
        accept &&
        !new RegExp(accept.split(',').join('|'), 'ig').test(file.type)
      ) {
        message.error(incorrectFileTypeTip);
        return Promise.reject(incorrectFileTypeTip);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  checkResolutionIsMatch = async (file: UploadFile) => {
    const { resolution = '', onResolutionNotMatch } = this.props;
    try {
      if (resolution) {
        const imgSize = await measureImgResolution(file);
        const resolutions = resolution.split('/');
        const isWidthMatch = imgSize.width === Number(resolutions[0]);
        const isHeightMatch = imgSize.height === Number(resolutions[1]);
        if (isWidthMatch && isHeightMatch) {
          return Promise.resolve();
        } else if (onResolutionNotMatch) {
          const result = await onResolutionNotMatch(file, imgSize);
          if (result === false) {
            return Promise.reject();
          }
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  componentDidMount() {
    this.handleOriginalFiles();
  }

  componentDidUpdate() {
    this.handleOriginalFiles();
  }

  handleOriginalFiles = () => {
    const { value = [] } = this.props;
    let { fileList } = this.state;
    try {
      const propfileList = this.parseInput(value)
        .map(item => item.url)
        .filter(v => v);
      const stateFileList = this.state.fileList
        .map(item => item.url)
        .filter(v => v);
      if (
        !(
          propfileList.length === stateFileList.length &&
          propfileList.every(item => stateFileList.includes(item))
        )
      ) {
        fileList = this.parseInput(value);
        this.setState({ fileList });
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleCustomBeforeUpload: (
    file: RcFile,
    FileList: RcFile[]
  ) => boolean | PromiseLike<void> = async (
    file: RcFile,
    FileList: RcFile[]
  ) => {
    const { beforeUpload } = this.props;
    try {
      if (beforeUpload) {
        const res = await beforeUpload(file, FileList);
        // if return false, then stop uploading
        if (res === false) {
          return Promise.reject(res);
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  parseInput: (files: IUploaderValue) => UploadFile[] = files => {
    // call inputFormater at the beginning
    const resolvedFiles = this.props.inputFormater
      ? this.props.inputFormater(files) || []
      : files;

    const fileUrls = this.state.fileList.map(item => item.url);
    const maxNumUid =
      this.state.fileList
        .map(item => +item.uid)
        .filter(uid => !isNaN(uid))
        .sort((a, b) => a - b)
        .pop() || 1;

    return resolvedFiles.map((file: string | UploadFile, i: number) => {
      const isString = typeof file === 'string';
      const isIncluded: boolean = isString && fileUrls.includes(file as string);
      if (isIncluded) {
        return this.state.fileList.find(item => item.url === file);
      }

      return isString
        ? {
            uid: `-${i - maxNumUid}`,
            url: file,
            name: file
          }
        : file;
    }) as UploadFile[];
  };

  parseOutput = (fileList: UploadFile[] = []) => {
    const parsedFiles = fileList
      .map(file => {
        if (!file.url) {
          if (this.uploadCallBackRsp[file.uid]) {
            file.url = this.uploadCallBackRsp[file.uid].url;
          }
        }
        // upload success return url
        // else return file object
        return file.url || file;
      })
      // only output the successful ones
      .filter(item => typeof item === 'string') as string[];

    return this.props.outputFormater
      ? this.props.outputFormater(parsedFiles)
      : parsedFiles;
  };

  handleChange: (info: UploadChangeParam<UploadFile>) => void = async info => {
    try {
      try {
        // upload error notify backend
        switch (info.file.status) {
          case 'error':
            getUploadResult({
              resource_id: this.preUploadRsp[info.file.uid].id!,
              resource_state: UploadResult.StateFail
            });
            break;
          case 'done':
            const resp = await getUploadResult({
              resource_id: this.preUploadRsp[info.file.uid].id!,
              resource_state: UploadResult.StateSuccess
            });
            this.uploadCallBackRsp[info.file.uid] = resp;
            break;
          default:
            break;
        }
      } catch (error) {}

      if (!info.fileList.map(item => item.status).includes('uploading')) {
        const output = this.parseOutput(info.fileList);
        this.props.onChange && this.props.onChange(output);
      }

      this.props.onFileChange && this.props.onFileChange(info);
      this.setState({ fileList: info.fileList });
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { uploadUrl, uploadParams, fileList } = this.state;
    const { value, children, ...options } = this.props;

    return (
      <Upload
        {...options}
        action={uploadUrl}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        data={uploadParams as object}
        fileList={fileList}
      >
        {children !== undefined
          ? children
          : null || (!this.isOverMaxLen() ? <Button>上传</Button> : null)}
      </Upload>
    );
  }
}

export default Uploader;
