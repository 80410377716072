import React from 'react';
import { Icon, message, Tree, Input, Tag } from 'antd';
import { toJS } from 'mobx';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema, Form } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';

import { Rbacv2Services, InneradminServices } from '@/services/apiServices.js';
import { Inneradmin_GetApiPathListReq_ListOption } from '@/services/enumTypes';
import { MenuItem, IMenuModel } from '@/Layouts/BasicLayout/menu';
import { getMenuTreeAndMap } from '@/Layouts/BasicLayout/menu.services';
import CURDTable from '@/components/Schema/CURDTable';

type IModel = MenuItem;
type AddModel = rbacv2.AddMenuReq;
type IFetchListReq = rbacv2.GetMenuTreeReq;
type IFetchListRsq = rbacv2.GetMenuTreeRsp;

type ISoftwareListItem = {
  id?: number;
  name: string;
  children?: ISoftwareListItem[];
  isLeaf?: boolean;
};

type SchemaState = {
  addMenuDialog: boolean;
  addAuthDialog: boolean;
  menu: IModel;
  menuMap: {
    [numeId: number]: IMenuModel;
  };
  menuId: number;
  softwareList: ISoftwareListItem[];
  searchApiVal: string;
  checkedApis: string[];
  menuApis: {
    [numeId: number]: string[];
  };
};

const config: ICURDTableSchema<IModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '添加菜单',
        icon: 'plus',
        onClick(this: SchemaCURDTable) {
          this.methods.setMenuData();
          this.observableData.addMenuDialog = true;
        }
      }
    ]
  },
  table: {
    rowKey: 'id',
    scroll: { y: 'calc(100vh - 300px)' },
    childrenColumnName: 'subMenu',
    defaultExpandAllRows: true,
    columns: [
      {
        dataIndex: 'readable_name',
        title: '菜单名称',
        width: 200
      },
      {
        dataIndex: 'view_order',
        title: '排序',
        width: 100
      },
      {
        dataIndex: 'path',
        title: '地址',
        width: 250
      },
      {
        dataIndex: 'ex',
        title: 'extra',
        width: 300,
        render(this: SchemaCURDTable, ex) {
          let hidden = false;
          let breadcrumbIds: number[] = [];
          if (ex) {
            hidden = !!ex.hidden;
            breadcrumbIds = ex.breadcrumbIds || [];
          }

          return (
            <>
              <div>是否菜单显示：{hidden ? '否' : '是'}</div>
              {breadcrumbIds.length ? (
                <div>
                  自定义面包屑：
                  {breadcrumbIds.map((v, i) => {
                    const node = this.observableData.menuMap[v];
                    if (node) {
                      return (
                        <span key={i}>
                          {node.readable_name}
                          {i === breadcrumbIds.length - 1 ? '' : ' / '}
                        </span>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
            </>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        dataType: 'btn-group',
        width: 400,
        renderBtnGroup(this: SchemaCURDTable, item: IModel) {
          return {
            needDivider: true,
            btns: [
              {
                type: 'link',
                children: '编辑',
                onClick(this: SchemaCURDTable) {
                  this.methods.setMenuData(item);
                  this.methods.toggleMenuDialog();
                }
              },
              {
                type: 'link',
                children: '添加子菜单',
                onClick(this: SchemaCURDTable) {
                  this.methods.setMenuData({ parent_id: item.id });
                  this.methods.toggleMenuDialog();
                }
              },
              {
                type: 'link',
                children: '编辑权限',
                async onClick(this: SchemaCURDTable) {
                  this.observableData.menuId = item.id;
                  const checkedApis: string[] =
                    this.observableData.menuApis[item.id!] || [];
                  await Promise.all(
                    checkedApis
                      .map(v => v.split('/').filter(String)[0])
                      .filter(String)
                      .map(v => this.methods.getSoftwareApiListByName(v))
                  );
                  this.methods.setCheckedApis(checkedApis);
                  this.methods.toggleAuthDialog();
                }
              },
              {
                type: 'link',
                style: { color: '#ff4d4f' },
                popconfirmProps: {
                  title: '确定删除此项？',
                  icon: (
                    <Icon
                      type="question-circle-o"
                      style={{ color: '#ff4d4f' }}
                    />
                  ),
                  onConfirm(this: SchemaCURDTable) {
                    Rbacv2Services.DelMenu({
                      menu_tree_id: +process.env.ENV_appId!,
                      corp_id: +process.env.ENV_corpId!,
                      id: item.id!
                    }).then(() => {
                      message.success('删除成功');
                      this.listService.refreshList();
                    });
                  }
                },
                children: '删除'
              }
            ]
          };
        }
      }
    ],
    pagination: false
  },
  observableData: {
    addMenuDialog: false,
    addAuthDialog: false,
    softwareList: [],
    menu: {
      menu_tree_id: +process.env.ENV_appId!,
      corp_id: +process.env.ENV_corpId!,
      readable_name: '',
      path: ''
    },
    menuId: 0,
    menuMap: {},
    searchApiVal: '',
    checkedApis: [],
    menuApis: {}
  },
  methods: {
    setMenuData(menu?: IModel) {
      const model: IModel = {
        readable_name: '',
        ...menu,
        menu_tree_id: +process.env.ENV_appId!,
        corp_id: +process.env.ENV_corpId!
      };
      if (model.parent_id) {
        const parent: IMenuModel = this.observableData.menuMap[
          model.parent_id!
        ];
        if (parent && model.path) {
          model.path = model.path!.replace(new RegExp(parent!.path + '/'), '');
        }
      }
      this.observableData.menu = model;
    },
    toggleMenuDialog(this: SchemaCURDTable) {
      this.observableData.addMenuDialog = !this.observableData.addMenuDialog;
    },
    toggleAuthDialog(this: SchemaCURDTable) {
      this.observableData.addAuthDialog = !this.observableData.addAuthDialog;
    },

    setSearchApiVal(val: string) {
      this.observableData.searchApiVal = val;
    },

    setCheckedApis(apis: string[] = []) {
      this.observableData.checkedApis = apis;
    },

    async getSoftwareList() {
      const {
        front_end_dep_server
      } = await InneradminServices.GetFrontEndDepServer({
        app_id: +process.env.ENV_appId!,
        corp_id: +process.env.ENV_corpId!
      });
      this.asyncAction(() => {
        this.observableData.softwareList = front_end_dep_server!.server_list!.map(
          v => ({ name: v, children: [] })
        );
      });
    },

    async getSoftwareApiListByName(softwareName: string) {
      const { list } = await InneradminServices.GetApiPathList({
        list_option: {
          options: [
            {
              type:
                Inneradmin_GetApiPathListReq_ListOption.ListOptionServerName,
              value: softwareName
            }
          ]
        }
      });
      this.asyncAction(() => {
        this.observableData.softwareList.forEach((item: ISoftwareListItem) => {
          if (item.name === softwareName) {
            item.children = list!.map(v => ({
              id: v.id!,
              name: v.api_path!,
              isLeaf: true
            }));
          }
        });
      });
    },

    getFetchListResult(this: SchemaCURDTable, result) {
      this.loading = result.loading;
      if (result.resp!) {
        const { menuTree, menuMap } = getMenuTreeAndMap(
          result.resp!.menu_list!
        );
        this.observableData.menuMap = menuMap;
        this.table.dataSource = menuTree;
      }
    },

    async getMenuApiList(this: CURDTable) {
      const { list } = await Rbacv2Services.GetMenuApiMapList({
        list_option: {}
      });
      this.asyncAction(() => {
        this.observableData.menuApis = list!.reduce(
          (acc: { [menuId: number]: string[] }, v) => ({
            ...acc,
            [v.menu_id!]: [...(acc[v.menu_id!] || []), v.api_path]
          }),
          {}
        );
      });
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(Rbacv2Services.GetMenuTree);
      this.listService.setReq<IFetchListReq>({
        menu_tree_id: +process.env.ENV_appId!,
        corp_id: +process.env.ENV_corpId!
      });
      this.listService.getList();
      this.methods.getSoftwareList();
      this.methods.getMenuApiList();
    }
  },
  customNode(this: SchemaCURDTable) {
    const { observableData, methods } = this;
    const {
      addMenuDialog,
      addAuthDialog,
      softwareList,
      searchApiVal,
      checkedApis
    } = toJS(observableData) as SchemaState;

    const renderTreeNode = (
      nodes: (inneradmin.ModelSoftware & {
        children?: inneradmin.ModelSoftware[];
      })[] = []
    ) =>
      nodes.map(({ id, name, children, ...props }) => (
        <Tree.TreeNode
          disableCheckbox={
            name.includes('/') ? false : !(children || []).length
          }
          key={name}
          title={name}
          {...props}
          children={renderTreeNode(children)}
        />
      ));

    // TODO mount dialog service
    return (
      <>
        <SchemaDialog
          schema={{
            visible: addMenuDialog,
            title: !this.observableData.menu!.id ? '添加菜单' : '编辑菜单',
            children: (
              <SchemaForm
                wrappedComponentRef={(ref: any) => {
                  this.customNodeRef.addMenuDialog = ref;
                }}
                schema={
                  {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                    labelAlign: 'right',
                    rows: [
                      [
                        {
                          label: '菜单名',
                          propName: 'readable_name',
                          type: 'input',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!
                              .readable_name,
                            rules: [
                              {
                                required: true,
                                message: '请输入菜单名'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '路径',
                          propName: 'path',
                          type: 'input',
                          extra: '会自动拼接父节点path，不需要 / 开头',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.path,
                            rules: [
                              {
                                required: true,
                                message: '请输入路径'
                              }
                            ]
                          }
                        }
                      ],
                      [
                        {
                          label: '图标',
                          propName: 'icon',
                          type: 'input',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.icon
                          }
                        }
                      ],
                      [
                        {
                          label: '排序',
                          propName: 'view_order',
                          type: 'number',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.view_order
                          }
                        }
                      ],
                      [
                        {
                          label: '是否隐藏',
                          propName: 'ex.hidden',
                          type: 'check-box',
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.ex
                              ? this.observableData.menu!.ex.hidden
                              : false
                          },
                          dynamicSetFormItemProps(this: Form) {
                            const { getFieldValue } = this.props.form;
                            return {
                              extra: getFieldValue('ex.hidden')
                                ? '不在菜单显示'
                                : ''
                            };
                          }
                        }
                      ],
                      [
                        {
                          label: '面包屑',
                          propName: 'ex.breadcrumbIds',
                          type: 'tree-select',
                          componentProps: {
                            allowClear: true,
                            multiple: true,
                            placeholder: '选择自定义面包屑路径',
                            treeDefaultExpandAll: true
                          },
                          treeSelectNodes: this.table.dataSource,
                          treeNodeOption: {
                            rowKey: 'id',
                            valueKey: 'id',
                            titleKey: 'readable_name',
                            childrenKey: 'subMenu'
                          },
                          style: {
                            display: !this.observableData.menu.id
                              ? 'none'
                              : 'block'
                          },
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.ex
                              ? this.observableData.menu!.ex.breadcrumbIds
                              : []
                          },
                          extra:
                            '自定义显示面包屑，根据添加先后顺序排列，有子节点不可点击'
                        }
                      ],
                      [
                        {
                          label: '重定向',
                          propName: 'ex.redirectTo',
                          type: 'input',
                          style: {
                            display: !this.observableData.menu.id
                              ? 'none'
                              : 'block'
                          },
                          FieldDecoratorOptions: {
                            initialValue: this.observableData.menu!.ex
                              ? this.observableData.menu!.ex.redirectTo
                              : '',
                            rules: [
                              {
                                required: false,
                                message: '请输入重定向路径'
                              }
                            ]
                          }
                        }
                      ]
                    ]
                  } as IFormSchema<IMenuModel>
                }
              />
            ),
            onCancel: () => {
              this.methods.toggleMenuDialog();
              this.customNodeRef.addMenuDialog.props.form.resetFields();
            },
            onOk: async () => {
              this.customNodeRef.addMenuDialog.props.form.validateFields(
                async (errors: Error[], value: any) => {
                  if (!errors) {
                    value.ex = JSON.stringify(value.ex || {});
                    const opt: AddModel = {
                      menu: {
                        ...this.observableData.menu,
                        ...value
                      }
                    };
                    if (opt.menu.parent_id) {
                      const parent = this.observableData.menuMap[
                        opt.menu.parent_id
                      ];
                      if (parent) {
                        opt.menu.path = parent.path + '/' + value.path;
                      }
                    }
                    if (opt.menu.id) {
                      await Rbacv2Services.SetMenu(opt);
                    } else {
                      await Rbacv2Services.AddMenu(opt);
                    }

                    message.success(`${!opt.menu.id ? '添加' : '编辑'}成功`);

                    this.listService.refreshList();
                    this.customNodeRef.addMenuDialog.props.form.resetFields();
                    this.methods.toggleMenuDialog();
                    return Promise.resolve(value);
                  }
                }
              );
            }
          }}
        />
        <SchemaDialog
          schema={{
            visible: addAuthDialog,
            title: '编辑权限',
            width: '50vw',
            maskClosable: false,
            onCancel: () => {
              this.methods.toggleAuthDialog();
            },
            onOk: async () => {
              await Rbacv2Services.ReplaceMenuApiMap({
                menu_id: this.observableData.menuId,
                api_path: checkedApis
              });
              methods.toggleAuthDialog();
              methods.getMenuApiList();
            },
            children: (
              <>
                <Input.Search
                  style={{ marginBottom: 8 }}
                  placeholder="查找api,需要展开节点"
                  onChange={e => {
                    methods.setSearchApiVal(e.currentTarget.value);
                  }}
                />

                <div>
                  {checkedApis.map((v, key) => (
                    <Tag
                      color="blue"
                      style={{ marginBottom: 10 }}
                      closable
                      children={v}
                      key={key}
                      onClose={() => {
                        methods.setCheckedApis(
                          checkedApis.filter(api => api !== v)
                        );
                      }}
                    />
                  ))}
                </div>

                <Tree
                  checkedKeys={checkedApis}
                  loadData={async node => {
                    methods.getSoftwareApiListByName(node.props.title);
                  }}
                  filterTreeNode={node => {
                    return !!searchApiVal
                      ? String(node.props.title!).includes(searchApiVal) ||
                          searchApiVal.includes(String(node.props.title!))
                      : false;
                  }}
                  onCheck={val => {
                    methods.setCheckedApis(val);
                  }}
                  multiple
                  selectable={false}
                  checkable
                >
                  {renderTreeNode(softwareList)}
                </Tree>
              </>
            )
          }}
        />
      </>
    );
  }
};

export default config;
