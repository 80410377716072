import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { Router } from '@/router/lib/index';
import { RouterProvider } from '@/router/lib/provider';
import {
  IAppConfigStoreProp,
  persistAppConfigStore,
  persistUserStore
} from 'store';
import registerApiServices from 'services/registerApiServices';
import '@/router';

import { LoadingPage } from 'components/LoadingPage';

import './App.less';
import '~styles/index.scss';

registerApiServices();

type IAppProps = Partial<IAppConfigStoreProp> & {};

function App(props: IAppProps) {
  console.log('getRouterConfig', toJS(props.appStore!.getRouterConfig));
  return (
    <LoadingPage
      loaded={() => Promise.all([persistAppConfigStore, persistUserStore])}
    >
      <RouterProvider routerConfig={props.appStore!.getRouterConfig}>
        <Router />
      </RouterProvider>
    </LoadingPage>
  );
}

export default inject(({ appStore }: IAppProps) => ({ appStore }))(
  observer(App)
);
