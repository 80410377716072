import { create, optionsType } from 'mobx-persist';

const storePrefix = `${window.location.host}${window.location.pathname}__`;

export function persistStore<T>(
  key: string,
  store: T,
  initState?: T,
  options: optionsType = {}
): Promise<T> {
  return new Promise((resolve, reject) => {
    create(options)(`${storePrefix}${key}`, store, initState)
      .then(persistResult => {
        console.warn(`${key} store is persist successfully`);
        resolve(persistResult);
      })
      .catch(err => {
        reject(err);
      });
  });
}
