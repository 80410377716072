export default {
  /**
   * 返回 url
   * @param {string} url
   * @param {object} data 描述url需要的query
   */
  getUrlQuery(url: string, data: object = {}): string {
    const hasQuery = data instanceof Object && Object.keys(data).length;
    return hasQuery ? `${url}?${this.obj2urlquery(data)}` : url;
  },

  obj2urlquery(params: object = {}): string {
    if (Object.keys(params).length) {
      return Object.keys(params)
        .map(
          key =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              (params as any)[key]
            )}`
        )
        .join('&');
    }
    return '';
  },

  getQueryString(name: string) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  },

  getQueryObject(searchStr: string = window.location.search) {
    return searchStr
      .substring(1)
      .split('&')
      .reduce((acc: any, item) => {
        const arr = item.split('=');
        if (arr.length === 2) {
          acc[arr[0]] = decodeURIComponent(arr[1]);
        }
        return acc;
      }, {});
  },

  delQueryParams(keys: string[]): string {
    const query = this.getQueryObject();
    return this.getUrlQuery(
      window.location.origin + window.location.pathname,
      Object.keys(query)
        .filter(k => !keys.includes(k))
        .reduce((acc: any, k) => {
          acc[k] = query[k];
          return acc;
        }, {})
    );
  },

  convertHttps(uri: string): string {
    if (/https/.test(uri)) {
      return uri;
    }
    return uri.replace(/http:/, 'https:');
  }
};
