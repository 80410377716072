import { IRouterConfItem, RouteItemProps } from '@/router/lib/routers';
import { IRouterConfigMap } from '@/router/routing';
import t from 'libs/utils';

const prefixPath = (path: string) => (path![0] === '/' ? path! : `/${path!}`);

// 根据menu 生成router 配置
function buildMenuRouterTree(menus: rbacv2.ModelMenu[]) {
  type MenuRouterItem = RouteItemProps & {
    id: number;
    parent_id: number;
    subRouter: RouteItemProps[];
    to?: string;
  };

  const menuRouterList: IRouterConfItem[] = [];

  const menuMap: {
    [id: number]: MenuRouterItem;
  } = menus!.reduce((acc, { id, parent_id, path, ex }) => {
    const new_ex = JSON.parse(JSON.stringify(ex));
    acc[id!] =
      new_ex && new_ex!.redirectTo
        ? {
            id,
            parent_id,
            path: prefixPath(path!),
            to: prefixPath(new_ex!.redirectTo!)
          }
        : { id, parent_id, path: prefixPath(path!) };

    // acc[id!] = { id, parent_id, path: prefixPath(path!) };
    return acc;
  }, {});

  Object.keys(menuMap).forEach(id => {
    const menu = menuMap[id];
    if (menu.parent_id) {
      if (menuMap[menu.parent_id]) {
        menu.path = prefixPath(
          String(menu.path!)
            .split('/')
            .pop()!
        );
        menuMap[menu.parent_id].subRouter = [
          ...(menuMap[menu.parent_id].subRouter || []),
          menu
        ];
      }
    }
  });

  // TODO 本地配置的路由没有考虑到
  Object.keys(menuMap).forEach(id => {
    const menu = menuMap[id];
    if (menu.parent_id) {
      if (menuMap[menu.parent_id].to) {
        menuMap[menu.parent_id].subRouter.push({
          to: menuMap[menu.parent_id].to!
        });
        delete menuMap[menu.parent_id].to;
      } else {
        if (menuMap[menu.parent_id].subRouter) {
          if (
            menuMap[menu.parent_id].subRouter.findIndex(
              (item: any) => item.to
            ) < 0
          ) {
            menuMap[menu.parent_id].subRouter.push({
              to: `${menuMap[menu.parent_id].path}${
                menuMap[menu.parent_id].subRouter[0].path
              }`
            });
          }
        }
      }
    } else {
      if (menu.to) {
        menu.subRouter = [...(menu.subRouter || []), { to: menu.to }];
        delete menu.to;
        menuRouterList.push(menu);
      } else {
        if (menu.subRouter) {
          menu.subRouter.push({ to: `${menu.path}${menu.subRouter[0].path}` });
        }
        menuRouterList.push(menu);
      }
    }
  });
  return menuRouterList;
}

// 本地配置路由 添加subRoute to重定向
function mapPathSubRoute(obj: RouteItemProps) {
  if (!obj.subRouter) return;
  if (obj.subRouter!.findIndex((item: any) => item.to) < 0) {
    obj.subRouter = [
      ...(obj.subRouter || []),
      {
        to: `${obj.path}${obj.subRouter![0].path}`
      }
    ];
  }
  if (obj.subRouter!.length > 0) {
    obj.subRouter!.forEach((v: any) => {
      mapPathSubRoute(v);
    });
  }
}

// 根据path 生成router 配置
function buildRouterMapRouterTree(map: IRouterConfigMap) {
  const resultMap: { [key: string]: RouteItemProps } = {};
  Object.keys(map).forEach(path => {
    const patharr = path.split('/').filter(String);
    patharr.forEach((path, i) => {
      const parentPath = patharr.slice(0, i).join('/');
      const fullPath = patharr.slice(0, i + 1).join('/');
      if (i === patharr.length - 1 && patharr.length > 1) {
        resultMap[parentPath].subRouter = [
          ...(resultMap[parentPath].subRouter || []),
          {
            ...(map[prefixPath(fullPath)] || {}),
            path: prefixPath(path)
          }
        ];
      } else {
        if (!resultMap.hasOwnProperty(fullPath)) {
          resultMap[path] = {
            ...(map[prefixPath(fullPath)] || {}),
            path: prefixPath(fullPath)
          };
        }
      }
    });
  });

  Object.keys(map).forEach(path => {
    const patharr = path.split('/').filter(String);
    patharr.forEach((path, i) => {
      const parentPath = patharr.slice(0, i).join('/');
      if (i === patharr.length - 1 && patharr.length > 1) {
        mapPathSubRoute(resultMap[parentPath]);
      }
    });
  });

  return Object.values(resultMap);
}

// 合并节点
function mergeNode(tree: IRouterConfItem[], node: IRouterConfItem) {
  if (
    !tree.some((item: RouteItemProps) => {
      if (item.path === node.path) {
        const { subRouter, ...params } = node as RouteItemProps;
        Object.assign(item, params);
        if (!item.subRouter) {
          item.subRouter = [];
        }
        mergeRouterTree(item.subRouter!, subRouter! || []);
      }
      return item.path === node.path;
    })
  ) {
    tree.push(node);
  }
}

const sortFn = (n: IRouterConfItem, o: IRouterConfItem) =>
  n.path ? (o.path ? o.path!.length : 0) - n.path.length : 1;

// Redirect 移动到底部
function sortRedirectItem(routers: IRouterConfItem[]): IRouterConfItem[] {
  routers.forEach((n: RouteItemProps) => sortRedirectItem(n.subRouter! || []));
  return routers.sort(sortFn);
}

// 合并多个树 第一个为基准
function mergeRouterTree(
  baseTree: IRouterConfItem[],
  ...trees: IRouterConfItem[][]
): IRouterConfItem[] {
  trees.forEach(tree => {
    tree.forEach(node => mergeNode(baseTree, node));
  });

  return sortRedirectItem(baseTree);
}

// 合并 routerconfig routerConfigMap menu 为routerConfig
function mergeRouterConfig(
  menus: rbacv2.ModelMenu[],
  map: IRouterConfigMap,
  routers: IRouterConfItem[]
): IRouterConfItem[] {
  menus = t.deepClone(menus);
  routers = t.deepClone(routers);
  map = t.deepClone(map);

  const menuRouterConfig = buildMenuRouterTree(menus);
  const routerMapRouterConfig = buildRouterMapRouterTree(map);

  routers.forEach(item => {
    mapPathSubRoute(item);
  });

  return mergeRouterTree(
    routers,
    // TODO map 无法配置 router 的config， 先这样实现，再看看怎么写
    [
      {
        path: '/',
        subRouter: routerMapRouterConfig
      }
    ],
    [
      {
        path: '/',
        subRouter: menuRouterConfig
      }
    ]
  );
}

export { mergeRouterConfig };
