import React from 'react';
import BraftEditor, {
  BraftEditorProps,
  EditorState,
  ControlType
} from 'braft-editor';
import 'braft-editor/dist/index.css';
import styles from './index.module.scss';
import { defaultProps } from '@/decorators';
import { media as mediaParams } from './services';

export interface IRichTextEditorProps extends BraftEditorProps {
  convertOutPut?: (editorState: EditorState) => any;
  convertInput?: (input: any) => EditorState;
  toRem?: boolean;
  sizeBase?: number;
  value?: string | EditorState;
  defaultValue?: string | EditorState;
  onChange?: (editorState: EditorState) => void;
  converts?: {
    unitImportFn: IUnitImportFn;
    unitExportFn: IUnitExportFn;
  };
}

export type IUnitImportFn = (
  unit: string,
  type: string,
  source: 'create' | 'paste'
) => number;

export type IUnitExportFn = (
  unit: number,
  type: string,
  target: 'html' | 'editor'
) => string | number;

// some unreliable controls are forbidden
const defaultControls: ControlType[] = [
  'font-size',
  // 'line-height',
  // 'letter-spacing',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'emoji',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'headings',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  'link',
  'separator',
  'hr',
  'separator',
  'media',
  'separator',
  'clear',
  'fullscreen'
];

@defaultProps({
  sizeBase: 23.4375
})
class RichTextEditor extends React.Component<IRichTextEditorProps> {
  content: string = '';

  exportConvertFn: IUnitExportFn = (
    unit: number,
    type: string,
    target: 'html' | 'editor'
  ) => {
    if (type === 'line-height') {
      // 输出行高时不添加单位
      return unit;
    }

    // target的值可能是html或者editor，对应输出到html和在编辑器中显示这两个场景
    if (target === 'html') {
      // 只在将内容输出为html时才进行转换
      return unit / this.props.sizeBase! + 'rem';
    } else {
      // 在编辑器中显示时，按px单位展示
      return unit + 'px';
    }
  };

  importConvertFn: IUnitImportFn = (
    unit: string,
    type: string,
    source: 'create' | 'paste'
  ) => {
    // type为单位类型，例如font-size等
    // source为输入来源，可能值为create或paste

    // 此函数的返回结果，需要过滤掉单位，只返回数值
    if (unit.indexOf('rem')) {
      return parseFloat(unit) * this.props.sizeBase!;
    } else {
      return parseFloat(unit);
    }
  };

  str2EditorState = (value: any) => {
    const { converts, toRem } = this.props;
    try {
      return typeof value === 'string'
        ? BraftEditor.createEditorState(value, {
            unitImportFn: toRem
              ? (converts && converts.unitImportFn) || this.importConvertFn
              : null
          })
        : value;
    } catch (e) {
      console.log(e);
      return value;
    }
  };

  handleOutput = (editorState: EditorState) => {
    const { convertOutPut } = this.props;
    return convertOutPut
      ? convertOutPut(editorState)
      : editorState.isEmpty()
      ? ''
      : editorState.toHTML();
  };

  handletInput = (value: any) => {
    // if typeof input is string,
    // transer to editorstate;
    // else use value directly
    const { convertInput } = this.props;
    try {
      return convertInput ? convertInput(value) : this.str2EditorState(value);
    } catch (e) {
      console.log(e);
      return value;
    }
  };

  triggerChange = (editorState: EditorState) => {
    const output = this.handleOutput(editorState);
    if (output === this.props.value) {
      return;
    }

    this.content = output;
    this.props.onChange && this.props.onChange(output);
  };

  shouldComponentUpdate(
    nextProps: IRichTextEditorProps,
    nextState: IRichTextEditorProps
  ) {
    return nextProps.value !== this.content;
  }

  render() {
    const {
      convertOutPut,
      convertInput,
      sizeBase,
      toRem,
      style,
      value,
      className,
      defaultValue,
      onChange,
      media,
      ...options
    } = this.props;

    return (
      <BraftEditor
        className={[styles.editor, className].join(' ')}
        style={{
          border: '1px solid #d1d1d1',
          ...style
        }}
        value={this.handletInput(value)}
        converts={
          toRem
            ? {
                unitImportFn: this.importConvertFn,
                unitExportFn: this.exportConvertFn
              }
            : undefined
        }
        defaultValue={this.handletInput(defaultValue)}
        onChange={this.triggerChange}
        controls={defaultControls}
        media={{ ...media, ...mediaParams }}
        {...options}
      />
    );
  }
}

export default RichTextEditor;
