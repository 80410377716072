import loadable from '@loadable/component';

import { IRouterConfig } from 'router/lib/index';
import { RouteItemProps } from 'router/lib/routers';
import { persistUserStore } from 'store/index';
import { handleToLogin } from '@/router';

import Layout from 'Layouts/index';

// Schema page
// import SchemaCURDTable from 'components/Schema/CURDTable';

// RBAC config
import { RbacRouting } from 'schema/_rbac';
import { StoreRoutingMap } from 'pages/storeManagement';
import { CustomermanagementRoutingMap } from 'pages/CustomerManagement';
import { ContentmanagementRoutingMap } from 'pages/ContentManagement';
import { CommissionManagementRoutingMap } from 'pages/CommissionManagement';
import { AdminRoutingMap } from 'pages/admin';

export type IRouterConfigMap = {
  [path: string]: RouteItemProps;
};

export const routerConfigMap: IRouterConfigMap = {
  ...StoreRoutingMap,
  ...AdminRoutingMap,
  ...CustomermanagementRoutingMap,
  ...ContentmanagementRoutingMap,
  ...CommissionManagementRoutingMap
};

const prefixPath = (path: string) => (path![0] === '/' ? path! : `/${path!}`);

const routerConfig: IRouterConfig = {
  aliveProps: {
    max: 1
  },
  canIVisit(props) {
    // TODO 暂时只是支持通过菜单配置的检查权限，本地写的无法访问, 跟路由合并一起处理
    return persistUserStore.then(store => {
      // 后门 rbac 可以访问
      const authWhiteList = ['/rbac/*'];

      const currentPath = props.location.pathname;

      const route = store.menuList.find(
        v => currentPath === prefixPath(v.path!)
      );

      if (!store.loginResp) {
        return handleToLogin(currentPath);
      }

      // TODO 现在是没有权限跳转第一个路由， 可以跳转403
      if (!route && store.menuTree.length) {
        if (authWhiteList.some(v => new RegExp(v).test(currentPath))) return;
        props.history.replace({
          pathname: prefixPath(store.menuTree[0]!.path!)
        });
        return false;
      }
    });
  },
  routers: [
    RbacRouting,
    {
      path: '/login',
      component: loadable(() =>
        import(/* webpackChunkName: "Login" */ '@/pages/Login')
      )
    },
    {
      path: '/404',
      exact: true,
      component: loadable(() =>
        import(/* webpackChunkName: "404" */ '@/pages/404')
      )
    },
    {
      path: '/',
      component: Layout,
      subRouter: [
        {
          to: 'customermanagement/customerlist'
        }
      ]
    },
    {
      to: '/404'
    }
  ]
};

export { routerConfig };
