import React, { useState } from 'react';
import { Icon, Dropdown, Menu, Tooltip, Breadcrumb } from 'antd';
import screenfull from 'screenfull';
import { Link } from 'react-router-dom';

import { MenuItem, IMenuModel } from './menu';
import { IRoutercomponentProps } from '@/router/lib/routers';

import { IUserStoreProp, IAppConfigStoreProp } from 'store/index';

import './header.scss';

type IHeaderProps = IUserStoreProp &
  IAppConfigStoreProp &
  IRoutercomponentProps;

const screenfullUtil = screenfull! as screenfull.Screenfull;

function Header(props: Partial<IHeaderProps>) {
  const { userInfo, LoginOutAsync } = props.userStore!;
  const { isMenuCollapsed, toggleCollapsed } = props.appStore!;

  const { userStore, location } = props;
  const currentPath = location!.pathname;

  const [isFullscreen, setFullscreen] = useState<boolean>(
    screenfullUtil.isFullscreen
  );

  React.useEffect(() => {
    let isSubscribed = true;
    screenfullUtil.onchange(() => {
      if (isSubscribed) {
        setFullscreen(screenfullUtil.isFullscreen);
      }
    });
    return () => {
      isSubscribed = false;
    };
  });
  // TODO 需要支持自定义
  function renderBreadcrumb(
    currentPath: string,
    menuTree: MenuItem[],
    menuMap: { [menuId: number]: IMenuModel }
  ) {
    const prefixPath = (path: string) =>
      path![0] === '/' ? path! : `/${path!}`;

    function findMenuItem(
      paths: string[],
      subMenu: MenuItem[] = [],
      breadcrumb: MenuItem[] = []
    ): MenuItem[] {
      if (paths.length) {
        const next = paths.slice(1);
        const item = subMenu.find(
          v => prefixPath(v.path!) === prefixPath(paths[0])
        );
        if (item) {
          breadcrumb.push(item);
          return findMenuItem(next, item.subMenu, breadcrumb);
        }
      }
      return breadcrumb;
    }

    let breadcrumb = findMenuItem(
      currentPath
        .split('/')
        .filter(String)
        .map((v, i, arr) =>
          prefixPath(arr[i - 1] ? [arr[i - 1], v].join('/') : v)
        ),
      menuTree
    );

    const last = breadcrumb[breadcrumb.length - 1];

    // 如果配置面包屑则用配置的面包屑
    if (
      last &&
      last.ex &&
      last.ex.breadcrumbIds &&
      last.ex.breadcrumbIds.length
    ) {
      breadcrumb = last.ex.breadcrumbIds.map(v => menuMap[v]).filter(v => v);
    }

    return (
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">首页</Link>
        </Breadcrumb.Item>
        {breadcrumb.map((item, i) => (
          <Breadcrumb.Item key={item.id}>
            {item.subMenu ? (
              <span>{item.readable_name}</span>
            ) : (
              <Link replace to={prefixPath(breadcrumb[i].path!)}>
                {item.readable_name}
              </Link>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }

  return (
    <div className="header">
      <div className="header__left">
        <Icon
          onClick={() => toggleCollapsed()}
          className="header-trigger"
          type={!isMenuCollapsed ? 'menu-fold' : 'menu-unfold'}
        />
        {renderBreadcrumb(
          currentPath,
          userStore!.menuTree!,
          userStore!.menuMap!
        )}
      </div>
      <div className="header__right">
        {screenfull.isEnabled ? (
          <div className="header__right-item">
            <Tooltip
              trigger="hover"
              title={isFullscreen ? '退出全屏' : '进入全屏'}
            >
              <Icon
                onClick={() => {
                  screenfullUtil.toggle();
                }}
                type={!isFullscreen ? 'fullscreen' : 'fullscreen-exit'}
              />
            </Tooltip>
          </div>
        ) : null}
        <Dropdown
          placement="bottomCenter"
          overlay={
            <Menu>
              <Menu.Item onClick={LoginOutAsync}>
                <Icon type="logout" />
                退出登录
              </Menu.Item>
            </Menu>
          }
        >
          <div className="header__right-item header-user">
            <span>{userInfo.account ? userInfo.account!.phone : ''}</span>
            <Icon type="down" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
