import { getBannerConfig } from './bannerConfig';
import {
  Shensuo_ModelBanner_Type,
  Shensuo_StoreType
} from '@/services/enumTypes';
import { StoreServices } from '@/services/apiServices.js';

export enum BannerJumpType {
  NIL = 0,
  STORE = Shensuo_ModelBanner_Type.TypeStorePage
}

const jumpType = {
  [BannerJumpType.NIL]: '不跳转',
  [BannerJumpType.STORE]: '商品'
};

export default getBannerConfig({
  defaultReq: {
    type: Shensuo_ModelBanner_Type.TypeStorePage
  },
  jumpType,
  async getJumpTypeOptions(jumpType: number) {
    switch (jumpType) {
      case BannerJumpType.STORE:
        const { list } = await StoreServices.GetProductListAdmin({
          list_option: {},
          store_id: Shensuo_StoreType.StoreTypeComm
        });
        return {
          options: list!,
          option: {
            valueKey: 'id',
            labelKey: 'name'
          }
        };
      default:
        break;
    }
    return {
      options: []
    };
  }
});
