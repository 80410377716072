import React from 'react';

import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import SchemaForm, { IFormSchema, Form } from '@/components/Schema/Form';
import SchemaDialog from '@/components/Schema/Dialog';
import CURDTable from '@/components/Schema/CURDTable';
import { toJS } from 'mobx';
import { message, Modal } from 'antd';
import { OmsServices } from 'services/apiServices.js';
import {
  Oms_ListOption,
  Oms_SaveOrderAdminReq_SaveType,
  Oms_OrderState
} from 'services/enumTypes';
import t from '@/libs/utils';
import moment from 'moment';
import { ShensuoServices, StoreServices } from 'services/apiServices';

type IFetchListReq = oms.GetOrderListAdminReq;
type IFetchListRsq = oms.GetOrderListAdminRsp;
type IOrderModel = oms.ModelOrder;
type IAddModel = DeliverPruduct;

type SchemaState = {
  addDialog: boolean;
  deliverPruduct: DeliverPruduct;
  orderDetail: oms.OrderDetail;
};
type DeliverPruduct = {
  order_number?: string;
  express_number?: string;
  express_company?: string;
};
let fetchData: any[] | never[] = [];
const OrderList: ICURDTableSchema<IOrderModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '导出',
        async onClick(this: CURDTable) {
          let timeRange = this.searchFormRef!.props.form.getFieldValue(
            Oms_ListOption.ListOptionCreatedAt.toString()
          );
          let orderState = this.searchFormRef!.props.form.getFieldValue(
            Oms_ListOption.ListOptionState.toString()
          );
          let goods_name = this.searchFormRef!.props.form.getFieldValue(
            Oms_ListOption.ListOptionSearch2.toString()
          );
          orderState = orderState ? orderState : 0;
          goods_name = goods_name ? goods_name : '';
          timeRange = timeRange
            ? timeRange.map((v: moment.Moment, idx: number) => {
                if (idx === 0)
                  return moment(v)
                    .startOf('day')
                    .unix();
                else
                  return moment(v)
                    .endOf('day')
                    .unix();
              })
            : undefined;
          let start_at = timeRange ? timeRange[0] : 0;
          let end_at = timeRange ? timeRange[1] : 9999999999;
          const {
            download_url
          } = await ShensuoServices.ExportOnlineStoreOrderList({
            order_state: orderState,
            start_at: start_at,
            end_at: end_at,
            goods_name: goods_name
          });
          if (download_url) {
            window.location.href = download_url;
          } else {
            message.warning('导出失败');
          }
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                Object.keys(value).forEach(item => {
                  if (Number(item) === Oms_ListOption.ListOptionCreatedAt) {
                    value[item] = value[item]
                      ? value[item].map((v: moment.Moment, idx: number) => {
                          if (idx === 0)
                            return moment(v)
                              .startOf('day')
                              .unix();
                          else
                            return moment(v)
                              .endOf('day')
                              .unix();
                        })
                      : undefined;
                  }
                });
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '订单状态',
              type: 'select',
              placeholder: '请选择订单状态',
              componentProps: {
                style: {
                  width: 200
                }
              },
              selectOptions: [
                { id: Oms_OrderState.OrderStateUndefined, status: '全部' },
                { id: Oms_OrderState.OrderStateReady2Pay, status: '待付款' },
                { id: Oms_OrderState.OrderStatePaid, status: '待发货' },
                { id: Oms_OrderState.OrderStateDelivering, status: '已发货' },
                {
                  id: Oms_OrderState.OrderStateConfirmFinished,
                  status: '已完成'
                },
                { id: Oms_OrderState.OrderStateSysCanceled, status: '已关闭' }
              ],
              selectOption: {
                valueKey: 'id',
                labelKey: 'status'
              },
              propName: Oms_ListOption.ListOptionState
            },
            {
              label: '收件人',
              type: 'input',
              placeholder: '请输入收件人',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName: Oms_ListOption.ListOptionFuzzySearch1
            },
            {
              label: '收件人手机号',
              type: 'input',
              placeholder: '请输入手机号',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName: Oms_ListOption.ListOptionSearch1
            },
            {
              label: '订单号码',
              type: 'input',
              placeholder: '请输入订单号',
              componentProps: {
                style: {
                  width: 200
                }
              },
              propName: Oms_ListOption.ListOptionOrderNumber
            },
            {
              label: '商品名称',
              type: 'input',
              placeholder: '请输入商品名称',
              componentProps: {
                style: {
                  width: 200
                }
              },
              propName: Oms_ListOption.ListOptionSearch2
            },
            {
              label: '选择时间',
              type: 'date-range-picker',
              componentProps: {
                style: {
                  width: 400
                }
              },
              propName: Oms_ListOption.ListOptionCreatedAt
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: ({ order }: any) => {
      return String(order!.id!);
    },
    scroll: { x: 1400, y: 'calc(100vh - 220px)' },
    columns: [
      {
        title: '操作',
        width: 160,
        fixed: 'left',
        dataType: 'btn-group',
        renderBtnGroup(record: any) {
          const _btns = [
            {
              type: 'link',
              size: 'small',
              children: '查看详情',
              onClick(this: SchemaCURDTable) {
                // @ts-ignore
                this.props.history.push({
                  pathname: '/storeManagement/orderDetail',
                  search: '?id=' + record!.order.id
                });
              }
            },
            {
              type: 'link',
              children: '发货',
              size: 'small',
              style: {
                color: 'rgb(255, 77, 79)'
              },
              onClick(this: SchemaCURDTable) {
                if (record!.order.delivery_id === '99999') {
                  const _this = this;
                  Modal.confirm({
                    title: '是否确认发货',
                    async onOk() {
                      await OmsServices.SaveOrderBySceneAdmin({
                        order_id: record!.order.id
                      });
                      try {
                        message.success('新建成功');
                        _this.listService.refreshList();
                      } catch (error) {}
                    }
                  });
                } else {
                  this.methods.toggleModelDialog(record);
                }
              }
            }
          ];
          const btns: any[] =
            record!.order!.order_state !== 3
              ? t.deepClone(_btns).splice(0, 1)
              : t.deepClone(_btns);
          return {
            needDivider: false,
            btns
          };
        }
      },
      {
        title: '订单编号',
        dataType: 'status',
        statusFn(record: any) {
          return record!.order!.order_number || '-';
        }
      },
      {
        title: '商品名称',
        render(record: any) {
          return (
            <span>
              {record!
                .skus!.map(
                  (_: oms.OrderSku, i: number) =>
                    `${_!.sku_info!.name}${_!.sku_info!.spec_value &&
                      `(${_!.sku_info!.spec_value})`}`
                )
                .join('、')}
            </span>
          );
        }
      },
      {
        title: '实付金额',
        dataType: 'status',
        statusFn(record: any) {
          return t.div(record!.order!.pay_money, 100) || '-';
        }
      },
      // {
      //   dataIndex: 'uid',
      //   title: '下单人',
      //   width: 300
      // },
      {
        title: '收件人',
        dataType: 'status',
        statusFn(record: any) {
          return record!.delivery!.receiver_name || '-';
        }
      },
      {
        title: '下单时间',
        dataType: 'status',
        statusFn(record: any) {
          return moment(record!.order!.created_at * 1000).format(
            'YYYY-MM-DD HH:mm:ss'
          );
        }
      },
      {
        title: '订单状态',
        dataType: 'status',
        statusFn: (record: any) => {
          let order_state = record!.order!.order_state;
          let state = '';
          switch (order_state) {
            case Oms_OrderState.OrderStateReady2Pay:
              state = '待付款';
              break;
            case Oms_OrderState.OrderStatePaid:
              state = '待发货';
              break;
            case Oms_OrderState.OrderStateDelivering:
              state = '待收货';
              break;
            case Oms_OrderState.OrderStateConfirmReceived:
              state = '已收货';
              break;
            case Oms_OrderState.OrderStateConfirmFinished:
              state = '已完成';
              break;
            case Oms_OrderState.OrderStateSysCanceled:
              state = '已关闭';
              break;
            default:
              state = '-';
          }
          return state;
        }
      }
    ]
  },
  observableData: {
    addDialog: false,
    deliverPruduct: {},
    orderDetail: {},
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      console.log('result',result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
      }
      this.loading = result.loading;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    toggleModelDialog(this: SchemaCURDTable, record: oms.OrderDetail = {}) {
      if (Object.keys(record).length) {
        this.observableData.deliverPruduct = {
          order_number: record ? record!.order!.order_number : ''
        };
        this.observableData.orderDetail = record;
      }
      this.observableData.addDialog = !this.observableData.addDialog;
    },

    async componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(OmsServices.GetOrderListAdmin);
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  },
   customNode(this: SchemaCURDTable) {
    const { observableData } = this;
    const { addDialog, orderDetail } = toJS(observableData) as SchemaState;
    const {
      observableData: { deliverPruduct }
    } = this;

    // TODO mount dialog service
    return (
      <SchemaDialog
        schema={{
          visible: addDialog,
          title: '发货',
          children: (
            <SchemaForm
              wrappedComponentRef={(ref: any) => {
                this.customNodeRef.addMenuDialog = ref;
              }}
              schema={
                {
                  labelCol: { span: 4 },
                  wrapperCol: { span: 14 },
                  labelAlign: 'right',

                  methods:{
                    async componentDidMount(this: Form) {
                      // let fetchData: any[] | never[] = [];
                      // @ts-ignore
                      fetchData =  await StoreServices.getDictionaryExpressTable({});
                      this.updateFormItemData('expressCompanyId', {
                        selectOptions: fetchData
                      });
                    }
                  },
                  rows: [
                    [
                      {
                        label: '订单编号',
                        propName: 'order_number',
                        type: 'input',
                        placeholder: '请输入订单编号',
                        componentProps: {
                          disabled: true
                        },
                        FieldDecoratorOptions: {
                          initialValue: deliverPruduct!.order_number
                        }
                      },
                      {
                        label: '物流公司',
                        propName: 'expressCompanyId',
                        type: 'select',
                        selectOptions: [],
                        selectOption: {
                          valueKey: 'id',
                          labelKey: 'comName'
                        },
                        needObservable: true,
                        placeholder: '请选择物流公司',
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required: true,
                              message: '请选择物流公司'
                            }
                          ]
                        }
                      }
                    ],
                    [
                      {
                        label: '物流编号',
                        propName: 'express_number',
                        type: 'input',
                        placeholder: '请输入物流编号',
                        FieldDecoratorOptions: {
                          rules: [
                            {
                              required: true,
                              message: '请输入物流编号',
                              whitespace: true
                            }
                          ]
                        }
                      }
                    ]
                  ]
                } as IFormSchema<IAddModel>
              }
            />
          ),
          onCancel: () => {
            this.methods.toggleModelDialog();
            this.customNodeRef.addMenuDialog.props.form.resetFields();
          },
          onOk: async () => {
            let expressCompanyName = '';
            console.log('express', this, fetchData);
            this.customNodeRef.addMenuDialog.props.form.validateFields(
              async (errors: Error[], values: any) => {
                fetchData.forEach(item=>{
                  if(item.id === values.expressCompanyId){
                    expressCompanyName = item.comName;
                  };
                })
                console.log('values',values);
                console.log('comName',expressCompanyName);
                if (!errors) {
                  let _values = t.deepClone(orderDetail);
                  console.log('dddd',_values)
                  let deliveryId = _values.delivery;
                  let omsOrderId = _values.order;
                  const ids = JSON.parse(JSON.stringify(deliveryId)).id
                  const omsOrderIs = JSON.parse(JSON.stringify(omsOrderId)).id
                  await OmsServices.SaveOrderAdmin({
                    order: values,
                    deliveryId:ids,
                    omsOrderId:omsOrderIs,
                    expressNumber:values.express_number,
                    expressCompany:expressCompanyName,
                    expressCompanyId:values.expressCompanyId,
                    save_type:
                      Oms_SaveOrderAdminReq_SaveType.SaveTypeSendDeliver
                  });
                  message.success('新建成功');
                  this.methods.toggleModelDialog();
                  this.customNodeRef.addMenuDialog.props.form.resetFields();
                  this.listService.refreshList();
                }
              }
            );
          }
        }}
      />
    );
  }
};

export default OrderList;
