import loadable from '@loadable/component';

import SchemaCURDTable from '@/components/Schema/CURDTable';
import { IRouterConfigMap } from '@/router/routing';

import user from './user';
import dept from './dept';

export const AdminRoutingMap: IRouterConfigMap = {
  '/admin/dept': {
    schema: dept,
    component: SchemaCURDTable
  },
  '/admin/editperm': {
    component: loadable(() =>
      import(/* webpackChunkName: "editpPrm" */ './editperm')
    )
  },
  '/admin/users': {
    schema: user,
    component: SchemaCURDTable
  }
};
