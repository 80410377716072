import { WeblogicServices } from '@/services/apiServices';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  Storage_ModelConfig_StorageType,
  Storage_ModelResource_State as UploadResult
} from '@/services/enumTypes';

import cosAuth from './tc/cos-auth';
import { Base64 } from './ali/base64';
import './ali/crypto';
import './ali/hmac';
import './ali/sha1';

type QiniuReq = {
  token: string;
  key: string;
  domain: string;
};

type TencentCloudReq = {
  key: string;
  Signature: string;
  'x-cos-security-token': string;
  'Content-Type': string;
};

type AliReq = {
  name: string;
  key: string;
  policy: string;
  OSSAccessKeyId: string;
  success_action_status?: '200' | '204';
  signature: string;
  'x-oss-security-token': string;
};

type IUploadParams = QiniuReq | TencentCloudReq | AliReq | null;

type IMeasureImgResult = {
  height: number;
  width: number;
};

function measureImgResolution(
  file: UploadFile
): PromiseLike<IMeasureImgResult> {
  return new Promise((resolve, reject) => {
    try {
      const img = document.createElement('img');
      const reader = new FileReader();
      let size: IMeasureImgResult = { height: 0, width: 0 };
      img.onload = () => {
        size.height = img.naturalHeight;
        size.width = img.naturalWidth;
        resolve(size);
      };
      img.onerror = e => {
        reject(e);
      };
      reader.addEventListener('load', () => {
        img.src = reader.result as string;
      });
      // @ts-ignore
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
}

function safeProtocol(url: string) {
  url = url.replace(/http:|https:/g, window.location.protocol);
  if (url.indexOf('http') !== 0) {
    url = window.location.protocol + '//' + url;
  }
  return url;
}

async function getUploadUrlAndParams(preUploadReq: weblogic.PreUploadReq) {
  const resp = await WeblogicServices.PreUpload(preUploadReq);
  const { storage_type, qn_token, tc_token, ali_token, resource } = resp;
  let uploadUrl = '';
  let uploadParams: IUploadParams = null;

  switch (storage_type!) {
    case Storage_ModelConfig_StorageType.StorageTypeQiniu:
      uploadUrl = safeProtocol(qn_token!.upload_url!);
      uploadParams = {
        token: qn_token!.token,
        key: resource!.path!,
        domain: resource!.domain!
      } as QiniuReq;
      break;
    case Storage_ModelConfig_StorageType.StorageTypeTCCloud:
      uploadUrl = safeProtocol(tc_token!.upload_url!);
      uploadParams = {
        Signature: cosAuth({
          SecretId: tc_token!.secret_id!,
          SecretKey: tc_token!.secret_key!,
          Method: 'POST',
          Pathname: '/'
        }),
        key: resource!.path!,
        'x-cos-security-token': tc_token!.token!,
        'Content-Type': resource!.content_type!
      } as TencentCloudReq;
      break;
    case Storage_ModelConfig_StorageType.StorageTypeAliyun:
      uploadUrl = safeProtocol(ali_token!.upload_url!);
      // 1天上传有效期
      const policyText = {
        expiration:
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
            .toISOString()
            .replace(/\..+/, '') + 'Z', // 设置Policy的失效时间，如果超过失效时间，就无法通过此Policy上传文件 格式ISO8601 GMT
        conditions: [
          ['content-length-range', 0, 1048576000] // 设置上传文件的大小限制，如果超过限制，文件上传到OSS会报错
        ]
      };
      const policy = Base64.encode(JSON.stringify(policyText));
      // @ts-ignore
      const bytes = Crypto.HMAC(
        // @ts-ignore
        Crypto.SHA1,
        policy,
        ali_token!.access_key_secret,
        {
          asBytes: true
        }
      );
      // @ts-ignore
      const signature = Crypto.util.bytesToBase64(bytes);

      uploadParams = {
        key: resource!.path,
        policy,
        OSSAccessKeyId: ali_token!.access_key_id,
        signature,
        success_action_status: '200',
        'x-oss-security-token': ali_token!.token
      } as AliReq;
      break;
    default:
      break;
  }

  return {
    uploadUrl,
    uploadParams,
    resp
  };
}

async function getUploadResult(req: weblogic.UploadCallBackReq) {
  return WeblogicServices.UploadCallBack(req);
}

export { measureImgResolution };

export {
  getUploadUrlAndParams,
  getUploadResult,
  UploadResult,
  QiniuReq,
  TencentCloudReq,
  IUploadParams
};
