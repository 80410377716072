import SchemaCURDTable, {
  ICURDTableSchema
} from '@/components/Schema/CURDTable';
import CURDTable from '@/components/Schema/CURDTable';
import { ShensuoServices } from 'services/apiServices.js';
import { Shensuo_GetOnlineLessonOrderListAdminReq_ListOption } from 'services/enumTypes';
import t from '@/libs/utils';
import moment from 'moment';
import styles from './index.module.scss';
import { message } from 'antd';

type IFetchListReq = shensuo.GetOnlineLessonOrderListAdminReq;
type IFetchListRsq = shensuo.GetOnlineLessonOrderListAdminRsp;
type IModel = shensuo.ModelOnlineLessonOrder;

type SchemaState = {
  user_map?: { [key: number]: shensuo.ModelUser };
  referrer_map?: { [key: number]: shensuo.ModelUser };
};

const LessonOrderList: ICURDTableSchema<IModel, SchemaState, IFetchListRsq> = {
  header: {
    action: [
      {
        type: 'primary',
        children: '导出',
        async onClick(this: CURDTable) {
          let timeRange = this.searchFormRef!.props.form.getFieldValue(
            Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionTimeRange.toString()
          );
          timeRange = timeRange
            ? timeRange.map((v: moment.Moment, idx: number) => {
                if (idx === 0)
                  return moment(v)
                    .startOf('day')
                    .unix();
                else
                  return moment(v)
                    .endOf('day')
                    .unix();
              })
            : undefined;
          let start_at = timeRange ? timeRange[0] : 0;
          let end_at = timeRange ? timeRange[1] : 9999999999;
          const {
            download_url
          } = await ShensuoServices.ExportOnlineLessonOrderList({
            start_at: start_at,
            end_at: end_at
          });
          if (download_url) {
            window.location.href = download_url;
          } else {
            message.warning('导出失败');
          }
        }
      }
    ],
    query: {
      schema: {
        layout: 'inline',
        rowProps: {
          type: 'flex'
        },
        onSubmit(this: CURDTable, e) {
          e.preventDefault();
          this.searchFormRef!.props.form.validateFields(
            async (errors, value) => {
              if (!errors) {
                Object.keys(value).forEach(item => {
                  if (
                    Number(item) ===
                    Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionTimeRange
                  ) {
                    value[item] = value[item]
                      ? value[item].map((v: moment.Moment, idx: number) => {
                          if (idx === 0)
                            return moment(v)
                              .startOf('day')
                              .unix();
                          else
                            return moment(v)
                              .endOf('day')
                              .unix();
                        })
                      : undefined;
                  }
                });
                this.listService.mapToOptions(value);
                this.listService.refreshList();
              }
            }
          );
        },
        onReset(this: CURDTable) {
          this.searchFormRef!.props.form.resetFields();
          this.listService.resetOptionsMap();
          this.listService.refreshList();
        },
        rows: [
          [
            {
              label: '购买人',
              type: 'input',
              placeholder: '请输入购买人',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName:
                Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionUserName
            },
            {
              label: '购买人手机号码',
              type: 'input',
              placeholder: '请输入购买人手机号码',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off',
                maxLength: 11
              },
              propName:
                Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionUserPhone
            },
            {
              label: '邀请人',
              type: 'input',
              placeholder: '请输入邀请人',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off'
              },
              propName:
                Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionReferrerName
            },
            {
              label: '邀请人手机号码',
              type: 'input',
              placeholder: '请输入邀请人手机号码',
              componentProps: {
                style: {
                  width: 200
                },
                autoComplete: 'off',
                maxLength: 11
              },
              propName:
                Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionReferrerPhone
            },
            {
              label: '选择时间',
              type: 'date-range-picker',
              componentProps: {
                style: {
                  width: 400
                }
              },
              propName:
                Shensuo_GetOnlineLessonOrderListAdminReq_ListOption.ListOptionTimeRange
            },
            {
              renderBtnGroup() {
                return {
                  needDivider: false,
                  btns: [
                    {
                      type: 'primary',
                      children: '查询',
                      htmlType: 'submit'
                    },
                    {
                      style: {
                        marginLeft: 10
                      },
                      htmlType: 'reset',
                      children: '重置'
                    }
                  ]
                };
              }
            }
          ]
        ]
      }
    }
  },
  table: {
    rowKey: 'id',
    rowClassName: () => styles.row,
    scroll: { x: 900, y: 'calc(100vh - 220px)' },
    columns: [
      {
        dataIndex: 'oms_order_number',
        title: '订单编号'
      },
      {
        title: '购买人',
        ellipsis: true,
        render(this: SchemaCURDTable, record: IModel) {
          const user_map = this.observableData.user_map;
          const user = user_map
            ? user_map[record.uid!]
              ? `${user_map[record.uid!].name}(${user_map[record.uid!].phone})`
              : record.user_name
            : record.user_name;
          return user;
        }
      },
      {
        dataIndex: 'detail',
        title: '课程名称',
        ellipsis: true,
        render(record: shensuo.ModelOnlineLessonOrder_Detail) {
          return record.name;
        }
      },
      {
        dataIndex: 'price',
        title: '课程价格',
        render(record: number) {
          return t.div(record, 100).toFixed(2);
        }
      },
      {
        dataIndex: 'paid_at',
        title: '下单时间',
        dataType: 'date',
        ellipsis: true
      },
      {
        title: '推荐人',
        render(this: SchemaCURDTable, record: IModel) {
          const referrer_map = this.observableData.referrer_map;
          const refferrer = referrer_map
            ? referrer_map[record.user_referrer_id!]
              ? `${referrer_map[record.user_referrer_id!].name}(${
                  referrer_map[record.user_referrer_id!].phone
                })`
              : record.user_referrer_name
            : record.user_referrer_name;
          return refferrer;
        }
      },
      {
        title: '返佣金额',
        render(record: IModel) {
          if (!!!Number(record.user_referrer_id)) {
            return '0.00';
          }
          return t.div(record.commission!, 100).toFixed(2);
        }
      }
    ]
  },
  observableData: {
    user_map: {},
    referrer_map: {}
  },
  methods: {
    getFetchListResult(this: SchemaCURDTable, result) {
      // console.log(result);
      if (result.resp!) {
        this.table.dataSource = result.resp!.list;
        this.observableData.user_map = result.resp!.user_map;
        this.observableData.referrer_map = result.resp!.referrer_map;
      }
      this.loading = result.loading;
    },

    componentDidActivate(this: SchemaCURDTable) {
      this.listService.refreshCurPageList();
    },

    componentDidMount(this: SchemaCURDTable) {
      this.listService.setFetchListFn(
        ShensuoServices.GetOnlineLessonOrderListAdmin
      );
      this.listService.setReq<IFetchListReq>({
        list_option: {}
      });
      this.listService.getList();
    }
  }
};

export default LessonOrderList;
