import React from 'react';
import { Spin } from 'antd';

type ILoadingPageProps = {
  loaded: () => Promise<any> & any;
  delay?: number;
  children: JSX.Element & React.ReactNode;
};

function LoadingPage(props: ILoadingPageProps) {
  const [loading, setLoading] = React.useState(true);
  Promise.resolve(props.loaded()).finally(() =>
    setTimeout(() => setLoading(false), props.delay || 0)
  );

  return loading ? (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin />
    </div>
  ) : (
    props.children!
  );
}

export { LoadingPage, ILoadingPageProps };
