import React from 'react';

/**
 * 组件销毁屏蔽 setState
 * @param target React.ComponentClass
 */
export function injectUnmount<T>(target: React.ComponentClass<T>) {
  const next = target.prototype.componentWillUnmount;
  target.prototype.componentWillUnmount = function() {
    if (next) next.call(this, ...arguments);
    this.unmount = true;
  };

  const setState = target.prototype.setState;
  target.prototype.setState = function() {
    if (this.unmount) return;
    setState.call(this, ...arguments);
  };
}

export function defaultProps<T>(props: T) {
  return (target: React.ComponentClass<any>) => {
    target.defaultProps = {
      ...target.defaultProps,
      ...props
    };
  };
}
