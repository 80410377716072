import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal.d';

import { SchemaProps, SchemaState, SchemaComponent } from '../Schema';

// import style from './index.module.scss';

type IDialogSchema = React.PropsWithChildren<ModalProps & {}>;

type ISchemaDialogProps = SchemaProps<IDialogSchema>;
type ISchemaDialogState = SchemaState<IDialogSchema>;

@observer
class Dialog extends SchemaComponent<
  IDialogSchema,
  ISchemaDialogProps,
  ISchemaDialogState
> {
  render() {
    const schemaConfig = toJS(this.props.schema);

    return <Modal {...schemaConfig} />;
  }
}

export default Dialog;

export { IDialogSchema, ISchemaDialogProps, ISchemaDialogState };
