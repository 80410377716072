import { IRouterConfigMap } from 'router/routing';
import loadable from '@loadable/component';
import SchemaCURDTable from 'components/Schema/CURDTable';

import MessageList from './MessageList/index';
import banner_list from './lessonBannerList/index';
import ApplyScheduleList from './ApplyScheduleList/index';
import OnlineLessonList from './onlineLessonList/index';
import { bindLifecycle } from '@/router/lib/keepAlive';

export enum routerName {
  CONTENTMANAGEMENT_BANNERLIST = '/contentmanagement/bannerlist',
  CONTENTMANAGEMENT_BANNEREDIT = '/contentmanagement/bannerEdit',
  CONTENTMANAGEMENT_BANNERADD = '/contentmanagement/bannerAdd',
  CONTENTMANAGEMENT_BANNER2THIRD = '/contentmanagement/banner2third',
  CONTENTMANAGEMENT_SCHEDULELIST = '/contentmanagement/scheduleList',
  CONTENTMANAGEMENT_SCHEDULEDETAIL = '/contentmanagement/scheduleDetail',
  CONTENTMANAGEMENT_SCHEDULEEDIT = '/contentmanagement/scheduleEdit',
  CONTENTMANAGEMENT_SCHEDULEADD = '/contentmanagement/scheduleAdd',
  CONTENTMANAGEMENT_LESSONLIST = '/contentmanagement/lessonlist',
  CONTENTMANAGEMENT_MESSAGELIST = '/contentmanagement/messageList',
  CONTENTMANAGEMENT_MESSAGEDETAIL = '/contentmanagement/messageDetail',
  CONTENTMANAGEMENT_LESSSONBANNERLIST = '/contentmanagement/lessonbannerlist',
  CONTENTMANAGEMENT_SIGNINDETAIL = '/contentmanagement/signInDetail',
  CONTENTMANAGEMENT_APPLYSCHEDULELIST = '/contentmanagement/applyScheduleList',
  CONTENTMANAGEMENT_ONLINELESSONLIST = '/contentmanagement/onlineLessonList',
  CONTENTMANAGEMENT_ONLINELESSONEDIT = '/contentmanagement/onlineLessonEdit',
  CONTENTMANAGEMENT_ONLINELESSONADD = '/contentmanagement/onlineLessonAdd',
  CONTENTMANAGEMENT_ONLINELESSONDETAIL = '/contentmanagement/onlineLessonDetail'
}

export const ContentmanagementRoutingMap: IRouterConfigMap = {
  [routerName.CONTENTMANAGEMENT_BANNERLIST]: {
    component: loadable(() => import('./BannerList/index'))
  },
  [routerName.CONTENTMANAGEMENT_BANNEREDIT]: {
    component: loadable(() => import('./BannerDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_BANNERADD]: {
    component: loadable(() => import('./BannerDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_BANNER2THIRD]: {
    component: loadable(() => import('./Banner2Third/index'))
  },

  [routerName.CONTENTMANAGEMENT_SCHEDULELIST]: {
    needAlive: true,
    component: loadable(() => import('./ScheduleList/index'))
  },
  [routerName.CONTENTMANAGEMENT_SCHEDULEDETAIL]: {
    component: loadable(() => import('./ScheduleDetailView/index'))
  },
  [routerName.CONTENTMANAGEMENT_SCHEDULEEDIT]: {
    component: loadable(() => import('./ScheduleDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_SCHEDULEADD]: {
    component: loadable(() => import('./ScheduleDetail/index'))
  },

  [routerName.CONTENTMANAGEMENT_LESSONLIST]: {
    component: loadable(() => import('./LessonList/index'))
  },
  [routerName.CONTENTMANAGEMENT_MESSAGELIST]: {
    needAlive: true,
    schema: MessageList,
    component: bindLifecycle(SchemaCURDTable)
  },
  [routerName.CONTENTMANAGEMENT_MESSAGEDETAIL]: {
    component: loadable(() => import('./MessageDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_LESSSONBANNERLIST]: {
    schema: banner_list,
    component: SchemaCURDTable
  },
  [routerName.CONTENTMANAGEMENT_SIGNINDETAIL]: {
    component: loadable(() => import('./SignInDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_APPLYSCHEDULELIST]: {
    needAlive: true,
    schema: ApplyScheduleList,
    component: bindLifecycle(SchemaCURDTable)
  },
  [routerName.CONTENTMANAGEMENT_ONLINELESSONLIST]: {
    needAlive: true,
    schema: OnlineLessonList,
    component: bindLifecycle(SchemaCURDTable)
  },
  [routerName.CONTENTMANAGEMENT_ONLINELESSONDETAIL]: {
    component: loadable(() => import('./onlineLessonDetailView/index'))
  },
  [routerName.CONTENTMANAGEMENT_ONLINELESSONEDIT]: {
    component: loadable(() => import('./onlineLessonDetail/index'))
  },
  [routerName.CONTENTMANAGEMENT_ONLINELESSONADD]: {
    component: loadable(() => import('./onlineLessonDetail/index'))
  }
};
