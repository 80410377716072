import React from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  RedirectProps,
  RouteComponentProps,
  SwitchProps,
  Switch
} from 'react-router-dom';
import queryString from 'query-string';

import KeepAlive from './keepAlive/KeepAlive';

type IRoutercomponentProps<T = any, S = any> = RouteComponentProps<T> & {
  childView?: React.ReactNode;
  schema: S;
  query: T;
};

type IRoutercomponent = React.ComponentType<IRoutercomponentProps | any>;

type RouteItemProps = RouteProps & {
  subRouter?: IRouterConfItem[];
  component?: IRoutercomponent;
  schema?: any;
  needAlive?: boolean;
  // TODO 暂未支持
  meta?: {
    title?: string;
    icon?: string;
    breadcrumb?: boolean;
    [key: string]: any;
  };
};

type IRouterConfItem = RouteItemProps | RedirectProps;

function Defaultcomponent(com?: IRoutercomponent) {
  return (
    com ||
    function Defaultcomponent(props: IRoutercomponentProps) {
      return <React.Fragment>{props.childView!}</React.Fragment>;
    }
  );
}

function prefixParentPath(
  parentPath: string | string[],
  path: string | string[]
) {
  // TODO debug
  if (parentPath instanceof Array || path instanceof Array) {
    return path;
  }
  return [
    '',
    ...[parentPath, path]
      .map(v => {
        if (v && v[0] === '/') {
          return v.slice(1);
        }
        return v;
      })
      .filter(String)
  ].join('/');
}

// TODO 渲染错误组件
type ICanIVisitFn = {
  (props: IRoutercomponentProps):
    | Promise<
        {
          canVisit: boolean;
          compoment: React.ComponentType<any>;
        } & any
      >
    | boolean;
};

type ICanIVisitOptions = {
  loadingCompoment?: React.ReactNode | string;
  permissionDeniedCompoment?: React.ReactNode | string;
};

type IAuthRouter = IRoutercomponentProps & {
  children: React.ComponentType<IRoutercomponentProps> & React.ReactNode & any;
  canIVisit?: ICanIVisitFn;
  canIVisitOptions?: ICanIVisitOptions;
};

function AuthRouter(props: IAuthRouter) {
  const {
    canIVisit,
    canIVisitOptions = {
      loadingCompoment: null,
      permissionDeniedCompoment: null
    },
    children,
    ...routerProps
  } = props;
  const [hasAuth, setAuth] = React.useState(!!!canIVisit);
  const [loading, setLoading] = React.useState(!!canIVisit);

  React.useEffect(() => {
    let isSubscribed = true;
    const proxySetAuth = (val: boolean) => {
      if (isSubscribed) {
        setAuth(val);
      }
    };
    const proxySetLoading = (val: boolean) => {
      if (isSubscribed) {
        setLoading(val);
      }
    };
    if (canIVisit) {
      Promise.resolve(canIVisit.call(children, routerProps))
        .then(res => {
          if (res !== false) {
            proxySetAuth(true);
          } else {
            proxySetAuth(false);
          }
        })
        .catch(err => {
          proxySetAuth(false);
        })
        .finally(() => {
          proxySetLoading(false);
        });
    }
    return () => {
      isSubscribed = false;
    };
  });

  if (loading) {
    return <>{canIVisitOptions.loadingCompoment || null}</>;
  }

  return (
    <>
      {hasAuth ? children : canIVisitOptions.permissionDeniedCompoment || null}
    </>
  );
}

/**
 * 渲染 Route、Redirect
 * @param {IRouterConfItem[]} routers
 * @returns
 */
function RenderRouters(
  routers: IRouterConfItem[],
  props?: Omit<SwitchProps, 'children'>,
  canIVisit?: ICanIVisitFn,
  canIVisitOptions?: ICanIVisitOptions
) {
  return (
    <Switch {...props}>
      {routers.map((v, i) => {
        if (!v.hasOwnProperty('to')) {
          const {
            component,
            render,
            subRouter,
            schema,
            needAlive,
            ...routeProps
          } = v as RouteItemProps;
          const Component = Defaultcomponent(component);

          const childView = RenderRouters(
            (subRouter! || []).reduce((acc: RouteItemProps[], v) => {
              const item = {
                ...v,
                path: prefixParentPath(routeProps.path!, v.path!)
              };
              acc.push(item);
              return acc;
            }, []),
            props,
            canIVisit
          );

          return (
            <Route
              key={String(v.path)}
              {...routeProps}
              render={props => {
                Object.assign(props, { schema, childView });
                if (props.location.search) {
                  (props as any).query = queryString.parse(
                    props.location.search
                  );
                } else {
                  (props as any).query = {};
                }

                const routeCompoment = render ? (
                  render(props)
                ) : (
                  <Component {...(props as IRoutercomponentProps)} />
                );

                return (
                  <AuthRouter
                    {...(props as IRoutercomponentProps)}
                    canIVisit={canIVisit}
                    canIVisitOptions={canIVisitOptions}
                    children={
                      needAlive ? (
                        <KeepAlive
                          name={String(routeProps.path)}
                          children={<div>{routeCompoment}</div>}
                        />
                      ) : (
                        routeCompoment
                      )
                    }
                  />
                );
              }}
            />
          );
        }

        return <Redirect key={i} {...(v as RedirectProps)} />;
      })}
    </Switch>
  );
}

export {
  RenderRouters,
  IRoutercomponentProps,
  RouteItemProps,
  IRouterConfItem,
  ICanIVisitFn,
  ICanIVisitOptions
};
