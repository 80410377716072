export default {
  // 加
  add(arg1: number, arg2: number) {
    let digits1;
    let digits2;
    let maxDigits;
    try {
      digits1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      digits1 = 0;
    }
    try {
      digits2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      digits2 = 0;
    }
    maxDigits = Math.pow(10, Math.max(digits1, digits2));
    return (arg1 * maxDigits + arg2 * maxDigits) / maxDigits;
  },

  // 减
  sub(arg1: number, arg2: number) {
    let digits1;
    let digits2;
    let maxDigits;
    try {
      digits1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      digits1 = 0;
    }
    try {
      digits2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      digits2 = 0;
    }
    maxDigits = Math.pow(10, Math.max(digits1, digits2));
    return (arg1 * maxDigits - arg2 * maxDigits) / maxDigits;
  },

  // 乘
  mul(arg1: number, arg2: number) {
    let digits = 0;
    const s1 = arg1.toString();
    const s2 = arg2.toString();
    /* tslint:disable:no-empty */
    try {
      digits += s1.split('.')[1].length;
    } catch (e) {}
    /* tslint:disable:no-empty */
    try {
      digits += s2.split('.')[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, digits)
    );
  },

  // 除
  div(arg1: number, arg2: number) {
    let int1 = 0;
    let int2 = 0;
    let digits1;
    let digits2;
    try {
      digits1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      digits1 = 0;
    }
    try {
      digits2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      digits2 = 0;
    }

    int1 = Number(arg1.toString().replace('.', ''));
    int2 = Number(arg2.toString().replace('.', ''));
    return (int1 / int2) * Math.pow(10, digits2 - digits1);
  }
};
